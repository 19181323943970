import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import { Col, Row, Select, Button, Input, Form, Spin } from "antd";
import { CloseOutlined, CalculatorOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Swiper, SwiperSlide } from "swiper/react";
import SwipeImage from "../../../assets/images/swipe.svg";
import SearchIcon from "../../../assets/images/search-icon.svg";
import "./Calculators.css";

import PhoneBgImage from "../../../assets/images/calculadoras-img-banner@2x.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import endpoints from "../../../services/endpoints";
import BannerPrincipal from "../../../components/website/BannerPrincipal/BannerPrincipal";
import imageBanner from "../../../assets/images/calculadoras-cientificas@2x.png";
import PracticalGuidesCTA from "../../../components/website/PracticalGuidesCTA/PracticalGuidesCTA";

export default function Calculadoras() {
  const [calculators, setCalculators] = useState([]);
  const [inputFilter, setInputFilter] = useState([]);
  const [selectFilter, setSelectFilter] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    handleGetCalculators();
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function handleGetCalculators() {
    setIsLoading(true);
    axios
      .get(endpoints.calculators.read)
      .then((res) => {
        setCalculators(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Spin spinning={isLoading}>
      <Helmet>
        <title>Cardio4all | Calculadoras úteis para a prática clínica MGF</title>
        <meta name="description" content="O Cardio4All tem à sua disposição várias calculadoras úteis para a prática clínica." />
        <meta
          name="keywords"
          content="calculadoras área cardiovascular, Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:title" content="Cardio4all | Calculadoras úteis para a prática clínica MGF" />
        <meta property="og:description" content="O Cardio4All tem à sua disposição várias calculadoras úteis para a prática clínica." />
        <meta property="og:image" content={`${PhoneBgImage}`} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Row>
        <BannerPrincipal
          color={"#4A718F"}
          backgroundImage={imageBanner}
          children={
            <>
              <p className="white f-40 bold mt-20 ellipsisThree">Calculadoras Científicas</p>
              <p className="white f-16 mt-20">
                O Cardio4all desenvolveu várias calculadoras úteis para a sua prática clínica do dia-a-dia. As calculadoras podem ser acedidas não só aqui, mas também através da
                App Cardio4all.
              </p>
            </>
          }
        />
        <Row className="mt-20">
          <Col id="list-calculators" span={24}>
            <div className="container">
              <Row gutter={[{ xs: 0, sm: 0, md: 0, lg: 24 }, 24]}>
                {calculators?.map((item, index) => {
                  return (
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} key={"guide-" + index}>
                      <Link to={item?.slug}>
                        <div className="list-guide-item">
                          <div className="d-flex jc-sb w-100 p-20 flex-wrap">
                            <div className="d-flex flex-column jc-center" style={{ maxWidth: 300 }}>
                              <p className="f-16 bold blue">{item?.name}</p>
                              {item?.description && <p className="f-14 blue ellipsisTwo">{item?.description}</p>}
                            </div>
                            <div className="minw-100 d-flex flex-column jc-center ai-center link">
                              <p>ver mais</p>
                              <span>
                                <ArrowRightOutlined />
                              </span>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Col>

          <Col span={24}>
            <PracticalGuidesCTA />
          </Col>
        </Row>
      </Row>
    </Spin>
  );
}
