import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Checkbox,
  Col,
  ColorPicker,
  Spin,
  Form,
  InputNumber,
  Radio,
  Drawer,
  Row,
  Select,
  message,
  Space,
  Switch,
  Upload,
  Input,
  Divider,
  DatePicker,
  Tooltip,
  Tabs,
} from "antd";
import "../../../assets/styles/admin.css";
import axios from "axios";
import api from "../../../services/api";
import { Outlet, Link } from "react-router-dom";
import { MinusCircleOutlined, PlusOutlined, ArrowUpOutlined, DesktopOutlined, MobileOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import { useForm } from "antd/es/form/Form";
import config from "../../../services/config";
import endpoints from "../../../services/endpoints";
import "react-quill/dist/quill.snow.css";
import dayjs from "dayjs";
import KeyMessages from "./KeyMessages";
const { Dragger } = Upload;

const { Option } = Select;
const { TextArea } = Input;

function Orientations({ open, close, data }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isOpenKeyMessages, setIsOpenKeyMessages] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [calculators, setCalculators] = useState([]);
  const [tabs, setTabs] = useState([{ key: 0, label: "Slide 1" }]);
  const [activeKey, setActiveKey] = useState(0);
  const [orientationsData, setOrientationsData] = useState({});

  const [form] = useForm();
  useEffect(() => {
    if (open) {
      handleGetData();
    }
  }, [open]);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.clinicalCases.readOrientationById, {
        params: { id: data.id },
      })
      .then((res) => {
        let auxTabs = [{ key: 0, label: "Slide 1" }];
        let auxData = res.data[0][0];
        setOrientationsData(auxData);
        if (auxData.data) {
          let formData = JSON.parse(auxData.data);
          let objectKeys = Object.keys(formData);
          for (let i = 0; i < objectKeys.length; i++) {
            if (i > 0) {
              auxTabs.push({ key: auxTabs.length, label: `Slide ${auxTabs.length + 1}` });
            }
          }

          form.setFieldsValue(formData);
        }

        setTabs(auxTabs);
        setCalculators(res.data[1]);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function handleClose() {
    form.resetFields();
    setTabs([{ key: 0, label: "Slide 1" }]);
    setActiveKey(0);
    close();
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);

    let dataSteps = Object.keys(values);

    for (let i = 0; i < dataSteps.length; i++) {
      for (let z = 0; z < values[dataSteps[i]].length; z++) {
        if (values[dataSteps[i]][z].type === "image") {
          //desktop
          if (values[dataSteps[i]][z].image && values[dataSteps[i]][z].image.fileList && values[dataSteps[i]][z].image.fileList.length > 0) {
            values[dataSteps[i]][z].image = values[dataSteps[i]][z].image.fileList[0].response;
          } else {
            values[dataSteps[i]][z].image = values[dataSteps[i]][z].image;
          }

          //mobile
          if (values[dataSteps[i]][z].image_mobile && values[dataSteps[i]][z].image_mobile.fileList && values[dataSteps[i]][z].image_mobile.fileList.length > 0) {
            values[dataSteps[i]][z].image_mobile = values[dataSteps[i]][z].image_mobile.fileList[0].response;
          } else {
            values[dataSteps[i]][z].image_mobile = values[dataSteps[i]][z].image_mobile;
          }
        } else if (values[dataSteps[i]][z].type === "cta_image") {
          if (values[dataSteps[i]][z].image && values[dataSteps[i]][z].image.fileList && values[dataSteps[i]][z].image.fileList.length > 0) {
            values[dataSteps[i]][z].image = values[dataSteps[i]][z].image.fileList[0].response;
          } else {
            values[dataSteps[i]][z].image = values[dataSteps[i]][z].image;
          }
        } else if (values[dataSteps[i]][z].type === "icones") {
          for (let y = 0; y < values[dataSteps[i]][z].icones.length; y++) {
            if (values[dataSteps[i]][z].icones[y].image && values[dataSteps[i]][z].icones[y].image.fileList && values[dataSteps[i]][z].icones[y].image.fileList.length > 0) {
              values[dataSteps[i]][z].icones[y].image = values[dataSteps[i]][z].icones[y].image.fileList[0].response;
            } else {
              values[dataSteps[i]][z].icones[y].image = values[dataSteps[i]][z].icones[y].image;
            }
          }
        }
      }
    }

    //setIsButtonLoading(false);
    axios
      .post(endpoints.clinicalCases.createOrientation, {
        data: values,
        id_clinical_case: data.id,
      })
      .then((resData) => {
        setTimeout(() => {
          setIsButtonLoading(false);
          handleClose();
        }, 1000);
      })
      .catch((error) => {
        setIsButtonLoading(false);
        console.error(error);
      });
  }

  const handleChangeTab = (key) => {
    setActiveKey(key);
  };

  const handleAddTab = () => {
    setTabs([
      ...tabs,
      {
        key: tabs.length,
        label: `Slide ${tabs.length + 1}`,
      },
    ]);
    setActiveKey(tabs.length);
  };

  const handleRemoveTab = (targetKey) => {
    if (tabs.length > 1) {
      const newPanes = tabs.filter((pane) => pane.key !== targetKey);

      for (let i = 0; i < newPanes.length; i++) {
        newPanes[i].key = i;
        newPanes[i].label = `Slide ${i + 1}`;
      }

      let allValues = form.getFieldsValue();
      delete allValues[`data_step_${targetKey}`];

      let newObjForm = {};
      for (let i = 0; i < Object.keys(allValues).length; i++) {
        newObjForm = { ...newObjForm, [`data_step_${i}`]: allValues[Object.keys(allValues)[i]] };
      }

      form.resetFields();
      form.setFieldsValue(newObjForm);
      setTabs(newPanes);
      setActiveKey(0);
    }
  };

  const handleEditTab = (targetKey, action) => {
    if (action === "add") {
      handleAddTab();
    } else {
      handleRemoveTab(targetKey);
    }
  };

  function handleOpenKeyMessages() {
    setIsOpenKeyMessages(true);
  }

  function handleCloseKeyMessages() {
    setIsOpenKeyMessages(false);
  }

  return (
    <Drawer
      className="drawer-clinical"
      key={`drawer-create-orientations-clinical`}
      title="Orientações - Caso clínico"
      width={1200}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        <>
          <Button disabled={isButtonLoading} className="mr-10" onClick={handleClose}>
            Cancelar
          </Button>
          <Button loading={isButtonLoading} type="primary" onClick={form.submit}>
            Submeter
          </Button>
        </>
      }
    >
      <Spin spinning={isLoading} getPopupContainer={(trigger) => trigger.parentNode}>
        <KeyMessages open={isOpenKeyMessages} close={handleCloseKeyMessages} data={data} />
        <Row gutter={[24]} className="m-0">
          <Col span={24}>
            <Button type="dashed" size="large" className="w-100 mb-20" onClick={handleOpenKeyMessages}>
              Mensagens-chave
            </Button>
            <Form form={form} onFinish={handleSubmit} layout="vertical">
              <Tabs
                activeKey={activeKey}
                type={"editable-card"}
                onEdit={handleEditTab}
                onChange={handleChangeTab}
                items={tabs.map((item, indexOfTab) => ({
                  key: item.key,
                  label: item.label,
                  forceRender: true,
                  children: (
                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues !== currentValues}>
                      {({ getFieldValue }) => (
                        <Form.List name={`data_step_${indexOfTab}`}>
                          {(fields, { add, remove, move }, { errors }) => (
                            <Row>
                              {fields.map((field, index) => {
                                return (
                                  <div className="w-100" style={{ position: "relative" }}>
                                    <Col span={24}>
                                      <Form.Item
                                        getValueFromEvent={(color) => {
                                          return "#" + color.toHex();
                                        }}
                                        label="Background"
                                        name={[index, "background"]}
                                        key={`orientation_color_picker`}
                                      >
                                        <ColorPicker getPopupContainer={(trigger) => trigger.parentNode} size="large" showText />
                                      </Form.Item>
                                      <Form.Item
                                        {...field}
                                        label="Tipo"
                                        name={[index, "type"]}
                                        key={`orientation_type_${field.key}_${index}`}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Este campo é obrigatório",
                                          },
                                        ]}
                                      >
                                        <Select
                                          size="large"
                                          showSearch
                                          placeholder="Tipo de content"
                                          optionFilterProp="children"
                                          filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                                          options={[
                                            { value: "image", label: "Imagem" },
                                            { value: "calculator", label: "Calculadora" },
                                            { value: "video", label: "Video" },
                                            { value: "references", label: "Referências" },
                                            { value: "text", label: "Texto" },
                                            { value: "icones", label: "Icones" },
                                            { value: "cta_image", label: "CTA Imagem" },
                                          ].map((item) => ({
                                            value: item.value,
                                            label: item.label,
                                          }))}
                                          getPopupContainer={(trigger) => trigger.parentNode}
                                        />
                                      </Form.Item>

                                      <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, currentValues) => prevValues[`data_step_${indexOfTab}`] !== currentValues[`data_step_${indexOfTab}`]}
                                      >
                                        {({ getFieldValue }) => {
                                          if (getFieldValue(`data_step_${indexOfTab}`) && getFieldValue(`data_step_${indexOfTab}`)[index]) {
                                            if (getFieldValue(`data_step_${indexOfTab}`)[index].type === "references") {
                                              return (
                                                <Form.Item
                                                  label="Referências"
                                                  name={[index, "references"]}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: "Este campo é obrigatório",
                                                    },
                                                  ]}
                                                >
                                                  <ReactQuill placeholder="Referências..." size="large" />
                                                </Form.Item>
                                              );
                                            } else if (getFieldValue(`data_step_${indexOfTab}`)[index].type === "text") {
                                              return (
                                                <Form.Item
                                                  label="Texto"
                                                  name={[index, "text"]}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: "Este campo é obrigatório",
                                                    },
                                                  ]}
                                                >
                                                  <ReactQuill placeholder="Referências..." size="large" />
                                                </Form.Item>
                                              );
                                            } else if (getFieldValue(`data_step_${indexOfTab}`)[index].type === "image") {
                                              return (
                                                <Row gutter={[24]}>
                                                  <Col span={12}>
                                                    <Form.Item
                                                      label="Imagem"
                                                      name={[index, "image"]}
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message: "Este campo é obrigatório",
                                                        },
                                                      ]}
                                                    >
                                                      <Dragger
                                                        accept="image/*"
                                                        multiple={false}
                                                        maxCount={1}
                                                        defaultFileList={
                                                          getFieldValue(`data_step_${indexOfTab}`)[index].image
                                                            ? [
                                                                {
                                                                  name:
                                                                    getFieldValue(`data_step_${indexOfTab}`)[index].image.fileList &&
                                                                    getFieldValue(`data_step_${indexOfTab}`)[index].image.fileList.length > 0
                                                                      ? getFieldValue(`data_step_${indexOfTab}`)[index].image.fileList[0].response
                                                                      : getFieldValue(`data_step_${indexOfTab}`)[index].image,
                                                                },
                                                              ]
                                                            : []
                                                        }
                                                        action={config.server_ip + endpoints.media.singleUpload}
                                                        className={`dragger ${getFieldValue(`data_step_${indexOfTab}`)[index].image ? "dragger_hidden" : ""}`}
                                                      >
                                                        <p className="ant-upload-drag-icon">
                                                          <DesktopOutlined
                                                            style={{
                                                              color: "rgb(103 103 103)",
                                                            }}
                                                          />
                                                        </p>
                                                        <p className="ant-upload-text text">
                                                          <b>Upload da imagem em desktop</b>
                                                        </p>
                                                        <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                                                        <div
                                                          className={`preview_file ${getFieldValue(`data_step_${indexOfTab}`)[index].image ? "" : "hidden"}`}
                                                          style={{
                                                            backgroundImage: `url(${
                                                              getFieldValue(`data_step_${indexOfTab}`)[index].image
                                                                ? `${config.server_ip}/assets/media/${
                                                                    getFieldValue(`data_step_${indexOfTab}`)[index].image.fileList &&
                                                                    getFieldValue(`data_step_${indexOfTab}`)[index].image.fileList.length > 0
                                                                      ? getFieldValue(`data_step_${indexOfTab}`)[index].image.fileList[0].response
                                                                      : getFieldValue(`data_step_${indexOfTab}`)[index].image
                                                                  }`
                                                                : null
                                                            })`,
                                                          }}
                                                        ></div>
                                                      </Dragger>
                                                    </Form.Item>
                                                  </Col>
                                                  <Col span={12}>
                                                    <Form.Item
                                                      label="Imagem"
                                                      name={[index, "image_mobile"]}
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message: "Este campo é obrigatório",
                                                        },
                                                      ]}
                                                    >
                                                      <Dragger
                                                        accept="image/*"
                                                        multiple={false}
                                                        maxCount={1}
                                                        defaultFileList={
                                                          getFieldValue(`data_step_${indexOfTab}`)[index].image_mobile
                                                            ? [
                                                                {
                                                                  name:
                                                                    getFieldValue(`data_step_${indexOfTab}`)[index].image_mobile.fileList &&
                                                                    getFieldValue(`data_step_${indexOfTab}`)[index].image_mobile.fileList.length > 0
                                                                      ? getFieldValue(`data_step_${indexOfTab}`)[index].image_mobile.fileList[0].response
                                                                      : getFieldValue(`data_step_${indexOfTab}`)[index].image_mobile,
                                                                },
                                                              ]
                                                            : []
                                                        }
                                                        action={config.server_ip + endpoints.media.singleUpload}
                                                        className={`dragger ${getFieldValue(`data_step_${indexOfTab}`)[index].image_mobile ? "dragger_hidden" : ""}`}
                                                      >
                                                        <p className="ant-upload-drag-icon">
                                                          <MobileOutlined
                                                            style={{
                                                              color: "rgb(103 103 103)",
                                                            }}
                                                          />
                                                        </p>
                                                        <p className="ant-upload-text text">
                                                          <b>Upload da imagem em mobile</b>
                                                        </p>
                                                        <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                                                        <div
                                                          className={`preview_file ${getFieldValue(`data_step_${indexOfTab}`)[index].image_mobile ? "" : "hidden"}`}
                                                          style={{
                                                            backgroundImage: `url(${
                                                              getFieldValue(`data_step_${indexOfTab}`)[index].image_mobile
                                                                ? `${config.server_ip}/assets/media/${
                                                                    getFieldValue(`data_step_${indexOfTab}`)[index].image_mobile.fileList &&
                                                                    getFieldValue(`data_step_${indexOfTab}`)[index].image_mobile.fileList.length > 0
                                                                      ? getFieldValue(`data_step_${indexOfTab}`)[index].image_mobile.fileList[0].response
                                                                      : getFieldValue(`data_step_${indexOfTab}`)[index].image_mobile
                                                                  }`
                                                                : null
                                                            })`,
                                                          }}
                                                        ></div>
                                                      </Dragger>
                                                    </Form.Item>
                                                  </Col>
                                                </Row>
                                              );
                                            } else if (getFieldValue(`data_step_${indexOfTab}`)[index].type === "calculator") {
                                              return (
                                                <Form.Item
                                                  {...field}
                                                  label="Calculadora"
                                                  name={[index, "calculator"]}
                                                  key={`orientation_${field.key}_${index}`}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: "Este campo é obrigatório",
                                                    },
                                                  ]}
                                                >
                                                  <Select
                                                    size="large"
                                                    showSearch
                                                    placeholder="Calculadora..."
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                                                    options={calculators.map((item) => ({
                                                      value: item.id,
                                                      label: item.name,
                                                    }))}
                                                    getPopupContainer={(trigger) => trigger.parentNode}
                                                  />
                                                </Form.Item>
                                              );
                                            } else if (getFieldValue(`data_step_${indexOfTab}`)[index].type === "cta_image") {
                                              return (
                                                <>
                                                  <Form.Item
                                                    label="Imagem"
                                                    name={[index, "image"]}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: "Este campo é obrigatório",
                                                      },
                                                    ]}
                                                  >
                                                    <Dragger
                                                      accept="image/*"
                                                      multiple={false}
                                                      maxCount={1}
                                                      defaultFileList={
                                                        getFieldValue(`data_step_${indexOfTab}`)[index].image
                                                          ? [
                                                              {
                                                                name:
                                                                  getFieldValue(`data_step_${indexOfTab}`)[index].image.fileList &&
                                                                  getFieldValue(`data_step_${indexOfTab}`)[index].image.fileList.length > 0
                                                                    ? getFieldValue(`data_step_${indexOfTab}`)[index].image.fileList[0].response
                                                                    : getFieldValue(`data_step_${indexOfTab}`)[index].image,
                                                              },
                                                            ]
                                                          : []
                                                      }
                                                      action={config.server_ip + endpoints.media.singleUpload}
                                                      className={`dragger ${getFieldValue(`data_step_${indexOfTab}`)[index].image ? "dragger_hidden" : ""}`}
                                                    >
                                                      <p className="ant-upload-drag-icon">
                                                        <DesktopOutlined
                                                          style={{
                                                            color: "rgb(103 103 103)",
                                                          }}
                                                        />
                                                      </p>
                                                      <p className="ant-upload-text text">
                                                        <b>Upload da imagem em desktop</b>
                                                      </p>
                                                      <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                                                      <div
                                                        className={`preview_file ${getFieldValue(`data_step_${indexOfTab}`)[index].image ? "" : "hidden"}`}
                                                        style={{
                                                          backgroundImage: `url(${
                                                            getFieldValue(`data_step_${indexOfTab}`)[index].image
                                                              ? `${config.server_ip}/assets/media/${
                                                                  getFieldValue(`data_step_${indexOfTab}`)[index].image.fileList &&
                                                                  getFieldValue(`data_step_${indexOfTab}`)[index].image.fileList.length > 0
                                                                    ? getFieldValue(`data_step_${indexOfTab}`)[index].image.fileList[0].response
                                                                    : getFieldValue(`data_step_${indexOfTab}`)[index].image
                                                                }`
                                                              : null
                                                          })`,
                                                        }}
                                                      ></div>
                                                    </Dragger>
                                                  </Form.Item>

                                                  <Form.Item
                                                    label="Texto"
                                                    name={[index, "text"]}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: "Este campo é obrigatório",
                                                      },
                                                    ]}
                                                  >
                                                    <ReactQuill placeholder="Referências..." size="large" />
                                                  </Form.Item>

                                                  <Form.Item
                                                    label="Texto do botão"
                                                    name={[index, "button_text"]}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: "Este campo é obrigatório",
                                                      },
                                                    ]}
                                                  >
                                                    <Input placeholder="Texto do botão..." size="large" />
                                                  </Form.Item>

                                                  <Form.Item
                                                    label="Link do botão"
                                                    name={[index, "button_link"]}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: "Este campo é obrigatório",
                                                      },
                                                    ]}
                                                  >
                                                    <Input placeholder="Link do botão..." size="large" />
                                                  </Form.Item>
                                                </>
                                              );
                                            } else if (getFieldValue(`data_step_${indexOfTab}`)[index].type === "icones") {
                                              return (
                                                <>
                                                  <Form.Item
                                                    label="Layout"
                                                    name={[index, "layout"]}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: "Este campo é obrigatório",
                                                      },
                                                    ]}
                                                  >
                                                    <Select
                                                      size="large"
                                                      showSearch
                                                      placeholder="Layout"
                                                      optionFilterProp="children"
                                                      filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                                                      options={[
                                                        { value: "vertical", label: "Vertical" },
                                                        { value: "horizontal", label: "Horizontal" },
                                                      ].map((item) => ({
                                                        value: item.value,
                                                        label: item.label,
                                                      }))}
                                                      getPopupContainer={(trigger) => trigger.parentNode}
                                                    />
                                                  </Form.Item>
                                                  <Form.Item
                                                    label="Título"
                                                    name={[index, "title"]}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: "Este campo é obrigatório",
                                                      },
                                                    ]}
                                                  >
                                                    <Input placeholder="Título..." size="large" />
                                                  </Form.Item>
                                                  <Form.List name={[index, "icones"]}>
                                                    {(subFields, subOpt) => (
                                                      <Row gutter={[24]}>
                                                        {subFields.map((subField) => (
                                                          <Col span={8} className="mt-10">
                                                            <Form.Item noStyle name={[subField.name, "image"]}>
                                                              <Dragger
                                                                accept="image/*"
                                                                multiple={false}
                                                                maxCount={1}
                                                                defaultFileList={
                                                                  getFieldValue(`data_step_${indexOfTab}`)[index].icones[subField.name]
                                                                    ? [
                                                                        {
                                                                          name:
                                                                            getFieldValue(`data_step_${indexOfTab}`)[index].icones[subField.name].image &&
                                                                            getFieldValue(`data_step_${indexOfTab}`)[index].icones[subField.name].image.fileList &&
                                                                            getFieldValue(`data_step_${indexOfTab}`)[index].icones[subField.name].image.fileList.length > 0
                                                                              ? getFieldValue(`data_step_${indexOfTab}`)[index].icones[subField.name].image.fileList[0].response
                                                                              : getFieldValue(`data_step_${indexOfTab}`)[index].icones[subField.name].image,
                                                                        },
                                                                      ]
                                                                    : []
                                                                }
                                                                action={config.server_ip + endpoints.media.singleUpload}
                                                                className={`dragger ${
                                                                  getFieldValue(`data_step_${indexOfTab}`)[index].icones[subField.name] &&
                                                                  getFieldValue(`data_step_${indexOfTab}`)[index].icones[subField.name].image
                                                                    ? "dragger_hidden"
                                                                    : ""
                                                                }`}
                                                              >
                                                                <p className="ant-upload-drag-icon">
                                                                  <DesktopOutlined
                                                                    style={{
                                                                      color: "rgb(103 103 103)",
                                                                    }}
                                                                  />
                                                                </p>
                                                                <p className="ant-upload-text text">
                                                                  <b>Upload da imagem</b>
                                                                </p>
                                                                <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                                                                <div
                                                                  className={`preview_file ${
                                                                    getFieldValue(`data_step_${indexOfTab}`)[index].icones[subField.name] &&
                                                                    getFieldValue(`data_step_${indexOfTab}`)[index].icones[subField.name].image
                                                                      ? ""
                                                                      : "hidden"
                                                                  }`}
                                                                  style={{
                                                                    backgroundImage: `url(${
                                                                      getFieldValue(`data_step_${indexOfTab}`)[index].icones[subField.name] &&
                                                                      getFieldValue(`data_step_${indexOfTab}`)[index].icones[subField.name].image
                                                                        ? `${config.server_ip}/assets/media/${
                                                                            getFieldValue(`data_step_${indexOfTab}`)[index].icones[subField.name].image.fileList &&
                                                                            getFieldValue(`data_step_${indexOfTab}`)[index].icones[subField.name].image.fileList.length > 0
                                                                              ? getFieldValue(`data_step_${indexOfTab}`)[index].icones[subField.name].image.fileList[0].response
                                                                              : getFieldValue(`data_step_${indexOfTab}`)[index].icones[subField.name].image
                                                                          }`
                                                                        : null
                                                                    })`,
                                                                  }}
                                                                ></div>
                                                              </Dragger>
                                                            </Form.Item>
                                                            <Form.Item label="Image width (px)" name={[subField.name, "image_max_width"]} className="mt-20 w-100">
                                                              <InputNumber min={1} placeholder="Image Max Width (px)" size="large" className="w-100" />
                                                            </Form.Item>
                                                            <Form.Item label="Alinhamento texto" name={[subField.name, "text_align"]} className="mt-20 w-100">
                                                              <Select
                                                                size="large"
                                                                showSearch
                                                                placeholder="Alinhamento"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                                                                options={[
                                                                  { value: "center", label: "Centro" },
                                                                  { value: "left", label: "Esquerda" },
                                                                  { value: "right", label: "Direita" },
                                                                ].map((item) => ({
                                                                  value: item.value,
                                                                  label: item.label,
                                                                }))}
                                                                getPopupContainer={(trigger) => trigger.parentNode}
                                                              />
                                                            </Form.Item>
                                                            <Form.Item label="Texto" noStyle name={[subField.name, "text"]}>
                                                              <ReactQuill placeholder="Texto..." size="large" className="mt-20" />
                                                            </Form.Item>
                                                            <Form.Item label="Span (1-24)" name={[subField.name, "span"]} className="mt-20 w-100">
                                                              <InputNumber max={24} min={1} placeholder="Span (1-24)" size="large" className="w-100" />
                                                            </Form.Item>
                                                            <Row gutter={[24]} className="ml-0 mr-0 mt-20 mb-40">
                                                              <Col span={12}>
                                                                <Button
                                                                  className="h-100 w-100"
                                                                  size="large"
                                                                  type="dashed"
                                                                  onClick={() => subOpt.move(subField.name, subField.name - 1)}
                                                                >
                                                                  <ArrowUpOutlined />
                                                                </Button>
                                                              </Col>
                                                              <Col span={12}>
                                                                <Button className="h-100 w-100" size="large" type="dashed" onClick={() => subOpt.remove(subField.name)}>
                                                                  <MinusCircleOutlined />
                                                                </Button>
                                                              </Col>
                                                            </Row>
                                                          </Col>
                                                        ))}
                                                        <Col span={8} className="mt-10">
                                                          <Button className="h-100 w-100" size="large" type="dashed" onClick={() => subOpt.add()}>
                                                            + Adicionar ícone
                                                          </Button>
                                                        </Col>
                                                      </Row>
                                                    )}
                                                  </Form.List>
                                                </>
                                              );
                                            } else if (getFieldValue(`data_step_${indexOfTab}`)[index].type === "cta_image") {
                                              return (
                                                <>
                                                  <Form.Item
                                                    label="Imagem"
                                                    name={[index, "image"]}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: "Este campo é obrigatório",
                                                      },
                                                    ]}
                                                  >
                                                    <Dragger
                                                      accept="image/*"
                                                      multiple={false}
                                                      maxCount={1}
                                                      defaultFileList={
                                                        getFieldValue(`data_step_${indexOfTab}`)[index].image
                                                          ? [
                                                              {
                                                                name:
                                                                  getFieldValue(`data_step_${indexOfTab}`)[index].image.fileList &&
                                                                  getFieldValue(`data_step_${indexOfTab}`)[index].image.fileList.length > 0
                                                                    ? getFieldValue(`data_step_${indexOfTab}`)[index].image.fileList[0].response
                                                                    : getFieldValue(`data_step_${indexOfTab}`)[index].image,
                                                              },
                                                            ]
                                                          : []
                                                      }
                                                      action={config.server_ip + endpoints.media.singleUpload}
                                                      className={`dragger ${getFieldValue(`data_step_${indexOfTab}`)[index].image ? "dragger_hidden" : ""}`}
                                                    >
                                                      <p className="ant-upload-drag-icon">
                                                        <DesktopOutlined
                                                          style={{
                                                            color: "rgb(103 103 103)",
                                                          }}
                                                        />
                                                      </p>
                                                      <p className="ant-upload-text text">
                                                        <b>Upload da imagem em desktop</b>
                                                      </p>
                                                      <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                                                      <div
                                                        className={`preview_file ${getFieldValue(`data_step_${indexOfTab}`)[index].image ? "" : "hidden"}`}
                                                        style={{
                                                          backgroundImage: `url(${
                                                            getFieldValue(`data_step_${indexOfTab}`)[index].image
                                                              ? `${config.server_ip}/assets/media/${
                                                                  getFieldValue(`data_step_${indexOfTab}`)[index].image.fileList &&
                                                                  getFieldValue(`data_step_${indexOfTab}`)[index].image.fileList.length > 0
                                                                    ? getFieldValue(`data_step_${indexOfTab}`)[index].image.fileList[0].response
                                                                    : getFieldValue(`data_step_${indexOfTab}`)[index].image
                                                                }`
                                                              : null
                                                          })`,
                                                        }}
                                                      ></div>
                                                    </Dragger>
                                                  </Form.Item>

                                                  <Form.Item
                                                    label="Texto"
                                                    name={[index, "text"]}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: "Este campo é obrigatório",
                                                      },
                                                    ]}
                                                  >
                                                    <ReactQuill placeholder="Referências..." size="large" />
                                                  </Form.Item>

                                                  <Form.Item
                                                    label="Texto do botão"
                                                    name={[index, "button_text"]}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: "Este campo é obrigatório",
                                                      },
                                                    ]}
                                                  >
                                                    <Input placeholder="Texto do botão..." size="large" />
                                                  </Form.Item>

                                                  <Form.Item
                                                    label="Link do botão"
                                                    name={[index, "button_link"]}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: "Este campo é obrigatório",
                                                      },
                                                    ]}
                                                  >
                                                    <Input placeholder="Link do botão..." size="large" />
                                                  </Form.Item>
                                                </>
                                              );
                                            } else {
                                              return (
                                                <Form.Item
                                                  {...field}
                                                  label="Link"
                                                  name={[index, "link"]}
                                                  key={`orientation_${field.key}_${index}`}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: "Este campo é obrigatório",
                                                    },
                                                  ]}
                                                >
                                                  <Input size="large" placeholder="Video link" />
                                                </Form.Item>
                                              );
                                            }
                                          }
                                        }}
                                      </Form.Item>
                                    </Col>
                                    <div className="d-flex jc-center ai-center" style={{ position: "absolute", top: 0, right: 0 }}>
                                      {index !== 0 && fields.length > 1 ? (
                                        <Tooltip title="Mover" placement="bottom">
                                          <ArrowUpOutlined className="f-20 mr-10" onClick={() => move(index, index - 1)} />
                                        </Tooltip>
                                      ) : null}
                                      {fields.length > 1 ? (
                                        <Tooltip title="Apagar">
                                          <MinusCircleOutlined className="f-20 mr-10" onClick={() => remove(field.name)} />
                                        </Tooltip>
                                      ) : null}
                                    </div>
                                    <Divider
                                      style={{
                                        height: 2,
                                        backgroundColor: "rgba(0,0,0,0.2)",
                                      }}
                                    />
                                  </div>
                                );
                              })}
                              <Form.Item className="w-100">
                                <Button type="dashed" onClick={() => add()} className="w-100" size="large" icon={<PlusOutlined />}>
                                  Adicionar item
                                </Button>
                              </Form.Item>
                              <Form.ErrorList errors={errors} />
                            </Row>
                          )}
                        </Form.List>
                      )}
                    </Form.Item>
                  ),
                }))}
              />
            </Form>
          </Col>
        </Row>
      </Spin>
    </Drawer>
  );
}

export default Orientations;
