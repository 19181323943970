import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import { Col, Row, Select, Button, Input, Form, Spin } from "antd";
import { CloseOutlined, CalculatorOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Swiper, SwiperSlide } from "swiper/react";
import SwipeImage from "../../../assets/images/swipe.svg";
import SearchIcon from "../../../assets/images/search-icon.svg";

import imageBanner from "../../../assets/images/curso-leitura-electrocardiograma.png";
import { Link } from "react-router-dom";
import axios from "axios";
import endpoints from "../../../services/endpoints";
import BannerPrincipal from "../../../components/website/BannerPrincipal/BannerPrincipal";

import "./Courses.css";
import CalculatorCTA from "../../../components/website/CalculatorCTA/CalculatorCTA";

export default function Courses() {
  const [calculators, setCalculators] = useState([]);
  const [inputFilter, setInputFilter] = useState([]);
  const [selectFilter, setSelectFilter] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    handleGetData();
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.course.read)
      .then((res) => {
        setCalculators(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Spin spinning={isLoading}>
      <Helmet>
        <title>Cardio4all | Calculadoras úteis para a prática clínica MGF</title>
        <meta name="description" content="O Cardio4All tem à sua disposição várias calculadoras úteis para a prática clínica." />
        <meta
          name="keywords"
          content="calculadoras área cardiovascular, Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:title" content="Cardio4all | Calculadoras úteis para a prática clínica MGF" />
        <meta property="og:description" content="O Cardio4All tem à sua disposição várias calculadoras úteis para a prática clínica." />
        <meta property="og:image" content={`${imageBanner}`} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <BannerPrincipal
        color={"#4A718F"}
        backgroundImage={imageBanner}
        children={
          <>
            <p className="white f-40 bold mt-20 ellipsisThree">Cursos</p>
            <p className="white f-16 mt-20">Aprendendo com os melhores casos clínicos</p>
          </>
        }
      />
      <Row className="mb-40">
        <Row className="mt-20">
          <Col id="list-calculators" span={24}>
            <div className="container">
              <Row
                gutter={[
                  { xs: 20, sm: 8, md: 16, lg: 60 },
                  { xs: 40, sm: 8, md: 16, lg: 20 },
                ]}
              >
                {calculators?.map((item, index) => {
                  return (
                    <Col span={24} key={"guide-" + index}>
                      <Link to={item?.slug}>
                        <div className="list-guide-item">
                          <div className="d-flex jc-sb w-100 p-20 flex-wrap">
                            <div className="d-flex flex-column jc-center" style={{ maxWidth: 300 }}>
                              <p className="f-16 bold blue">{item?.name}</p>
                              {item?.description && <p className="f-14 blue ellipsisTwo">{item?.description}</p>}
                            </div>
                            <div className="minw-100 d-flex flex-column jc-center ai-center link">
                              <p>ver mais</p>
                              <span>
                                <ArrowRightOutlined />
                              </span>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Col>
        </Row>
      </Row>
      <CalculatorCTA />
    </Spin>
  );
}
