import React, { useEffect, useState } from "react";
import { Col, Row, Space, Button, Form, Input, Checkbox, Spin, message, Alert } from "antd";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ImageCoverBg from "../../../assets/images/bg-login@2x.jpg";
import endpoints from "../../../services/endpoints";
import api from "../../../services/api";
import "./LoginEvent.css";
import imageLogo from "../../../assets/images/cardio4all-logo.svg";
import imageLogoEvent from "../../../assets/images/logo-cardio-evento.svg";
import GoogleReCAPTCHA from "../../../services/recaptcha";
import { Helmet } from "react-helmet";

const LoginEvent = () => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  function handleSubmit(values) {
    setIsButtonLoading(true);

    axios
      .post(endpoints.events.login, {
        data: values,
      })
      .then((res) => {
        if (res.data.user_exists && res.data.user_password) {
          messageApi.open({
            type: "success",
            content: "Bem-vindo ao Cardio4all Summit 2024!",
          });
          window.location = `https://2024.cardio4all.pt/home?token=${res.data.token}`;
        } else {
          if (!res.data.user_exists) {
            messageApi.open({
              type: "error",
              content: "Este e-mail não está inscrito no Cardio4all Summit 2024",
            });
          } else if (!res.data.user_password) {
            messageApi.open({
              type: "error",
              content: "A password associada a este e-mail está errada",
            });
          }
          setIsButtonLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsButtonLoading(false);
      });
  }

  const [isVerified, setIsVerified] = useState(true);
  const handleVerify = (value) => {
    setIsVerified(!!value);
  };

  return (
    <>
      {contextHolder}
      <Helmet>
        <title>Login Cardio4all | Formação Científica em Medicina Cardiovascular</title>
        <meta
          name="description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
        <meta
          name="keywords"
          content="Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, calculadoras área cardiovascular, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:title" content="Cardio4all | Formação Científica em Medicina Cardiovascular" />
        <meta
          property="og:description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <GoogleReCAPTCHA onVerify={handleVerify} />
      <Row
        className="h-100 w-100 login_content d-flex jc-center ai-center position-relative"
        style={{
          minHeight: "100vh",
          backgroundImage: `url(${ImageCoverBg})`,
        }}
      >
        <Col span={24} className="container d-flex flex-column jc-center ai-center">
          <Row className="login-row d-flex jc-center ai-center">
            <Col xs={24} sm={24} md={12} lg={12} xl={8} className="d-flex flex-column jc-center ai-center bg-white p-40" style={{ boxShadow: "box-shadow: 2px 4px 6px #0000005C" }}>
              <img src={imageLogoEvent} className="w-100 maxw-200 mb-30" />
              <p className="f-24 blue mb-30 text-center bold">Evento exclusivo a profissionais de saúde</p>
              <Form className="login-form w-100" name="basic" onFinish={handleSubmit} form={form}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Introduza um endereço de e-mail válido",
                    },
                  ]}
                >
                  <Input size="large" placeholder="E-mail" />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Este campo é obrigatório",
                    },
                  ]}
                >
                  <Input.Password size="large" placeholder="Código" />
                </Form.Item>
                <p className="f-11 text-center mb-20">
                  Caso esteja com dificuldade a aceder fale connosco através do chat ou do email secretariado@phormulagroup.com Responderemos com a maior brevidade possível.
                </p>

                <div className="d-flex jc-center ai-center mb-20">
                  <p className="f-14">
                    Não está inscrito?
                    <br />
                    <Link className="blue bold text-center" to="/registo">
                      Inscreva-se aqui
                    </Link>
                  </p>
                </div>
                <div className="d-flex jc-center ai-center">
                  <Button loading={isButtonLoading} type="primary" htmlType="submit" className="login-btn mr-10" disabled={!isVerified}>
                    Entrar
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
          <Row className="d-flex jc-center ai-center mt-40">
            <Col span={24} className="d-flex flex-column jc-center ai-center">
              <p className="white italic f-12 mb-20">Uma iniciativa:</p>
              <img src={imageLogo} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default LoginEvent;
