import axios from "axios";
import config from "./config";

const api = {
  axiosCreate: () => {
    return new Promise((resolve, reject) => {
      axios.defaults.baseURL = config.server_ip;
      axios
        .get("/")
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  axiosInit: (token) => {
    return new Promise((resolve, reject) => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
        resolve(token);
      } else {
        reject("No token!");
      }
    });
  },
};

export default api;
