import React, { useState } from 'react'
import '../../../assets/styles/calculator.css'
import { Col, Form, Row, Space, Radio } from 'antd'
import CalculatorBanner from '../../../components/website/CalculatorBanner/CalculatorBanner'
import { CalculatorLinks } from '../../../components/website/CalculatorLinks/CalculatorLinks'
import { CalculatorResult } from '../../../components/website/CalculatorResult/CalculatorResult'
import { CalculatorContent } from '../../../components/website/CalculatorContent/CalculatorContent'
import { Helmet } from "react-helmet";
import './Calculators.css'

/* CALCULADORA INTERPRETAÇÃO DOS VALORES DE PEPTÍDEOS NATRIURÉTICOS */
const CalculatorPeptideosNatriureticosPage = () => {

  const [parentCheckbox, setParentCheckbox] = useState('');
  const [ntCronicoCheckbox, setNtCronicoCheckbox] = useState(false);
  const [ntAgudoCheckbox, setNtAgudoCheckbox] = useState(false);
  const [bnpCronicoCheckbox, setBnpCronicoCheckbox] = useState(false);
  const [bnpAgudoCheckbox, setBnpAgudoCheckbox] = useState(false);
  const [checkboxFinalValue, setCheckboxFinalValue] = useState('');

  const clearCheckbox = (target, action) => {
    if (!target) {
      setNtCronicoCheckbox(false)
      setNtAgudoCheckbox(false)
      setBnpCronicoCheckbox(false)
      setBnpAgudoCheckbox(false)
    }
    setCheckboxFinalValue('')
    action()
  }

  const targetFinalValue = (value) => {
    setCheckboxFinalValue(value)
    handleValuesChange(value)
  }

  const [resultMessage1, setResultMessage1] = useState('')
  const [resultMessage2, setResultMessage2] = useState('')
  const [resultMessage3, setResultMessage3] = useState('')
  const [resultMessageType, setResultMessageType] = useState('')
  const [resultMessageValue, setResultMessageValue] = useState('')
  const [resultMessageValue2, setResultMessageValue2] = useState('')
  const [form] = Form.useForm();

  const handleValuesChange = (changedValue) => {

    let pepResultado = '';
    pepResultado = parentCheckbox === 'ntprobnp' ? 'NT-PROBNP:' : 'BNP:';
    let conditionMessage1 = '';
    let conditionMessage2 = '';
    let conditionMessage3 = '';
    let conditionMessageDetail = '';
        
    setResultMessageType(`${pepResultado}`)

    const splitFinalValue = changedValue.split('_')
    const newFinalValue = splitFinalValue[0]
        
    if(parentCheckbox === 'ntprobnp'){
        if(ntCronicoCheckbox){
          if(newFinalValue === '< 125'){
            conditionMessage1 = '< 125: IC pouco provável'
            conditionMessage2 = '125 - 600: Zona Cinzenta'
            conditionMessage3 = '> 600: IC provável'
            conditionMessageDetail = 'IC pouco provável'
          } else if(newFinalValue === '> 600'){
            conditionMessage1 = '< 125: IC pouco provável'
            conditionMessage2 = '125 - 600: Zona Cinzenta'
            conditionMessage3 = '> 600: IC provável'
            conditionMessageDetail = 'IC provável'
          } else if(newFinalValue === '125 - 600'){
            conditionMessage1 = '< 125: IC pouco provável'
            conditionMessage2 = '125 - 600: Zona Cinzenta'
            conditionMessage3 = '> 600: IC provável'
            conditionMessageDetail = 'IC provável'
            conditionMessageDetail = 'Zona Cinzenta'
          }
        } else if (ntAgudoCheckbox) {
          if(newFinalValue === '< 300'){
            if (splitFinalValue[1] === '3') {
              conditionMessage1 = '< 300: IC pouco provável'
              conditionMessage2 = '300 - 1800: Zona Cinzenta'
              conditionMessage3 = '> 1800: IC provável'
            } else if (splitFinalValue[1] === '2') {
              conditionMessage1 = '< 300: IC pouco provável'
              conditionMessage2 = '300 - 900: Zona Cinzenta'
              conditionMessage3 = '> 900: IC provável'
            } else {
              conditionMessage1 = '< 300: IC pouco provável'
              conditionMessage2 = '300 - 450: Zona Cinzenta'
              conditionMessage3 = '> 450: IC provável'
            }
            conditionMessageDetail = 'IC pouco provável'
          } else if(newFinalValue === '> 450'){
            conditionMessage1 = '< 300: IC pouco provável'
            conditionMessage2 = '300 - 450: Zona Cinzenta'
            conditionMessage3 = '> 450: IC provável'
            conditionMessageDetail = 'IC provável'
          } else if(newFinalValue === '> 900'){
            conditionMessage1 = '< 300: IC pouco provável'
            conditionMessage2 = '300 - 900: Zona Cinzenta'
            conditionMessage3 = '> 900: IC provável'
            conditionMessageDetail = 'IC provável'
          }  else if(newFinalValue === '> 1800'){
            conditionMessage1 = '< 300: IC pouco provável'
            conditionMessage2 = '300 - 1800: Zona Cinzenta'
            conditionMessage3 = '> 1800: IC provável'
            conditionMessageDetail = 'IC provável'
          } else if(newFinalValue === '300 - 450'){
            conditionMessage1 = '< 300: IC pouco provável'
            conditionMessage2 = '300 - 450: Zona Cinzenta'
            conditionMessage3 = '> 450: IC provável'
            conditionMessageDetail = 'Zona Cinzenta'
          } else if(newFinalValue === '300 - 900'){
            conditionMessage1 = '< 300: IC pouco provável'
            conditionMessage2 = '300 - 900: Zona Cinzenta'
            conditionMessage3 = '> 900: IC provável'
            conditionMessageDetail = 'Zona Cinzenta'
          } else if(newFinalValue === '300 - 1800'){
            conditionMessage1 = '< 300: IC pouco provável'
            conditionMessage2 = '300 - 1800: Zona Cinzenta'
            conditionMessage3 = '> 1800: IC provável'
            conditionMessageDetail = 'Zona Cinzenta'
          }
        }
    } else if(parentCheckbox === 'bnp') {
      if(bnpCronicoCheckbox){
        if(newFinalValue === '< 100'){
          conditionMessage1 = '< 100: IC pouco provável'
          conditionMessage2 = '100 - 400: Zona Cinzenta'
          conditionMessage3 = '> 400: IC provável'
          conditionMessageDetail = 'IC pouco provável'
        } else if(newFinalValue === '> 400'){
          conditionMessage1 = '< 100: IC pouco provável'
          conditionMessage2 = '100 - 400: Zona Cinzenta'
          conditionMessage3 = '> 400: IC provável'
          conditionMessageDetail = 'IC provável'
        } else if(newFinalValue === '100 - 400'){
          conditionMessage1 = '< 100: IC pouco provável'
          conditionMessage2 = '100 - 400: Zona Cinzenta'
          conditionMessage3 = '> 400: IC provável'
          conditionMessageDetail = 'Zona Cinzenta'
        }
      } else if(bnpAgudoCheckbox) {
        if(newFinalValue === '< 35'){
          conditionMessage1 = '< 35: IC pouco provável'
          conditionMessage2 = '35 - 150: Zona Cinzenta'
          conditionMessage3 = '> 150: IC provável'
          conditionMessageDetail = 'IC pouco provável'
        } else if(newFinalValue === '> 150'){
          conditionMessage1 = '< 35: IC pouco provável'
          conditionMessage2 = '35 - 150: Zona Cinzenta'
          conditionMessage3 = '> 150: IC provável'
          conditionMessageDetail = 'IC provável'
        } else if(newFinalValue === '35 - 150'){
          conditionMessage1 = '< 35: IC pouco provável'
          conditionMessage2 = '35 - 150: Zona Cinzenta'
          conditionMessage3 = '> 150: IC provável'
          conditionMessageDetail = 'Zona Cinzenta'
        }
      }
    }
          
    setResultMessage1(conditionMessage1);
    setResultMessage2(conditionMessage2);
    setResultMessage3(conditionMessage3);
    setResultMessageValue(newFinalValue)
    setResultMessageValue2(conditionMessageDetail)
  }

  return(
    <>
      <Helmet>
        <title>Calculadora INTERPRETAÇÃO DOS VALORES DE PEPTÍDEOS NATRIURÉTICOS | Formação Científica em Medicina Cardiovascular</title>
        <meta name="description" content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares." />
        <meta name="keywords" content="calculadoras área cardiovascular, Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular" />
        <meta property="og:title" content="Calculadora INTERPRETAÇÃO DOS VALORES DE PEPTÍDEOS NATRIURÉTICOS | Formação Científica em Medicina Cardiovascular" />
        <meta property="og:description" content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares." />
      </Helmet>
      <CalculatorBanner 
        title='INTERPRETAÇÃO DOS VALORES DE PEPTÍDEOS NATRIURÉTICOS' 
      />
      
      <Form>
        <CalculatorContent>
          <Form
            form={form}
          >
            {/* Row #1 */}
            <Row gutter={60} className='mb-40 blue'>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className='mb-50'> 
                <Row className='mt-10 d-flex flex-nowrap'>
                  <Col xs={3} sm={2} md={2} lg={2} xl={2}>
                    <Radio 
                      checked={(parentCheckbox === 'ntprobnp') && true} 
                      onClick={(e) => setParentCheckbox(e.target.value)}
                      value={'ntprobnp'}
                      style={{
                        width: 'auto',
                        transform: 'scale(1.5)',
                        marginRight: '8px',
                      }}
                    />
                  </Col>
                  <Col xs={21} sm={22} md={22} lg={22} xl={22}>
                    <p className='bold'>NT-proBNP</p>
                    <Row className='d-flex flex-column'>
                      <p>Contexto Agudo ou Crónico?</p>

                      <Row className={(parentCheckbox === 'ntprobnp') ? 'd-flex flex-column' : 'd-flex flex-column content-disabled'}>
                        <p className='bold mt-20'>Contexto?</p>
                        <p className='mt-20'>
                          <Radio 
                            checked={ntCronicoCheckbox} 
                            onClick={() => {clearCheckbox(ntCronicoCheckbox, () => {setNtCronicoCheckbox(!ntCronicoCheckbox)})}}
                            style={{
                              width: 'auto',
                              transform: 'scale(1.5)',
                              marginRight: '10px',
                            }}
                          />
                          Se crónico:
                        </p>
                        <Row gutter={[{ xs: 20, sm: 8, md: 16, lg: 30 }, { xs: 20, sm: 8, md: 16, lg: 30 }]} className={`mt-20 pt-20 ml-0 mr-0 ${ntCronicoCheckbox ? '' : 'content-disabled'}`} style={{borderTop: '1px dashed #707070' }}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Radio 
                              checked={checkboxFinalValue === '< 125'} 
                              onClick={() => targetFinalValue('< 125')}
                              style={{
                                width: 'auto',
                                transform: 'scale(1.5)',
                                marginRight: '10px',
                              }}
                            />
                            {'< 125'}
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Radio 
                              checked={checkboxFinalValue === '125 - 600'} 
                              onClick={() => targetFinalValue('125 - 600')}
                              style={{
                                width: 'auto',
                                transform: 'scale(1.5)',
                                marginRight: '10px',
                              }}
                            />
                            125 - 600
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Radio 
                              checked={checkboxFinalValue === '> 600'} 
                              onClick={() => targetFinalValue('> 600')}
                              style={{
                                width: 'auto',
                                transform: 'scale(1.5)',
                                marginRight: '10px',
                              }}
                            />
                            {'> 600'}
                          </Col>
                        </Row>
                        <p className='mt-60'>
                          <Radio 
                            checked={ntAgudoCheckbox} 
                            onClick={() => {clearCheckbox(ntAgudoCheckbox, () => {setNtAgudoCheckbox(!ntAgudoCheckbox)})}}
                            style={{
                              width: 'auto',
                              transform: 'scale(1.5)',
                              marginRight: '10px',
                            }}
                          />
                          Se agudo:
                        </p>
                        <Row gutter={[{ xs: 20, sm: 8, md: 16, lg: 30 }, { xs: 20, sm: 8, md: 16, lg: 30 }]} className={`mt-20 pt-20 ml-0 mr-0 ${ntAgudoCheckbox ? '' : 'content-disabled'}`} style={{borderTop: '1px dashed #707070' }}>
                          <Col span={24} className='pb-20' style={{borderBottom: '1px dashed #707070' }}>
                            <p className='bold'>Idade?</p>
                            <p className='bold mt-20'>{'< 50 anos'}</p>
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Radio 
                              checked={checkboxFinalValue === '< 300'} 
                              onClick={() => targetFinalValue('< 300')}
                              style={{
                                width: 'auto',
                                transform: 'scale(1.5)',
                                marginRight: '10px',
                              }}
                            />
                            {'< 300'}
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Radio 
                              checked={checkboxFinalValue === '300 - 450'} 
                              onClick={() => targetFinalValue('300 - 450')}
                              style={{
                                width: 'auto',
                                transform: 'scale(1.5)',
                                marginRight: '10px',
                              }}
                            />
                            300 - 450
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Radio 
                              checked={checkboxFinalValue === '> 450'} 
                              onClick={() => targetFinalValue('> 450')}
                              style={{
                                width: 'auto',
                                transform: 'scale(1.5)',
                                marginRight: '10px',
                              }}
                            />
                            {'> 450'}
                          </Col>
                          <Col span={24} className='mb-20 pb-20' style={{borderBottom: '1px dashed #707070' }}>
                            <p className='bold mt-20'>{'50 - 75 anos'}</p>
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Radio 
                              checked={checkboxFinalValue === '< 300_2'} 
                              onClick={() => targetFinalValue('< 300_2')}
                              style={{
                                width: 'auto',
                                transform: 'scale(1.5)',
                                marginRight: '10px',
                              }}
                            />
                            {'< 300'}
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Radio 
                              checked={checkboxFinalValue === '300 - 900'} 
                              onClick={() => targetFinalValue('300 - 900')}
                              style={{
                                width: 'auto',
                                transform: 'scale(1.5)',
                                marginRight: '10px',
                              }}
                            />
                            300 - 900
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Radio 
                              checked={checkboxFinalValue === '> 900'} 
                              onClick={() => targetFinalValue('> 900')}
                              style={{
                                width: 'auto',
                                transform: 'scale(1.5)',
                                marginRight: '10px',
                              }}
                            />
                            {'> 900'}
                          </Col>
                          <Col span={24} className='mb-20 pb-20' style={{borderBottom: '1px dashed #707070' }}>
                            <p className='bold mt-20'>{'> 75 anos'}</p>
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Radio 
                              checked={checkboxFinalValue === '< 300_3'} 
                              onClick={() => targetFinalValue('< 300_3')}
                              style={{
                                width: 'auto',
                                transform: 'scale(1.5)',
                                marginRight: '10px',
                              }}
                            />
                            {'< 300'}
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Radio 
                              checked={checkboxFinalValue === '300 - 1800'} 
                              onClick={() => targetFinalValue('300 - 1800')}
                              style={{
                                width: 'auto',
                                transform: 'scale(1.5)',
                                marginRight: '10px',
                              }}
                            />
                            300 - 1800
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Radio 
                              checked={checkboxFinalValue === '> 1800'} 
                              onClick={() => targetFinalValue('> 1800')}
                              style={{
                                width: 'auto',
                                transform: 'scale(1.5)',
                                marginRight: '10px',
                              }}
                            />
                            {'> 1800'}
                          </Col>
                        </Row>
                      </Row>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Row gutter={[{ xs: 20, sm: 8, md: 16, lg: 30 }, { xs: 20, sm: 8, md: 16, lg: 30 }]} className='mt-10 d-flex flex-nowrap'>
                  <Col xs={3} sm={2} md={2} lg={2} xl={2}>
                    <Radio 
                      checked={(parentCheckbox === 'bnp') && true} 
                      onClick={(e) => setParentCheckbox(e.target.value)}
                      value={'bnp'}
                      style={{
                        width: 'auto',
                        transform: 'scale(1.5)',
                        marginRight: '8px',
                      }}
                    />
                  </Col>
                  <Col xs={21} sm={22} md={22} lg={22} xl={22}>
                    <p className='bold'>BNP</p>
                    <Row className='d-flex flex-column'>
                      <p>Contexto Agudo ou Crónico?</p>

                      <Row className={(parentCheckbox === 'bnp') ? 'd-flex flex-column' : 'd-flex flex-column content-disabled'}>
                        <p className='bold mt-20'>Contexto?</p>
                        <p className='mt-20'>
                          <Radio 
                            checked={bnpCronicoCheckbox} 
                            onClick={() => {clearCheckbox(bnpCronicoCheckbox, () => {setBnpCronicoCheckbox(!bnpCronicoCheckbox)})}}
                            style={{
                              width: 'auto',
                              transform: 'scale(1.5)',
                              marginRight: '10px',
                            }}
                          />
                          Se crónico:
                        </p>
                        <Row gutter={[{ xs: 20, sm: 8, md: 16, lg: 30 }, { xs: 20, sm: 8, md: 16, lg: 30 }]} className={(bnpCronicoCheckbox) ? 'mt-20 pt-20' : 'mt-20 pt-20 content-disabled'} style={{borderTop: '1px dashed #707070' }}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Radio 
                              checked={checkboxFinalValue === '< 100'} 
                              onClick={() => targetFinalValue('< 100')}
                              style={{
                                width: 'auto',
                                transform: 'scale(1.5)',
                                marginRight: '10px',
                              }}
                            />
                            {'< 100'}
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Radio 
                              checked={checkboxFinalValue === '100 - 400'} 
                              onClick={() => targetFinalValue('100 - 400')}
                              style={{
                                width: 'auto',
                                transform: 'scale(1.5)',
                                marginRight: '10px',
                              }}
                            />
                            100 - 400
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Radio 
                              checked={checkboxFinalValue === '> 400'} 
                              onClick={() => targetFinalValue('> 400')}
                              style={{
                                width: 'auto',
                                transform: 'scale(1.5)',
                                marginRight: '10px',
                              }}
                            />
                            {'> 400'}
                          </Col>
                        </Row>
                        <p className='mt-60'>
                          <Radio 
                            checked={bnpAgudoCheckbox} 
                            onClick={() => {clearCheckbox(bnpAgudoCheckbox, () => {setBnpAgudoCheckbox(!bnpAgudoCheckbox)})}}
                            style={{
                              width: 'auto',
                              transform: 'scale(1.5)',
                              marginRight: '10px',
                            }}
                          />
                          Se agudo:
                        </p>
                        <Row gutter={[{ xs: 20, sm: 8, md: 16, lg: 30 }, { xs: 20, sm: 8, md: 16, lg: 30 }]} className={(bnpAgudoCheckbox) ? 'mt-20 pt-20' : 'mt-20 pt-20 content-disabled'} style={{borderTop: '1px dashed #707070' }}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Radio 
                              checked={checkboxFinalValue === '< 35'} 
                              onClick={() => targetFinalValue('< 35')}
                              style={{
                                width: 'auto',
                                transform: 'scale(1.5)',
                                marginRight: '10px',
                              }}
                            />
                            {'< 35'}
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Radio 
                              checked={checkboxFinalValue === '35 - 150'} 
                              onClick={() => targetFinalValue('35 - 150')}
                              style={{
                                width: 'auto',
                                transform: 'scale(1.5)',
                                marginRight: '10px',
                              }}
                            />
                            35 - 150
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Radio 
                              checked={checkboxFinalValue === '> 150'} 
                              onClick={() => targetFinalValue('> 150')}
                              style={{
                                width: 'auto',
                                transform: 'scale(1.5)',
                                marginRight: '10px',
                              }}
                            />
                            {'> 150'}
                          </Col>
                        </Row>
                      </Row>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </CalculatorContent>

        {(resultMessageType && resultMessageValue) && (
          <CalculatorResult>
            <Row className='h-100 ai-center jc-start'>
              <Col xs={24} sm={16} md={16} lg={16} xl={16}>
                <Row>
                  <p className='f-16 text-uppercase mb-10'>{resultMessageType}</p>
                </Row>
                <Row>
                  <h3 className='f-48 bold'>{resultMessageValue}</h3>
                </Row>
                <Row className='mb-20'>
                  <h3 className='f-32 bold'>{resultMessageValue2}</h3>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} className='d-flex flex-column jc-center ai-center mt-15'>
                <Row gutter={[24]} className='mt-15'>
                  <div className="d-flex ai-center">
                    <div className='circle_calc yellow'></div>
                    <div className='ml-10'>
                      <p className='m-0'>{resultMessage1}</p>
                    </div>
                  </div>
                </Row>
                <Row gutter={[24]} className='mt-15'>
                  <div className="d-flex ai-center">
                    <div className='circle_calc orange'></div>
                    <div className='ml-10'>
                      <p className='m-0'>{resultMessage2}</p>
                    </div>
                  </div>
                </Row>
                <Row gutter={[24]} className='mt-15'>
                  <div className="d-flex ai-center">
                    <div className='circle_calc red'></div>
                    <div className='ml-10'>
                      <p className='m-0'>{resultMessage3}</p>
                    </div>
                  </div>
                </Row>
              </Col>
            </Row>
          </CalculatorResult>
        )}
      </Form>
      
      <CalculatorLinks />
    </>
  )
}
export default CalculatorPeptideosNatriureticosPage