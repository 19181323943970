import React, { useState, useEffect } from "react";
import axios from "axios";
import endpoints from "../../../services/endpoints";
import BannerGuiasPraticos from "../../../components/website/BannerGuiasPraticos/BannerGuiasPraticos";
import ListGuiasPraticos from "../../../components/website/ListGuiasPraticos/ListGuiasPraticos";
import { Button, Col, Row, Spin, Statistic, Image, Form, Radio, Popover, Empty } from "antd";
import { Helmet } from "react-helmet";
import BgGuiasPraticos from "../../../assets/images/Cardio4All-2024-casos-clinicos@2x.jpg";
import { Link, useParams } from "react-router-dom";
import dayjs from "dayjs";
import BannerPrincipal from "../../../components/website/BannerPrincipal/BannerPrincipal";
import BialImage from "../../../assets/images/BIAL.png";
import "./ClinicalCases.css";
import config from "../../../services/config";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Swiper, SwiperSlide } from "swiper/react";
import courseImage from "../../../assets/images/curso-leitura-electrocardiograma.png";

import ReactPlayer from "react-player";
import questionImage from "../../../assets/images/question.svg";
import rightAnswerImage from "../../../assets/images/right.svg";
import wrongAnswerImage from "../../../assets/images/wrong.svg";
import imageBanner from "../../../assets/images/calculadoras-cientificas@2x.png";
import electroIcon from "../../../assets/images/electrocardiograma-icon.png";

const { Countdown } = Statistic;
const ClinicalCaseDetails = () => {
  const [data, setData] = useState({});
  const [clinicalCases, setClinicalCases] = useState([]);
  const [calculators, setCalculators] = useState([]);
  const [guides, setGuides] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  const { slug } = useParams();

  const [form] = Form.useForm();

  useEffect(() => {
    handleGetData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function handleGetData() {
    axios
      .get(endpoints.clinicalCases.readBySlug, {
        params: { slug },
      })
      .then((res) => {
        console.log(res);
        if (res.data.clinical_case[0].length > 0) {
          let auxData = res.data.clinical_case[0][0];
          auxData.patient_info = typeof auxData.patient_info === "string" ? JSON.parse(auxData.patient_info) : auxData.patient_info;

          let today = dayjs();
          let dayOfPublish = dayjs(auxData.publish_date);
          if (today.diff(dayOfPublish) > 0) {
            auxData.is_active = true;
          } else {
            auxData.is_active = false;
          }

          setData(res.data.clinical_case[0][0]);
          setClinicalCases(res.data.clinical_case[1]);
          setCalculators(res.data.clinical_case[2]);
          setGuides(res.data.clinical_case[3]);
          setQuestions(res.data.questions);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleSubmit(_, allValues) {
    console.log(allValues);
  }

  function handleFormatGuidelines(guidelines) {
    if (guidelines) {
      let aux = JSON.parse(guidelines);
      console.log(aux);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <Helmet>
        <title>Guias Práticos do Cardio4all de a atualização científica e com as últimas guidelines de diagnóstico e tratamento da doença cardiovascular</title>
        <meta name="description" content="Guias Práticos do Cardio4all úteis para a prática clínica e formação médica em Medicina Geral e Familiar e de Medicina Interna." />
        <meta
          name="keywords"
          content="Guias Práticos do Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, calculadoras área cardiovascular, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta
          property="og:title"
          content="Guias Práticos do Cardio4all de a atualização científica e com as últimas guidelines de diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:description" content="Guias Práticos do Cardio4all úteis para a prática clínica e formação médica em Medicina Geral e Familiar e de Medicina Interna." />
        <meta property="og:image" content={`${BgGuiasPraticos}`} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Row style={{ backgroundColor: "#F5F5F5" }}>
        <div className="container mt-20" style={{ backgroundColor: "#F5F5F5" }}>
          <div className="d-flex">
            <Link to={`/casos-clinicos`} className="d-flex flex-column">
              <p className="f-16 blue">Voltar a casos clínicos</p>
              <span className="d-flex ai-center jc-center mt-10">
                <ArrowLeftOutlined className="blue" />
              </span>
            </Link>
          </div>
        </div>
      </Row>
      <Row style={{ backgroundColor: "#F5F5F5" }}>
        {Object.keys(data).length > 0 ? (
          <Row gutter={[24]} className="container mt-20">
            <Col xs={24} sm={24} md={6} lg={3} xl={3} className="d-flex flex-column jc-center">
              <img className="w-100 maxw-200 patient_img" src={config.server_ip + "/assets/media/" + data.image} />
            </Col>
            <Col xs={24} sm={24} md={18} lg={21} xl={21} className="d-flex flex-column jc-center">
              <h1 className="f-36 bold blue">{data.title}</h1>
              <p className="f-26 blue">{data.text}</p>
            </Col>
            {!data.is_active && (
              <Col span={24}>
                <p className="blue f-20 mt-40 mb-40 text-center">
                  Disponível <span className="text-uppercase">{dayjs(data.publish_date).format("DD MMM")}</span>
                </p>
              </Col>
            )}
          </Row>
        ) : (
          <Row gutter={[24]} className="container mt-20 pb-60">
            <Col span={24}>
              <Empty description={<p className="blue f-20 mt-40 mb-40 text-center">Não encontramos nenhum desafio com este url</p>} />
            </Col>
          </Row>
        )}
        {data.is_active && data.patient_info && (
          <div className="container">
            <Row gutter={[24]}>
              {data.patient_info.map((item) => (
                <Col xs={24} sm={24} md={24} lg={24 / data.patient_info.length} xl={24 / data.patient_info.length} className="mt-20">
                  <div className="card-patient-info p-20 h-100">
                    <p className="mb-10 bold blue">{item.title}</p>
                    <div className="blue" dangerouslySetInnerHTML={{ __html: item.text }} />
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        )}

        {data.is_active && (
          <div className="container mt-40 pb-60">
            <div className="patient-description" dangerouslySetInnerHTML={{ __html: data.description }}></div>
          </div>
        )}
      </Row>

      {questions.length > 0 && (
        <Form form={form} onValuesChange={handleSubmit} layout="vertical">
          {questions.map((item, index) => (
            <>
              <Row className="question_row">
                <Row gutter={[24]} className="container">
                  <Col xs={0} sm={0} md={6} lg={6} xl={6} className="d-flex jc-center ai-center">
                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues[index] !== currentValues[index]}>
                      {({ getFieldValue }) =>
                        getFieldValue(index)?.title ? (
                          getFieldValue(index)?.is_correct ? (
                            <img src={rightAnswerImage} className="w-100 maxw-150" />
                          ) : (
                            <img src={wrongAnswerImage} className="w-100 maxw-150" />
                          )
                        ) : (
                          <img src={questionImage} className="w-100 maxw-200" />
                        )
                      }
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                    <p className="f-30 blue bold mb-10">PERGUNTA #{index + 1}</p>
                    <p className="blue f-22 mb-10">{item.question}</p>
                    <Form.Item name={index}>
                      <Radio.Group className="d-flex flex-column">
                        {item.answers &&
                          JSON.parse(item.answers).map((q, i) => (
                            <Radio className="mt-10 w-100 f-18 blue" value={q}>
                              <b>{letters[i]}</b>. {q.title}
                            </Radio>
                          ))}
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues[index] !== currentValues[index]}>
                  {({ getFieldValue }) =>
                    getFieldValue(index)?.title ? (
                      <Row className={` guidelines ${getFieldValue(index)?.is_correct ? "correct" : "incorrect"}`}>
                        <Row gutter={[24]} className={`container pb-0 pt-0 guidelines ${getFieldValue(index)?.is_correct ? "correct" : "incorrect"}`}>
                          <Col xs={0} sm={0} md={6} lg={6} xl={6} className="d-flex jc-center ai-center" style={{ marginTop: "-30px" }}>
                            <div className="arrow-up"></div>
                          </Col>
                          <Col span={18}></Col>
                        </Row>
                        <Row gutter={[24]} className={`container guidelines ${getFieldValue(index)?.is_correct ? "correct" : "incorrect"}`}>
                          <Col xs={24} sm={24} md={24} lg={6} xl={6} className="mt-20">
                            {!getFieldValue(index).is_correct && (
                              <div className="mb-20">
                                <p className="white bold f-24">Resposta certa seria:</p>
                                <p className="m-0 white f-20">
                                  <b>{item.answers && letters[JSON.parse(item.answers).findIndex((item) => item.is_correct === true)]}</b>.{" "}
                                  {item.answers && JSON.parse(item.answers).filter((item) => item.is_correct === true)[0]?.title}
                                </p>
                              </div>
                            )}
                            <div className="cta_orientations">
                              <p className="blue f-20">Entenda melhor todo o processo por detrás do Caso Clínico.</p>
                              <p className="blue f-20 bold mt-30 mb-30">Clique aqui para ver a orientação completa do caso clínico</p>
                              <Link to={`/casos-clinicos/${slug}/orientacoes`}>
                                <Button type="primary" danger size="large">
                                  Orientações Caso Clínico
                                </Button>
                              </Link>
                            </div>
                          </Col>

                          <Col xs={24} sm={24} md={24} lg={18} xl={18} className="mt-20 guidelines-text">
                            <p className="white f-24 bold">
                              O que dizem as <i>Guidelines</i>?
                            </p>
                            <div className="white">
                              {item.guidelines
                                ? JSON.parse(item.guidelines).guidelines.map((guideline) => {
                                    if (guideline.type === "text") {
                                      return <div className="mt-30 guideline_text" dangerouslySetInnerHTML={{ __html: guideline.text }} />;
                                    } else if (guideline.type === "image") {
                                      console.log(guideline);
                                      return (
                                        <div className="mt-30">
                                          {windowWidth > 600 ? (
                                            <img style={{ maxWidth: 700 }} className="w-100 mt-20 mb-20" src={`${config.server_ip}/assets/media/${guideline.image}`} />
                                          ) : (
                                            <img className="w-100 mt-20 mb-20" src={`${config.server_ip}/assets/media/${guideline.image_mobile}`} />
                                          )}
                                        </div>
                                      );
                                    } else if (guideline.type === "references") {
                                      return <div className="references mt-30" dangerouslySetInnerHTML={{ __html: guideline.references }} />;
                                    } else if (guideline.type === "video") {
                                      console.log(guideline);
                                      return (
                                        <Row className="mt-30 video-cta">
                                          <Col xs={24} sm={24} md={guideline.image ? 16 : 24} lg={guideline.image ? 16 : 24} xl={guideline.image ? 16 : 24} className="p-20">
                                            <p className="white f-18 bold">{guideline.title}</p>
                                            <div className="white mt-10 mb-10" dangerouslySetInnerHTML={{ __html: guideline.text }} />
                                            <Popover
                                              overlayClassName="popover-guideline-video"
                                              content={
                                                <div className="player-wrapper">
                                                  <ReactPlayer className="react-player" controls volume={1} url={guideline.button_link} />
                                                </div>
                                              }
                                              title=""
                                              trigger="click"
                                            >
                                              <Button className="mt-10" danger size="large" type="primary">
                                                {guideline.button_text}
                                              </Button>
                                            </Popover>
                                          </Col>
                                          {guideline.image && (
                                            <Col
                                              xs={24}
                                              sm={24}
                                              md={8}
                                              lg={8}
                                              xl={8}
                                              className="d-flex flex-column jc-center background-video-cta"
                                              style={{ backgroundImage: `url(${config.server_ip}/assets/media/${guideline.image})` }}
                                            ></Col>
                                          )}
                                        </Row>
                                      );
                                    } else if (guideline.type === "guide") {
                                      let auxGuide = guides.filter((guide) => guide.id === guideline.guide)[0];
                                      return (
                                        <Row className="h-100 guide-cta mt-30">
                                          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="p-20 d-flex flex-column jc-center">
                                            <p className="blue f-18 bold">Guia Cardio4ALL {auxGuide.title}</p>
                                            <Link to={`/guias-praticos/` + auxGuide.slug} target="_blank">
                                              <Button className="mt-30" danger size="large" type="primary">
                                                Aceder ao guia
                                              </Button>
                                            </Link>
                                          </Col>
                                          <Col
                                            xs={24}
                                            sm={24}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            style={{ background: `url(${config.server_ip}/assets/media/${auxGuide.image})`, minHeight: 100 }}
                                          ></Col>
                                        </Row>
                                      );
                                    } else if (guideline.type === "calculator") {
                                      let auxCalculator = calculators.filter((guide) => guide.id === guideline.calculator)[0];
                                      console.log(auxCalculator);
                                      return (
                                        <Row className="h-100 guide-cta mt-30">
                                          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="p-20 d-flex flex-column jc-center">
                                            <p className="blue f-18 bold">{auxCalculator.name}</p>
                                            <Link to={auxCalculator.slug} target="_blank">
                                              <Button className="mt-30" danger size="large" type="primary">
                                                Aceder Calculadora
                                              </Button>
                                            </Link>
                                          </Col>
                                          <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ background: `url(${imageBanner})`, minHeight: 100 }}></Col>
                                        </Row>
                                      );
                                    }
                                  })
                                : null}
                            </div>
                          </Col>
                        </Row>
                      </Row>
                    ) : null
                  }
                </Form.Item>
              </Row>
              {index === 0 && (
                <>
                  {data.is_active && (
                    <div className="container d-flex jc-center flex-column">
                      <div className="d-flex ai-center mt-20">
                        <p className="blue bold f-20">Electrocardiograma</p>
                      </div>
                      {data.electrocardiogram && (
                        <div>
                          <Row gutter={[24]}>
                            <Col xs={24} sm={24} md={24} lg={16} xl={16} className="mt-20">
                              <div className="d-flex flex-column">
                                <Image src={`${config.server_ip}/assets/media/${data.electrocardiogram}`} className="w-100" />
                                <div className="blue mt-20 electrocardiogram_subtitle" dangerouslySetInnerHTML={{ __html: data.electrocardiogram_subtitle }}></div>
                              </div>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={8} xl={8} className="mt-20">
                              <div className="electro-course-cta h-100" style={{ maxHeight: 450 }}>
                                <div className="w-100 h-100 background-course" style={{ backgroundImage: `url(${courseImage})`, minHeight: 100, maxHeight: 150 }}></div>
                                <div className="p-20 d-flex flex-column jc-center ai-center">
                                  <p className="f-20 blue mb-20">
                                    SE QUISER <b>MELHORAR A LEITURA E INTERPERTAÇÃO DO ECG</b> CONSULTE O NOSSO <b>CURSO CARDIO4ALL</b>
                                  </p>
                                  <p className="blue mb-20">Aceda aqui ao Curso Cardio4ALL de Interpretação de Electrocardiograma na Prática Clínica</p>
                                  <Link to="/cursos/curso-de-leitura-e-interpretacao-clinica-de-electrocardiograma">
                                    <Button size="large" danger type="primary">
                                      Aceder ao Curso
                                    </Button>
                                  </Link>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <div className="d-flex ai-center mt-40 ">
                                <img src={electroIcon} className="maxw-40 h-100 mr-10" />
                                <p className="bold blue f-20">Relatório ecocardiograma</p>
                              </div>
                              <div className="patient-description blue mt-20" dangerouslySetInnerHTML={{ __html: data.electrocardiogram_report }}></div>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </div>
                  )}
                  {data.is_active && (
                    <div className="container d-flex jc-center flex-column">
                      <p className="blue f-20 bold">Estudo analítico</p>
                      {data.analytical_study && (
                        <div>
                          <Row gutter={[24]} className="mt-20">
                            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                              <div className="mb-40">
                                <Image src={`${config.server_ip}/assets/media/${data.analytical_study}`} className="w-100 mt-10" />
                                <p className="blue f-12 mt-10">{data.analytical_study_subtitle}</p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </>
          ))}
        </Form>
      )}

      <Row gutter={[60]} className="container mb-20 mt-40 cta-orientations">
        <Col xs={24} sm={24} md={24} lg={12} xl={12} className="pt-40 pb-40">
          <p className="white f-30">
            Se quer saber a <b>ORIENTAÇÃO CORRETA DO CASO CLÍNICO</b>
          </p>
          <Link to={`/casos-clinicos/${slug}/orientacoes`}>
            <Button danger type="primary" size="large" className="mt-30">
              Ver orientação
            </Button>
          </Link>
        </Col>
      </Row>
      <Row gutter={[60]} className="container mb-50 mt-20">
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={20}
          grabCursor={true}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: "auto",
              width: "auto",
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
          className="mySwiper d-flex jc-start ai-start mt-30 w-100"
        >
          {clinicalCases.slice(0, 3).map((card, index) => {
            return (
              <SwiperSlide key={"swiper-card-" + card.id} id={card.id} className={`swiper-card`}>
                <Row align={"bottom"} className={`swipe-card ${card.is_active ? "active" : "inactive"}`}>
                  <Col
                    span={8}
                    className="h-100 d-flex jc-end ai-end p-0"
                    style={{
                      backgroundImage: `url(${config.server_ip}/assets/media/${card.image})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      filter: card.is_active ? "none" : "grayscale(100%)",
                    }}
                  >
                    <div className="d-flex white bold f-60 mr-10" style={{ lineHeight: "50px" }}>
                      {card.position}
                    </div>
                  </Col>
                  <Col span={16} className="p-20 h-100">
                    <div className="d-flex flex-column jc-sb h-100">
                      <div>
                        <p className="f-22 bold m-0" style={{ color: card.is_active ? "#0E426A" : "#9C9DA1" }}>
                          {card.name}
                        </p>
                        <p className="mt-10" style={{ color: card.is_active ? "#87A1B5" : "#9C9DA1" }}>
                          {card.type}
                        </p>
                      </div>
                      <div>
                        {card.is_active ? (
                          <Link to={`/casos-clinicos/${card.slug}`} type="text" className="f-18" style={{ color: "#F04C4B" }}>
                            Participar
                          </Link>
                        ) : (
                          <p className="bold" style={{ color: card.is_active ? "#87A1B5" : "#9C9DA1" }}>
                            Dísponvel a <span className="text-uppercase">{dayjs(card.publish_date).format("DD MMM")}</span>
                          </p>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Row>

      <Row span={24} style={{ backgroundColor: "#F5F5F5" }}>
        <Col span={24} align="middle" className="container pt-40 pb-40">
          <p className="blue">Com o patrocínio independente de:</p>
          <img src={BialImage} className="w-100 maxw-160" />
          <p className="f-12 blue italic">O patrocinador destes conteúdos Cardio4all não tem qualquer influência na criação dos mesmos</p>
        </Col>
      </Row>
    </Spin>
  );
};

export default ClinicalCaseDetails;
