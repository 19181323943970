import React, { useState, useEffect } from "react";
import { Col, Row, Select, Button, Modal, Card, Upload, Empty, message, Pagination, Form } from "antd";
import { Outlet, Link } from "react-router-dom";
import { EditOutlined, EllipsisOutlined, SettingOutlined, DeleteOutlined } from "@ant-design/icons";
import Update from "../../../components/dashboard/video/Update";
import Create from "../../../components/dashboard/video/Create";
import Delete from "../../../components/dashboard/video/Delete";
import axios from "axios";
import endpoints from "../../../services/endpoints";
import config from "../../../services/config";
import "../../../assets/styles/admin.css";
import dayjs from "dayjs";

const { Meta } = Card;
const { confirm } = Modal;

function DashboardVideos() {
  const [videos, setVideos] = useState([]);
  const [videosFiltered, setVideosFiltered] = useState([]);
  const [tags, setTags] = useState([]);
  const [guides, setGuides] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState([]);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(8);

  const [form] = Form.useForm();

  useEffect(() => {
    handleGetVideos();
  }, []);

  function handleGetVideos() {
    axios
      .get(endpoints.videos.read)
      .then((res) => {
        axios.get(endpoints.guides.read).then((resGuides) => {
          setVideos(res.data[0]);
          setVideosFiltered(res.data[0]);
          setTags(res.data[1]);
          setGuides(resGuides.data);

          if (form.getFieldsValue() && form.getFieldsValue().type) {
            handleFilter(null, form.getFieldValue());
          }
        });
      })
      .catch((error) => console.error(error));
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetVideos();
  }

  function handleOpenUpdate(data) {
    setSelectedVideo(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setSelectedVideo({});
    setIsOpenUpdate(false);
    handleGetVideos();
  }

  function handleOpenDelete(data) {
    setSelectedVideo(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setSelectedVideo({});
    setIsOpenDelete(false);
    handleGetVideos();
  }

  function handleChangePage(e) {
    setCurrentPage(e);
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(itemsPerPage);
    } else {
      let newMinValue = itemsPerPage * (e - 1);
      let newMaxValue = newMinValue + itemsPerPage;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
    }
  }

  function handleFilter(_, allValues) {
    console.log(allValues);
    if (allValues.type) {
      setVideosFiltered(videos.filter((item) => item.type === allValues.type));
    } else {
      setVideosFiltered(videos);
    }
  }

  return (
    <Row>
      <Create open={isOpenCreate} close={handleCloseCreate} tags={tags} guides={guides} />
      <Update open={isOpenUpdate} close={handleCloseUpdate} data={selectedVideo} tags={tags} guides={guides} />
      <Delete open={isOpenDelete} close={handleCloseDelete} data={selectedVideo} />
      <Col span={24}>
        <Row className="title-admin-page d-flex jc-sb ai-center">
          <Col span={12}>
            <h2 className="m-0">Videos</h2>
          </Col>
          <Col span={12}>
            <Row className="d-flex jc-end ai-center">
              <Form form={form} onValuesChange={handleFilter} className="mr-20">
                <Form.Item className="mb-0 minw-200" name="type">
                  <Select
                    allowClear
                    className="w-100 mb-0 maxw-200"
                    size="large"
                    showSearch
                    placeholder="Tipo"
                    options={[
                      {
                        value: "videocasts",
                        label: "Videocasts",
                      },
                      {
                        value: "videocasts_scientific_update",
                        label: "Videocasts atualização científica",
                      },
                      {
                        value: "guides",
                        label: "Guias",
                      },
                      {
                        value: "calculators",
                        label: "Calculadoras",
                      },
                    ]}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  />
                </Form.Item>
              </Form>
              <Button size="large" onClick={handleOpenCreate}>
                Adicionar
              </Button>
            </Row>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          {videosFiltered?.slice(minValue, maxValue).map((item) => {
            return (
              <Col span={6} key={item.id}>
                <Card
                  cover={
                    item.link ? (
                      <iframe
                        style={{
                          borderTopRightRadius: "8px",
                          borderTopLeftRadius: "8px",
                        }}
                        width="100%"
                        height="220px"
                        frameBorder="0"
                        src={`${item.link}`}
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                        loading="lazy"
                      />
                    ) : (
                      <img src={`${config.server_ip}/assets/media/${item.image}`} />
                    )
                  }
                  actions={[<EditOutlined key="edit" onClick={() => handleOpenUpdate(item)} />, <DeleteOutlined key="delete" onClick={() => handleOpenDelete(item)} />]}
                >
                  <Meta
                    title={item.title}
                    description={
                      <div className="d-flex flex-column">
                        <p>{item.guide_title}</p>
                        <p className="f-14 mt-10">{dayjs(item.date).format("DD-MM-YYYY")}</p>
                      </div>
                    }
                  />
                </Card>
              </Col>
            );
          })}

          <Row className="ml-0 mr-0" align="center">
            <Pagination showSizeChanger={false} onChange={handleChangePage} pageSize={itemsPerPage} defaultCurrent={1} current={currentPage} total={videosFiltered.length} />
          </Row>
          {videos.length === 0 && (
            <div style={{ width: "100%", display: "block", margin: "0 auto" }}>
              <Empty description="Não foram encontrados banners" image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
        </Row>
      </Col>
    </Row>
  );
}

export default DashboardVideos;
