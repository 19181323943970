const districts = [
	{
		"level": 1,
		"code": 1,
		"name": "Aveiro",
		"county": [
			{
				"level": 2,
				"code": 101,
				"name": "Águeda"
			},
			{
				"level": 2,
				"code": 102,
				"name": "Albergaria-a-Velha"
			},
			{
				"level": 2,
				"code": 103,
				"name": "Anadia"
			},
			{
				"level": 2,
				"code": 104,
				"name": "Arouca"
			},
			{
				"level": 2,
				"code": 105,
				"name": "Aveiro"
			},
			{
				"level": 2,
				"code": 106,
				"name": "Castelo de Paiva"
			},
			{
				"level": 2,
				"code": 107,
				"name": "Espinho"
			},
			{
				"level": 2,
				"code": 108,
				"name": "Estarreja"
			},
			{
				"level": 2,
				"code": 109,
				"name": "Santa Maria da Feira"
			},
			{
				"level": 2,
				"code": 110,
				"name": "Ílhavo"
			},
			{
				"level": 2,
				"code": 111,
				"name": "Mealhada"
			},
			{
				"level": 2,
				"code": 112,
				"name": "Murtosa"
			},
			{
				"level": 2,
				"code": 113,
				"name": "Oliveira de Azeméis"
			},
			{
				"level": 2,
				"code": 114,
				"name": "Oliveira do Bairro"
			},
			{
				"level": 2,
				"code": 115,
				"name": "Ovar"
			},
			{
				"level": 2,
				"code": 116,
				"name": "São João da Madeira"
			},
			{
				"level": 2,
				"code": 117,
				"name": "Sever do Vouga"
			},
			{
				"level": 2,
				"code": 118,
				"name": "Vagos"
			},
			{
				"level": 2,
				"code": 119,
				"name": "Vale de Cambra"
			}
		]
	},
	{
		"level": 1,
		"code": 2,
		"name": "Beja",
		"county": [
			{
				"level": 2,
				"code": 201,
				"name": "Aljustrel"
			},
			{
				"level": 2,
				"code": 202,
				"name": "Almodôvar"
			},
			{
				"level": 2,
				"code": 203,
				"name": "Alvito"
			},
			{
				"level": 2,
				"code": 204,
				"name": "Barrancos"
			},
			{
				"level": 2,
				"code": 205,
				"name": "Beja"
			},
			{
				"level": 2,
				"code": 206,
				"name": "Castro Verde"
			},
			{
				"level": 2,
				"code": 207,
				"name": "Cuba"
			},
			{
				"level": 2,
				"code": 208,
				"name": "Ferreira do Alentejo"
			},
			{
				"level": 2,
				"code": 209,
				"name": "Mértola"
			},
			{
				"level": 2,
				"code": 210,
				"name": "Moura"
			},
			{
				"level": 2,
				"code": 211,
				"name": "Odemira"
			},
			{
				"level": 2,
				"code": 212,
				"name": "Ourique"
			},
			{
				"level": 2,
				"code": 213,
				"name": "Serpa"
			},
			{
				"level": 2,
				"code": 214,
				"name": "Vidigueira"
			}
		]
	},
	{
		"level": 1,
		"code": 3,
		"name": "Braga",
		"county": [
			{
				"level": 2,
				"code": 301,
				"name": "Amares"
			},
			{
				"level": 2,
				"code": 302,
				"name": "Barcelos"
			},
			{
				"level": 2,
				"code": 303,
				"name": "Braga"
			},
			{
				"level": 2,
				"code": 304,
				"name": "Cabeceiras de Basto"
			},
			{
				"level": 2,
				"code": 305,
				"name": "Celorico de Basto"
			},
			{
				"level": 2,
				"code": 306,
				"name": "Esposende"
			},
			{
				"level": 2,
				"code": 307,
				"name": "Fafe"
			},
			{
				"level": 2,
				"code": 308,
				"name": "Guimarães"
			},
			{
				"level": 2,
				"code": 309,
				"name": "Póvoa de Lanhoso"
			},
			{
				"level": 2,
				"code": 310,
				"name": "Terras de Bouro"
			},
			{
				"level": 2,
				"code": 311,
				"name": "Vieira do Minho"
			},
			{
				"level": 2,
				"code": 312,
				"name": "Vila Nova de Famalicão"
			},
			{
				"level": 2,
				"code": 313,
				"name": "Vila Verde"
			},
			{
				"level": 2,
				"code": 314,
				"name": "Vizela"
			}
		]
	},
	{
		"level": 1,
		"code": 4,
		"name": "Bragança",
		"county": [
			{
				"level": 2,
				"code": 401,
				"name": "Alfândega da Fé"
			},
			{
				"level": 2,
				"code": 402,
				"name": "Bragança"
			},
			{
				"level": 2,
				"code": 403,
				"name": "Carrazeda de Ansiães"
			},
			{
				"level": 2,
				"code": 404,
				"name": "Freixo de Espada à Cinta"
			},
			{
				"level": 2,
				"code": 405,
				"name": "Macedo de Cavaleiros"
			},
			{
				"level": 2,
				"code": 406,
				"name": "Miranda do Douro"
			},
			{
				"level": 2,
				"code": 407,
				"name": "Mirandela"
			},
			{
				"level": 2,
				"code": 408,
				"name": "Mogadouro"
			},
			{
				"level": 2,
				"code": 409,
				"name": "Torre de Moncorvo"
			},
			{
				"level": 2,
				"code": 410,
				"name": "Vila Flor"
			},
			{
				"level": 2,
				"code": 411,
				"name": "Vimioso"
			},
			{
				"level": 2,
				"code": 412,
				"name": "Vinhais"
			}
		]
	},
	{
		"level": 1,
		"code": 5,
		"name": "Castelo Branco",
		"county": [
			{
				"level": 2,
				"code": 501,
				"name": "Belmonte"
			},
			{
				"level": 2,
				"code": 502,
				"name": "Castelo Branco"
			},
			{
				"level": 2,
				"code": 503,
				"name": "Covilhã"
			},
			{
				"level": 2,
				"code": 504,
				"name": "Fundão"
			},
			{
				"level": 2,
				"code": 505,
				"name": "Idanha-a-Nova"
			},
			{
				"level": 2,
				"code": 506,
				"name": "Oleiros"
			},
			{
				"level": 2,
				"code": 507,
				"name": "Penamacor"
			},
			{
				"level": 2,
				"code": 508,
				"name": "Proença-a-Nova"
			},
			{
				"level": 2,
				"code": 509,
				"name": "Sertã"
			},
			{
				"level": 2,
				"code": 510,
				"name": "Vila de Rei"
			},
			{
				"level": 2,
				"code": 511,
				"name": "Vila Velha de Ródão"
			}
		]
	},
	{
		"level": 1,
		"code": 6,
		"name": "Coimbra",
		"county": [
			{
				"level": 2,
				"code": 601,
				"name": "Arganil"
			},
			{
				"level": 2,
				"code": 602,
				"name": "Cantanhede"
			},
			{
				"level": 2,
				"code": 603,
				"name": "Coimbra"
			},
			{
				"level": 2,
				"code": 604,
				"name": "Condeixa-a-Nova"
			},
			{
				"level": 2,
				"code": 605,
				"name": "Figueira da Foz"
			},
			{
				"level": 2,
				"code": 606,
				"name": "Góis"
			},
			{
				"level": 2,
				"code": 607,
				"name": "Lousã"
			},
			{
				"level": 2,
				"code": 608,
				"name": "Mira"
			},
			{
				"level": 2,
				"code": 609,
				"name": "Miranda do Corvo"
			},
			{
				"level": 2,
				"code": 610,
				"name": "Montemor-o-Velho"
			},
			{
				"level": 2,
				"code": 611,
				"name": "Oliveira do Hospital"
			},
			{
				"level": 2,
				"code": 612,
				"name": "Pampilhosa da Serra"
			},
			{
				"level": 2,
				"code": 613,
				"name": "Penacova"
			},
			{
				"level": 2,
				"code": 614,
				"name": "Penela"
			},
			{
				"level": 2,
				"code": 615,
				"name": "Soure"
			},
			{
				"level": 2,
				"code": 616,
				"name": "Tábua"
			},
			{
				"level": 2,
				"code": 617,
				"name": "Vila Nova de Poiares"
			}
		]
	},
	{
		"level": 1,
		"code": 7,
		"name": "Évora",
		"county": [
			{
				"level": 2,
				"code": 701,
				"name": "Alandroal"
			},
			{
				"level": 2,
				"code": 702,
				"name": "Arraiolos"
			},
			{
				"level": 2,
				"code": 703,
				"name": "Borba"
			},
			{
				"level": 2,
				"code": 704,
				"name": "Estremoz"
			},
			{
				"level": 2,
				"code": 705,
				"name": "Évora"
			},
			{
				"level": 2,
				"code": 706,
				"name": "Montemor-o-Novo"
			},
			{
				"level": 2,
				"code": 707,
				"name": "Mora"
			},
			{
				"level": 2,
				"code": 708,
				"name": "Mourão"
			},
			{
				"level": 2,
				"code": 709,
				"name": "Portel"
			},
			{
				"level": 2,
				"code": 710,
				"name": "Redondo"
			},
			{
				"level": 2,
				"code": 711,
				"name": "Reguengos de Monsaraz"
			},
			{
				"level": 2,
				"code": 712,
				"name": "Vendas Novas"
			},
			{
				"level": 2,
				"code": 713,
				"name": "Viana do Alentejo"
			},
			{
				"level": 2,
				"code": 714,
				"name": "Vila Viçosa"
			}
		]
	},
	{
		"level": 1,
		"code": 8,
		"name": "Faro",
		"county": [
			{
				"level": 2,
				"code": 801,
				"name": "Albufeira"
			},
			{
				"level": 2,
				"code": 802,
				"name": "Alcoutim"
			},
			{
				"level": 2,
				"code": 803,
				"name": "Aljezur"
			},
			{
				"level": 2,
				"code": 804,
				"name": "Castro Marim"
			},
			{
				"level": 2,
				"code": 805,
				"name": "Faro"
			},
			{
				"level": 2,
				"code": 806,
				"name": "Lagoa"
			},
			{
				"level": 2,
				"code": 807,
				"name": "Lagos"
			},
			{
				"level": 2,
				"code": 808,
				"name": "Loulé"
			},
			{
				"level": 2,
				"code": 809,
				"name": "Monchique"
			},
			{
				"level": 2,
				"code": 810,
				"name": "Olhão"
			},
			{
				"level": 2,
				"code": 811,
				"name": "Portimão"
			},
			{
				"level": 2,
				"code": 812,
				"name": "São Brás de Alportel"
			},
			{
				"level": 2,
				"code": 813,
				"name": "Silves"
			},
			{
				"level": 2,
				"code": 814,
				"name": "Tavira"
			},
			{
				"level": 2,
				"code": 815,
				"name": "Vila do Bispo"
			},
			{
				"level": 2,
				"code": 816,
				"name": "Vila Real de Santo António"
			}
		]
	},
	{
		"level": 1,
		"code": 9,
		"name": "Guarda",
		"county": [
			{
				"level": 2,
				"code": 901,
				"name": "Aguiar da Beira"
			},
			{
				"level": 2,
				"code": 902,
				"name": "Almeida"
			},
			{
				"level": 2,
				"code": 903,
				"name": "Celorico da Beira"
			},
			{
				"level": 2,
				"code": 904,
				"name": "Figueira de Castelo Rodrigo"
			},
			{
				"level": 2,
				"code": 905,
				"name": "Fornos de Algodres"
			},
			{
				"level": 2,
				"code": 906,
				"name": "Gouveia"
			},
			{
				"level": 2,
				"code": 907,
				"name": "Guarda"
			},
			{
				"level": 2,
				"code": 908,
				"name": "Manteigas"
			},
			{
				"level": 2,
				"code": 909,
				"name": "Mêda"
			},
			{
				"level": 2,
				"code": 910,
				"name": "Pinhel"
			},
			{
				"level": 2,
				"code": 911,
				"name": "Sabugal"
			},
			{
				"level": 2,
				"code": 912,
				"name": "Seia"
			},
			{
				"level": 2,
				"code": 913,
				"name": "Trancoso"
			},
			{
				"level": 2,
				"code": 914,
				"name": "Vila Nova de Foz Côa"
			}
		]
	},
	{
		"level": 1,
		"code": 10,
		"name": "Leiria",
		"county": [
			{
				"level": 2,
				"code": 1001,
				"name": "Alcobaça"
			},
			{
				"level": 2,
				"code": 1002,
				"name": "Alvaiázere"
			},
			{
				"level": 2,
				"code": 1003,
				"name": "Ansião"
			},
			{
				"level": 2,
				"code": 1004,
				"name": "Batalha"
			},
			{
				"level": 2,
				"code": 1005,
				"name": "Bombarral"
			},
			{
				"level": 2,
				"code": 1006,
				"name": "Caldas da Rainha"
			},
			{
				"level": 2,
				"code": 1007,
				"name": "Castanheira de Pêra"
			},
			{
				"level": 2,
				"code": 1008,
				"name": "Figueiró dos Vinhos"
			},
			{
				"level": 2,
				"code": 1009,
				"name": "Leiria"
			},
			{
				"level": 2,
				"code": 1010,
				"name": "Marinha Grande"
			},
			{
				"level": 2,
				"code": 1011,
				"name": "Nazaré"
			},
			{
				"level": 2,
				"code": 1012,
				"name": "Óbidos"
			},
			{
				"level": 2,
				"code": 1013,
				"name": "Pedrógão Grande"
			},
			{
				"level": 2,
				"code": 1014,
				"name": "Peniche"
			},
			{
				"level": 2,
				"code": 1015,
				"name": "Pombal"
			},
			{
				"level": 2,
				"code": 1016,
				"name": "Porto de Mós"
			}
		]
	},
	{
		"level": 1,
		"code": 11,
		"name": "Lisboa",
		"county": [
			{
				"level": 2,
				"code": 1101,
				"name": "Alenquer"
			},
			{
				"level": 2,
				"code": 1102,
				"name": "Arruda dos Vinhos"
			},
			{
				"level": 2,
				"code": 1103,
				"name": "Azambuja"
			},
			{
				"level": 2,
				"code": 1104,
				"name": "Cadaval"
			},
			{
				"level": 2,
				"code": 1105,
				"name": "Cascais"
			},
			{
				"level": 2,
				"code": 1106,
				"name": "Lisboa"
			},
			{
				"level": 2,
				"code": 1107,
				"name": "Loures"
			},
			{
				"level": 2,
				"code": 1108,
				"name": "Lourinhã"
			},
			{
				"level": 2,
				"code": 1109,
				"name": "Mafra"
			},
			{
				"level": 2,
				"code": 1110,
				"name": "Oeiras"
			},
			{
				"level": 2,
				"code": 1111,
				"name": "Sintra"
			},
			{
				"level": 2,
				"code": 1112,
				"name": "Sobral de Monte Agraço"
			},
			{
				"level": 2,
				"code": 1113,
				"name": "Torres Vedras"
			},
			{
				"level": 2,
				"code": 1114,
				"name": "Vila Franca de Xira"
			},
			{
				"level": 2,
				"code": 1115,
				"name": "Amadora"
			},
			{
				"level": 2,
				"code": 1116,
				"name": "Odivelas"
			}
		]
	},
	{
		"level": 1,
		"code": 12,
		"name": "Portalegre",
		"county": [
			{
				"level": 2,
				"code": 1201,
				"name": "Alter do Chão"
			},
			{
				"level": 2,
				"code": 1202,
				"name": "Arronches"
			},
			{
				"level": 2,
				"code": 1203,
				"name": "Avis"
			},
			{
				"level": 2,
				"code": 1204,
				"name": "Campo Maior"
			},
			{
				"level": 2,
				"code": 1205,
				"name": "Castelo de Vide"
			},
			{
				"level": 2,
				"code": 1206,
				"name": "Crato"
			},
			{
				"level": 2,
				"code": 1207,
				"name": "Elvas"
			},
			{
				"level": 2,
				"code": 1208,
				"name": "Fronteira"
			},
			{
				"level": 2,
				"code": 1209,
				"name": "Gavião"
			},
			{
				"level": 2,
				"code": 1210,
				"name": "Marvão"
			},
			{
				"level": 2,
				"code": 1211,
				"name": "Monforte"
			},
			{
				"level": 2,
				"code": 1212,
				"name": "Nisa"
			},
			{
				"level": 2,
				"code": 1213,
				"name": "Ponte de Sor"
			},
			{
				"level": 2,
				"code": 1214,
				"name": "Portalegre"
			},
			{
				"level": 2,
				"code": 1215,
				"name": "Sousel"
			}
		]
	},
	{
		"level": 1,
		"code": 13,
		"name": "Porto",
		"county": [
			{
				"level": 2,
				"code": 1301,
				"name": "Amarante"
			},
			{
				"level": 2,
				"code": 1302,
				"name": "Baião"
			},
			{
				"level": 2,
				"code": 1303,
				"name": "Felgueiras"
			},
			{
				"level": 2,
				"code": 1304,
				"name": "Gondomar"
			},
			{
				"level": 2,
				"code": 1305,
				"name": "Lousada"
			},
			{
				"level": 2,
				"code": 1306,
				"name": "Maia"
			},
			{
				"level": 2,
				"code": 1307,
				"name": "Marco de Canaveses"
			},
			{
				"level": 2,
				"code": 1308,
				"name": "Matosinhos"
			},
			{
				"level": 2,
				"code": 1309,
				"name": "Paços de Ferreira"
			},
			{
				"level": 2,
				"code": 1310,
				"name": "Paredes"
			},
			{
				"level": 2,
				"code": 1311,
				"name": "Penafiel"
			},
			{
				"level": 2,
				"code": 1312,
				"name": "Porto"
			},
			{
				"level": 2,
				"code": 1313,
				"name": "Póvoa de Varzim"
			},
			{
				"level": 2,
				"code": 1314,
				"name": "Santo Tirso"
			},
			{
				"level": 2,
				"code": 1315,
				"name": "Valongo"
			},
			{
				"level": 2,
				"code": 1316,
				"name": "Vila do Conde"
			},
			{
				"level": 2,
				"code": 1317,
				"name": "Vila Nova de Gaia"
			},
			{
				"level": 2,
				"code": 1318,
				"name": "Trofa"
			}
		]
	},
	{
		"level": 1,
		"code": 14,
		"name": "Santarém",
		"county": [
			{
				"level": 2,
				"code": 1401,
				"name": "Abrantes"
			},
			{
				"level": 2,
				"code": 1402,
				"name": "Alcanena"
			},
			{
				"level": 2,
				"code": 1403,
				"name": "Almeirim"
			},
			{
				"level": 2,
				"code": 1404,
				"name": "Alpiarça"
			},
			{
				"level": 2,
				"code": 1405,
				"name": "Benavente"
			},
			{
				"level": 2,
				"code": 1406,
				"name": "Cartaxo"
			},
			{
				"level": 2,
				"code": 1407,
				"name": "Chamusca"
			},
			{
				"level": 2,
				"code": 1408,
				"name": "Constância"
			},
			{
				"level": 2,
				"code": 1409,
				"name": "Coruche"
			},
			{
				"level": 2,
				"code": 1410,
				"name": "Entroncamento"
			},
			{
				"level": 2,
				"code": 1411,
				"name": "Ferreira do Zêzere"
			},
			{
				"level": 2,
				"code": 1412,
				"name": "Golegã"
			},
			{
				"level": 2,
				"code": 1413,
				"name": "Mação"
			},
			{
				"level": 2,
				"code": 1414,
				"name": "Rio Maior"
			},
			{
				"level": 2,
				"code": 1415,
				"name": "Salvaterra de Magos"
			},
			{
				"level": 2,
				"code": 1416,
				"name": "Santarém"
			},
			{
				"level": 2,
				"code": 1417,
				"name": "Sardoal"
			},
			{
				"level": 2,
				"code": 1418,
				"name": "Tomar"
			},
			{
				"level": 2,
				"code": 1419,
				"name": "Torres Novas"
			},
			{
				"level": 2,
				"code": 1420,
				"name": "Vila Nova da Barquinha"
			},
			{
				"level": 2,
				"code": 1421,
				"name": "Ourém"
			}
		]
	},
	{
		"level": 1,
		"code": 15,
		"name": "Setúbal",
		"county": [
			{
				"level": 2,
				"code": 1501,
				"name": "Alcácer do Sal"
			},
			{
				"level": 2,
				"code": 1502,
				"name": "Alcochete"
			},
			{
				"level": 2,
				"code": 1503,
				"name": "Almada"
			},
			{
				"level": 2,
				"code": 1504,
				"name": "Barreiro"
			},
			{
				"level": 2,
				"code": 1505,
				"name": "Grândola"
			},
			{
				"level": 2,
				"code": 1506,
				"name": "Moita"
			},
			{
				"level": 2,
				"code": 1507,
				"name": "Montijo"
			},
			{
				"level": 2,
				"code": 1508,
				"name": "Palmela"
			},
			{
				"level": 2,
				"code": 1509,
				"name": "Santiago do Cacém"
			},
			{
				"level": 2,
				"code": 1510,
				"name": "Seixal"
			},
			{
				"level": 2,
				"code": 1511,
				"name": "Sesimbra"
			},
			{
				"level": 2,
				"code": 1512,
				"name": "Setúbal"
			},
			{
				"level": 2,
				"code": 1513,
				"name": "Sines"
			}
		]
	},
	{
		"level": 1,
		"code": 16,
		"name": "Viana do Castelo",
		"county": [
			{
				"level": 2,
				"code": 1601,
				"name": "Arcos de Valdevez"
			},
			{
				"level": 2,
				"code": 1602,
				"name": "Caminha"
			},
			{
				"level": 2,
				"code": 1603,
				"name": "Melgaço"
			},
			{
				"level": 2,
				"code": 1604,
				"name": "Monção"
			},
			{
				"level": 2,
				"code": 1605,
				"name": "Paredes de Coura"
			},
			{
				"level": 2,
				"code": 1606,
				"name": "Ponte da Barca"
			},
			{
				"level": 2,
				"code": 1607,
				"name": "Ponte de Lima"
			},
			{
				"level": 2,
				"code": 1608,
				"name": "Valença"
			},
			{
				"level": 2,
				"code": 1609,
				"name": "Viana do Castelo"
			},
			{
				"level": 2,
				"code": 1610,
				"name": "Vila Nova de Cerveira"
			}
		]
	},
	{
		"level": 1,
		"code": 17,
		"name": "Vila Real",
		"county": [
			{
				"level": 2,
				"code": 1701,
				"name": "Alijó"
			},
			{
				"level": 2,
				"code": 1702,
				"name": "Boticas"
			},
			{
				"level": 2,
				"code": 1703,
				"name": "Chaves"
			},
			{
				"level": 2,
				"code": 1704,
				"name": "Mesão Frio"
			},
			{
				"level": 2,
				"code": 1705,
				"name": "Mondim de Basto"
			},
			{
				"level": 2,
				"code": 1706,
				"name": "Montalegre"
			},
			{
				"level": 2,
				"code": 1707,
				"name": "Murça"
			},
			{
				"level": 2,
				"code": 1708,
				"name": "Peso da Régua"
			},
			{
				"level": 2,
				"code": 1709,
				"name": "Ribeira de Pena"
			},
			{
				"level": 2,
				"code": 1710,
				"name": "Sabrosa"
			},
			{
				"level": 2,
				"code": 1711,
				"name": "Santa Marta de Penaguião"
			},
			{
				"level": 2,
				"code": 1712,
				"name": "Valpaços"
			},
			{
				"level": 2,
				"code": 1713,
				"name": "Vila Pouca de Aguiar"
			},
			{
				"level": 2,
				"code": 1714,
				"name": "Vila Real"
			}
		]
	},
	{
		"level": 1,
		"code": 18,
		"name": "Viseu",
		"county": [
			{
				"level": 2,
				"code": 1801,
				"name": "Armamar"
			},
			{
				"level": 2,
				"code": 1802,
				"name": "Carregal do Sal"
			},
			{
				"level": 2,
				"code": 1803,
				"name": "Castro Daire"
			},
			{
				"level": 2,
				"code": 1804,
				"name": "Cinfães"
			},
			{
				"level": 2,
				"code": 1805,
				"name": "Lamego"
			},
			{
				"level": 2,
				"code": 1806,
				"name": "Mangualde"
			},
			{
				"level": 2,
				"code": 1807,
				"name": "Moimenta da Beira"
			},
			{
				"level": 2,
				"code": 1808,
				"name": "Mortágua"
			},
			{
				"level": 2,
				"code": 1809,
				"name": "Nelas"
			},
			{
				"level": 2,
				"code": 1810,
				"name": "Oliveira de Frades"
			},
			{
				"level": 2,
				"code": 1811,
				"name": "Penalva do Castelo"
			},
			{
				"level": 2,
				"code": 1812,
				"name": "Penedono"
			},
			{
				"level": 2,
				"code": 1813,
				"name": "Resende"
			},
			{
				"level": 2,
				"code": 1814,
				"name": "Santa Comba Dão"
			},
			{
				"level": 2,
				"code": 1815,
				"name": "São João da Pesqueira"
			},
			{
				"level": 2,
				"code": 1816,
				"name": "São Pedro do Sul"
			},
			{
				"level": 2,
				"code": 1817,
				"name": "Sátão"
			},
			{
				"level": 2,
				"code": 1818,
				"name": "Sernancelhe"
			},
			{
				"level": 2,
				"code": 1819,
				"name": "Tabuaço"
			},
			{
				"level": 2,
				"code": 1820,
				"name": "Tarouca"
			},
			{
				"level": 2,
				"code": 1821,
				"name": "Tondela"
			},
			{
				"level": 2,
				"code": 1822,
				"name": "Vila Nova de Paiva"
			},
			{
				"level": 2,
				"code": 1823,
				"name": "Viseu"
			},
			{
				"level": 2,
				"code": 1824,
				"name": "Vouzela"
			}
		]
	},
	{
		"level": 1,
		"code": 31,
		"name": "Ilha da Madeira",
		"county": [
			{
				"level": 2,
				"code": 3101,
				"name": "Calheta"
			},
			{
				"level": 2,
				"code": 3102,
				"name": "Câmara de Lobos"
			},
			{
				"level": 2,
				"code": 3103,
				"name": "Funchal"
			},
			{
				"level": 2,
				"code": 3104,
				"name": "Machico"
			},
			{
				"level": 2,
				"code": 3105,
				"name": "Ponta do Sol"
			},
			{
				"level": 2,
				"code": 3106,
				"name": "Porto Moniz"
			},
			{
				"level": 2,
				"code": 3107,
				"name": "Ribeira Brava"
			},
			{
				"level": 2,
				"code": 3108,
				"name": "Santa Cruz"
			},
			{
				"level": 2,
				"code": 3109,
				"name": "Santana"
			},
			{
				"level": 2,
				"code": 3110,
				"name": "São Vicente"
			}
		]
	},
	{
		"level": 1,
		"code": 32,
		"name": "Ilha de Porto Santo",
		"county": [
			{
				"level": 2,
				"code": 3201,
				"name": "Porto Santo"
			}
		]
	},
	{
		"level": 1,
		"code": 41,
		"name": "Ilha de Santa Maria",
		"county": [
			{
				"level": 2,
				"code": 4101,
				"name": "Vila do Porto"
			}
		]
	},
	{
		"level": 1,
		"code": 42,
		"name": "Ilha de São Miguel",
		"county": [
			{
				"level": 2,
				"code": 4201,
				"name": "Lagoa"
			},
			{
				"level": 2,
				"code": 4202,
				"name": "Nordeste"
			},
			{
				"level": 2,
				"code": 4203,
				"name": "Ponta Delgada"
			},
			{
				"level": 2,
				"code": 4204,
				"name": "Povoação"
			},
			{
				"level": 2,
				"code": 4205,
				"name": "Ribeira Grande"
			},
			{
				"level": 2,
				"code": 4206,
				"name": "Vila Franca do Campo"
			}
		]
	},
	{
		"level": 1,
		"code": 43,
		"name": "Ilha Terceira",
		"county": [
			{
				"level": 2,
				"code": 4301,
				"name": "Angra do Heroísmo"
			},
			{
				"level": 2,
				"code": 4302,
				"name": "Vila da Praia da Vitória"
			}
		]
	},
	{
		"level": 1,
		"code": 44,
		"name": "Ilha Graciosa",
		"county": [
			{
				"level": 2,
				"code": 4401,
				"name": "Santa Cruz da Graciosa"
			}
		]
	},
	{
		"level": 1,
		"code": 45,
		"name": "Ilha de São Jorge",
		"county": [
			{
				"level": 2,
				"code": 4501,
				"name": "Calheta"
			},
			{
				"level": 2,
				"code": 4502,
				"name": "Velas"
			}
		]
	},
	{
		"level": 1,
		"code": 46,
		"name": "Ilha do Pico",
		"county": [
			{
				"level": 2,
				"code": 4601,
				"name": "Lajes do Pico"
			},
			{
				"level": 2,
				"code": 4602,
				"name": "Madalena"
			},
			{
				"level": 2,
				"code": 4603,
				"name": "São Roque do Pico"
			}
		]
	},
	{
		"level": 1,
		"code": 47,
		"name": "Ilha do Faial",
		"county": [
			{
				"level": 2,
				"code": 4701,
				"name": "Horta"
			}
		]
	},
	{
		"level": 1,
		"code": 48,
		"name": "Ilha das Flores",
		"county": [
			{
				"level": 2,
				"code": 4801,
				"name": "Lajes das Flores"
			},
			{
				"level": 2,
				"code": 4802,
				"name": "Santa Cruz das Flores"
			}
		]
	},
	{
		"level": 1,
		"code": 49,
		"name": "Ilha do Corvo",
		"county": [
			{
				"level": 2,
				"code": 4901,
				"name": "Corvo"
			}
		]
	}
]

export default districts;
