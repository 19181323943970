import React, { useEffect, useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
import { CloseOutlined } from "@ant-design/icons";
import ReactPlayer from "react-player";
import { Col, Row, Card, Select, Space, Button, Form, Input, Checkbox, Tabs, Empty } from "antd";
import BannerSummit from "../../../assets/images/banner-summit-2023.png";
import FiltroIcon from "../../../assets/images/filtro-icon.svg";
import SearchIcon from "../../../assets/images/search-icon.svg";
import { Outlet, Link } from "react-router-dom";
import config from "../../../services/config";
import "./ListVideocasts.css";
import ModalVideo from "../ModalVideo/ModalVideo";

const { Meta } = Card;

const CalculatorsTab = ({ data, handleOpenModalVideo }) => {
  const [itemsToShow, setItemsToShow] = useState(10);

  const handleLoadMore = () => {
    setItemsToShow(itemsToShow + 10);
  };

  return (
    <>
      {data.length > 0 ? (
        <Row
          gutter={[
            { xs: 40, sm: 8, md: 16, lg: 24 },
            { xs: 40, sm: 8, md: 16, lg: 24 },
          ]}
          className="mr-0 ml-0 d-flex"
        >
          {data?.slice(0, itemsToShow + 1).map((item, index) => {
            return (
              <Col
                xs={24}
                sm={12}
                md={12}
                lg={8}
                xl={8}
                className="d-flex flex-column mb-20"
                //onClick={() => handleOpenModalVideo(item)}
              >
                <div className="player-wrapper">
                  <ReactPlayer className="react-player" controls volume={1} url={item?.link} />
                </div>
                <p className="bold blue f-18 mb-10 mt-10">{item?.title}</p>
                <p className="f-16 normal ellipsis_list_description">{item?.description}</p>
                {item?.button_text && item?.button_link && (
                  <Row className="mt-10">
                    <Col span={24} align="middle">
                      <Link to={item.button_link} target="_blank">
                        <Button type="primary" size="large">
                          {item.button_text}
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                )}
              </Col>
            );
          })}
          <Row className="d-flex jc-center ai-center mt-40 mb-40">
            {data && itemsToShow < data?.length && (
              <Button className="btn-ver-mais" onClick={handleLoadMore}>
                Explorar mais videos
              </Button>
            )}
          </Row>
        </Row>
      ) : (
        <Empty description="Não foram encontrados videos" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
};

export default CalculatorsTab;
