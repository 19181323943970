import React, { useState } from "react";
import "../../../assets/styles/calculator.css";
import { Col, Form, Row, Space, Input, Button, Radio, Card, InputNumber, Divider, Tooltip } from "antd";
import CalculatorBanner from "../../../components/website/CalculatorBanner/CalculatorBanner";
import { CalculatorLinks } from "../../../components/website/CalculatorLinks/CalculatorLinks";
import { CalculatorResult } from "../../../components/website/CalculatorResult/CalculatorResult";
import { CalculatorContent } from "../../../components/website/CalculatorContent/CalculatorContent";
import { Helmet } from "react-helmet";
import { LogoutOutlined } from "@ant-design/icons";
import Boehringer from "../../../assets/images/Boehringer-Ingelheim.svg";

const Calculator02Page = () => {
  const [points, setPoints] = useState(null);
  const [message, setMessage] = useState(null);
  const [pointsCompleted, setPointsCompleted] = useState(null);
  const [percentage, setPercentage] = useState(null);
  const [isResultLoading, setIsResultLoading] = useState(false);
  const [minValues] = useState({ heavy: 20, systolic_pressure: 20, age: 55, filling_pressure: 4 });
  const [maxValues] = useState({ heavy: 60, systolic_pressure: 90, age: 100, filling_pressure: 30 });
  const [errorFinded, setErrorFinded] = useState(null);
  const [form] = Form.useForm();
  const [formCompleted] = Form.useForm();

  function handleSubmit() {
    let allValues = form.getFieldsValue();
    let columns = Object.keys(allValues);
    let readyToCalc = true;

    for (let i = 0; i < columns.length; i++) {
      if (allValues[columns[i]] === null || allValues[columns[i]] === undefined) {
        readyToCalc = false;
        return;
      }
    }

    let sumPoints = 0;
    if (readyToCalc) {
      for (let i = 0; i < columns.length; i++) {
        if (allValues[columns[i]] === "unknown") {
          sumPoints += 0;
        } else {
          sumPoints += allValues[columns[i]];
        }
      }

      if (sumPoints <= 1) {
        setMessage("Probabilidade Baixa de ICFEP");
      } else if (sumPoints > 1 && sumPoints <= 5) {
        setMessage("Probabilidade Intermédia de ICFEP");
      } else {
        setMessage("Probabilidade Alta de ICFEP");
      }

      setPoints(sumPoints);
    }
  }

  function handleSubmitFormCompleted() {
    let allValues = formCompleted.getFieldsValue();
    let columns = Object.keys(allValues);

    let nullItems = 0;
    let auxErrorsFinded = 0;

    for (let i = 0; i < columns.length; i++) {
      if (typeof allValues[columns[i]] === "string") {
        allValues[columns[i]] = Number(allValues[columns[i]]);
      }

      if (allValues[columns[i]] === null || allValues[columns[i]] === undefined) {
        nullItems++;
      }
    }

    if (nullItems === 0) {
      const validationErrors = Object.values(formCompleted.getFieldsError());

      for (let e = 0; e < validationErrors.length; e++) {
        if (validationErrors[e].errors.length > 0) {
          auxErrorsFinded++;
        }
      }
    }

    let sumPoints = 0;

    if (nullItems === 0 && auxErrorsFinded === 0) {
      let hypertension = allValues.hypertension;
      let fibrillation = allValues.fibrillation;
      let heavy = allValues.heavy;
      let systolic_pressure = allValues.systolic_pressure;
      let elderly = allValues.age;
      let filling_pressure = allValues.filling_pressure;

      sumPoints =
        -9.19174463966566 +
        0.0451129471272832 * elderly +
        0.130730156015681 * heavy +
        0.0858634402456586 * filling_pressure +
        0.051963758732548 * systolic_pressure +
        1.69968057294513 * fibrillation;

      let odds = Math.pow(2.71828182845904, sumPoints);

      let probability = (odds / (1 + odds)) * 100;

      let bo_heavy = heavy > 30 ? 2 : 0;
      let bo_hypertension = hypertension == 1 ? 1 : 0;
      let bo_fibrillation = fibrillation == 1 ? 3 : 0;
      let bo_systolic_pressure = systolic_pressure > 35 ? 1 : 0;
      let bo_elderly = elderly > 60 ? 1 : 0;
      let bo_filling_pressure = filling_pressure > 9 ? 1 : 0;

      let sumICFEP = bo_heavy + bo_hypertension + bo_fibrillation + bo_systolic_pressure + bo_elderly + bo_filling_pressure;
      let percentage_H2FPEF = parseFloat(probability).toFixed(1);

      setPointsCompleted(sumICFEP);
      setPercentage(percentage_H2FPEF);
      setErrorFinded(false);
    } else {
      if (auxErrorsFinded > 0) {
        setErrorFinded(true);
      }
      setPercentage(null);
      setPointsCompleted(null);
    }
  }

  function handleIncrement(key) {
    let value = formCompleted.getFieldValue(key);
    if (typeof value === "string") {
      value = Number(value);
    }

    if (value) {
      if (value >= minValues[key] && value < maxValues[key]) {
        value++;
      } else if (value < minValues[key]) {
        value = minValues[key];
      } else {
        value = maxValues[key];
      }

      formCompleted.setFieldValue([key], Number(value));
      formCompleted.validateFields([key]);
    } else {
      formCompleted.setFieldValue([key], minValues[key]);
      formCompleted.validateFields([key]);
    }
  }

  function handleDecrement(key) {
    let value = formCompleted.getFieldValue(key);
    if (typeof value === "string") {
      value = Number(value);
    }

    if (value) {
      if (value > minValues[key] && value <= maxValues[key]) {
        value--;
      } else if (value <= minValues[key]) {
        value = minValues[key];
      } else {
        value = maxValues[key];
      }

      formCompleted.setFieldValue([key], Number(value));
      formCompleted.validateFields([key]);
    } else {
      formCompleted.setFieldValue([key], minValues[key]);
      formCompleted.validateFields([key]);
    }
  }

  function handleValidate(rule, value) {
    let key = rule.field;
    let keysOfNumber = Object.keys(minValues);
    if (keysOfNumber.includes(key)) {
      if (Number(value) < minValues[rule.field]) {
        return Promise.reject(<Tooltip color="red" placement="bottom" open={true} title={`Este valor não pode ser menor que ${minValues[rule.field]}`}></Tooltip>); // The validator should always return a promise on both success and error
      } else if (Number(value) > maxValues[rule.field]) {
        return Promise.reject(<Tooltip color="red" placement="bottom" open={true} title={`Este valor não pode ser maior que ${maxValues[rule.field]}`}></Tooltip>); // The validator should always return a promise on both success and error
      } else {
        return Promise.resolve(); // The validator should always return a promise on both success and error
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>Calculadora SCORE H2FPEF - PROBABILIDADE DE ICFEP | Formação Científica em Medicina Cardiovascular</title>
        <meta
          name="description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
        <meta
          name="keywords"
          content="calculadoras área cardiovascular, Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:title" content="Calculadora SCORE H2FPEF - PROBABILIDADE DE ICFEP | Formação Científica em Medicina Cardiovascular" />
        <meta
          property="og:description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
      </Helmet>
      <CalculatorBanner title="SCORE H2FPEF - PROBABILIDADE DE ICFEP" />

      <CalculatorContent>
        <Form form={form} onFieldsChange={handleSubmit} className="form_calculator">
          {/* Row #1 */}
          <Row
            gutter={[
              { xs: 20, sm: 40, md: 40, lg: 60 },
              { xs: 40, sm: 40, md: 40, lg: 60 },
            ]}
          >
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold text-center">
                <i>Heavy</i> (IMC, kg/m2)
              </p>
              <div className="dashed_label">
                <p className="m-0">{"IMC> 30 kg/m2"}</p>
              </div>
              <Form.Item name={"heavy"} className="mt-10 w-100 mb-0">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Radio.Button value={2} className="w-50 mr-10">
                    Sim
                  </Radio.Button>
                  <Radio.Button value={0} className="w-50 ml-10">
                    Não
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <p className="f-14 mt-10 text-center">Sim = 2 pontos | Não = 0</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold text-center">Hipertensão</p>
              <div className="dashed_label">
                <p className="m-0">(toma de 2 ou mais anti-hipertensores)</p>
              </div>
              <Form.Item name={"hypertension"} className="mt-10 w-100 mb-0">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Radio.Button value={1} className="w-50 mr-10">
                    Sim
                  </Radio.Button>
                  <Radio.Button value={0} className="w-50 ml-10">
                    Não
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <p className="f-14 mt-10 text-center">Sim = 1 ponto | Não = 0</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold text-center">Fibrilação auricular</p>
              <div className="dashed_label">
                <p className="m-0">(paroxística ou persistente)</p>
              </div>
              <Form.Item name={"fibrillation"} className="mt-10 w-100 mb-0">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Radio.Button value={3} className="w-50 mr-10">
                    Sim
                  </Radio.Button>
                  <Radio.Button value={0} className="w-50 ml-10">
                    Não
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <p className="f-14 mt-10 text-center">Sim = 3 pontos | Não = 0</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold text-center">Hipertensão Pulmonar</p>
              <div className="dashed_label">
                <p className="m-0">{"(Ecocardiograma com PSAP > 35 mmHg)"}</p>
              </div>
              <Form.Item name={"pulmonary"} className="mt-10 w-100 mb-0">
                <Radio.Group className="w-100 d-flex flex-column">
                  <div className="d-flex flex-nowrap w-100">
                    <Radio.Button value={2} className="w-50 mr-10">
                      Sim
                    </Radio.Button>
                    <Radio.Button value={0} className="w-50 ml-10">
                      Não
                    </Radio.Button>
                  </div>
                  <Radio.Button value={"unknown"} className="w-100 mt-10">
                    Desconhecido
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <p className="f-14 mt-10 text-center">Sim = 2 pontos | Não = 0 | Desconhecido = 0</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold text-center">Idoso</p>
              <div className="dashed_label">
                <p className="m-0">{"(>60 anos)"}</p>
              </div>
              <Form.Item name={"age"} className="mt-10 w-100 mb-0">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Radio.Button value={1} className="w-50 mr-10">
                    Sim
                  </Radio.Button>
                  <Radio.Button value={0} className="w-50 ml-10">
                    Não
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <p className="f-14 mt-10 text-center">Sim = 1 ponto | Não = 0</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold text-center">Pressões de enchimento</p>
              <div className="dashed_label">
                <p className="m-0">{`(Ecocardiograma com E/E'>9)`}</p>
              </div>
              <Form.Item name={"pressure"} className="mt-10 w-100 mb-0">
                <Radio.Group className="w-100 d-flex flex-column">
                  <div className="d-flex flex-nowrap w-100">
                    <Radio.Button value={1} className="w-50 mr-10">
                      Sim
                    </Radio.Button>
                    <Radio.Button value={0} className="w-50 ml-10">
                      Não
                    </Radio.Button>
                  </div>
                  <Radio.Button value={"unknown"} className="w-100 mt-10">
                    Desconhecido
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <p className="f-14 mt-10 text-center">Sim = 1 ponto | Não = 0 | Desconhecido = 0</p>
            </Col>
          </Row>
        </Form>
      </CalculatorContent>

      {points ? (
        <CalculatorResult>
          <Row
            gutter={[
              { xs: 20, sm: 40, md: 40, lg: 0 },
              { xs: 40, sm: 40, md: 40, lg: 0 },
            ]}
            className="ml-0 mr-0"
          >
            <Col xs={24} sm={12} md={12} lg={12} xl={14}>
              <p className="mt-10 f-30 bold">{points} pontos</p>
              <p>{message}</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={10} className="p-20" style={{ border: "1px solid #FFF" }}>
              <Row className="w-100">
                <Col span={12}>
                  <p className="mobile-f-12">Interpretação</p>
                </Col>
                <Col span={12}>
                  <p className="mobile-f-12">Probabilidade de ICFEP</p>
                </Col>
              </Row>
              <Row className="w-100  mt-10">
                <Col span={12}>
                  <p className="mobile-f-12">"0-1"</p>
                </Col>
                <Col span={12}>
                  <p className="mobile-f-12">Baixa</p>
                </Col>
              </Row>
              <Row className="w-100">
                <Col span={12}>
                  <p className="mobile-f-12">"2-5"</p>
                </Col>
                <Col span={12}>
                  <p className="mobile-f-12">Intermédia</p>
                </Col>
              </Row>
              <Row className="w-100">
                <Col span={12}>
                  <p className="mobile-f-12">"6-10"</p>
                </Col>
                <Col span={12}>
                  <p className="mobile-f-12">Alta</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </CalculatorResult>
      ) : null}

      {/* CALCULADORA COMPLETA */}
      <CalculatorContent className="mt-10">
        <div className="completed_calc">
          <p className="text-center">CALCULADORA COMPLETA</p>
        </div>
        <Form form={formCompleted} onFieldsChange={handleSubmitFormCompleted} className="form_calculator">
          {/* Row #1 */}
          <Row
            gutter={[
              { xs: 20, sm: 8, md: 16, lg: 60 },
              { xs: 40, sm: 8, md: 16, lg: 60 },
            ]}
          >
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">
                <i>Heavy</i> (IMC, kg/m2)
              </p>
              <Row className="mt-10 d-flex flex-nowrap">
                <Button onClick={() => handleDecrement("heavy")} className="calculator_button mr-10">
                  -
                </Button>
                <Form.Item name={"heavy"} className="w-100 mb-0">
                  <Input type="number" className="w-100" rules={[{ validator: handleValidate }]} />
                </Form.Item>
                <Button onClick={() => handleIncrement("heavy")} className="calculator_button ml-10">
                  +
                </Button>
              </Row>
              <p className="f-14 mt-10">Oscila entre 20kg/m2 e 60kg/m2</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Hipertensão</p>
              <Form.Item name={"hypertension"} className="mt-10 w-100 mb-0">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Radio.Button value={1} className="w-50 mr-10">
                    Sim
                  </Radio.Button>
                  <Radio.Button value={0} className="w-50 ml-10">
                    Não
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <p className="f-14 mt-10">Sim = 1 | Não = 0</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Fibrilação auricular</p>
              <Form.Item name={"fibrillation"} className="mt-10 w-100 mb-0">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Radio.Button value={1} className="w-50 mr-10">
                    Sim
                  </Radio.Button>
                  <Radio.Button value={0} className="w-50 ml-10">
                    Não
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <p className="f-14 mt-10">Sim = 1 | Não = 0</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Pressão sistólica da artéria pulmonar (mmHg)</p>
              <Row className="mt-10 d-flex flex-nowrap">
                <Button onClick={() => handleDecrement("systolic_pressure")} className="calculator_button mr-10">
                  -
                </Button>
                <Form.Item name={"systolic_pressure"} className="w-100 mb-0" rules={[{ validator: handleValidate }]}>
                  <Input type="number" className="w-100" />
                </Form.Item>
                <Button onClick={() => handleIncrement("systolic_pressure")} className="calculator_button ml-10">
                  +
                </Button>
              </Row>
              <p className="f-14 mt-10">Oscila entre 20 e 90 mmHg</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold text-center">"Elderly" (idade)</p>
              <Row className="mt-10 d-flex flex-nowrap mt-30">
                <Button onClick={() => handleDecrement("age")} className="calculator_button mr-10">
                  -
                </Button>
                <Form.Item name={"age"} className="w-100 mb-0" rules={[{ validator: handleValidate }]}>
                  <Input type="number" className="w-100" />
                </Form.Item>
                <Button onClick={() => handleIncrement("age")} className="calculator_button ml-10">
                  +
                </Button>
              </Row>
              <p className="f-14 mt-10">Varia entre 55 anos e 100 anos</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">"Filling pressure" (E/e')</p>
              <Row className="mt-10 d-flex flex-nowrap mt-30">
                <Button onClick={() => handleDecrement("filling_pressure")} className="calculator_button mr-10">
                  -
                </Button>
                <Form.Item name={"filling_pressure"} className="w-100 mb-0" rules={[{ validator: handleValidate }]}>
                  <Input type="number" className="w-100" />
                </Form.Item>
                <Button onClick={() => handleIncrement("filling_pressure")} className="calculator_button ml-10">
                  +
                </Button>
              </Row>
              <p className="f-14 mt-10">Varia entre entre 4 e 30</p>
            </Col>
          </Row>
        </Form>
      </CalculatorContent>

      {pointsCompleted != null ? (
        <CalculatorResult isLoading={isResultLoading}>
          <Row
            gutter={[
              { xs: 20, sm: 8, md: 16, lg: 60 },
              { xs: 40, sm: 8, md: 16, lg: 60 },
            ]}
          >
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <p>PROBABILIDADE DE DIAGNÓSTICO DE INSUFICIÊNCIA CARDÍACA DE FRAÇÃO DE EJEÇÃO PRESERVADA (ICFEP):</p>
              <p className="mt-10 f-30 bold">{percentage} %</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Row
                gutter={[
                  { xs: 20, sm: 8, md: 16, lg: 60 },
                  { xs: 40, sm: 8, md: 16, lg: 60 },
                ]}
              >
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <p>PROBABILIDADE DE ICFEP:</p>
                  <p className="mt-10 f-30 bold">{pointsCompleted}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </CalculatorResult>
      ) : null}
      <Row className={`container_fluid`}>
        <Row className="container">
          <div className="container d-flex flex-column jc-center ai-center p-30" style={{ backgroundColor: "#F5F5F5" }}>
            <p className="blue f-14">Com o apoio independente de:</p>
            <img className="mt-15" src={Boehringer} />
          </div>
        </Row>
      </Row>

      <CalculatorLinks />
    </>
  );
};
export default Calculator02Page;
