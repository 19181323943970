import React, { useState, useEffect } from "react";
import { Button, Checkbox, Col, ColorPicker, Form, InputNumber, Radio, Drawer, Row, Select, message, Space, Switch, Upload, Input, Modal } from "antd";
import "../../../assets/styles/admin.css";
import axios from "axios";
import api from "../../../services/api";
import { Outlet, Link } from "react-router-dom";
import { InboxOutlined, UploadOutlined, DesktopOutlined, MobileOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import { useForm } from "antd/es/form/Form";
import config from "../../../services/config";
import endpoints from "../../../services/endpoints";
import "react-quill/dist/quill.snow.css";
const { Dragger } = Upload;

const { Option } = Select;
const { TextArea } = Input;

function Update({ open, close, data }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  function handleClose() {
    close();
  }

  function handleSubmit() {
    setIsButtonLoading(true);
    axios
      .post(endpoints.user.adminDelete, {
        data: data,
      })
      .then((res) => {
        setTimeout(() => {
          setIsButtonLoading(false);
          handleClose();
        }, 1000);
      })
      .catch((error) => {
        setIsButtonLoading(false);
        console.error(error);
      });
  }

  return (
    <Modal
      className="modal-delete-guide p-20"
      key={`modal-delete-guide-${data.id}`}
      width={800}
      onCancel={handleClose}
      open={open}
      footer={
        <>
          <Button disabled={isButtonLoading} className="mr-10" onClick={handleClose}>
            Cancelar
          </Button>
          <Button loading={isButtonLoading} type="primary" onClick={handleSubmit}>
            Apagar
          </Button>
        </>
      }
    >
      <Row>
        <Col span={24} className="p-20">
          <p className="f-20 bold blue">Deseja mesmo apagar este utilizador:</p>
          <p className="f-16 mt-20">
            <b>Nome</b>: {data.name}
          </p>
          <p className="f-16">
            <b>E-mail</b>: {data.email}
          </p>
        </Col>
      </Row>
    </Modal>
  );
}

export default Update;
