import React, { useState } from "react";
import "../../../assets/styles/calculator.css";
import { Col, Form, Row, Space, Input, Button, Radio, Card, InputNumber, Tooltip, message as antMessage } from "antd";
import CalculatorBanner from "../../../components/website/CalculatorBanner/CalculatorBanner";
import { CalculatorLinks } from "../../../components/website/CalculatorLinks/CalculatorLinks";
import { CalculatorResult } from "../../../components/website/CalculatorResult/CalculatorResult";
import { CalculatorContent } from "../../../components/website/CalculatorContent/CalculatorContent";
import bial from "../../../assets/images/BIAL.png";
import "./Calculators.css";
import { Helmet } from "react-helmet";

/* CALCULADORA SCORE2-Diabetes */
const Calculator01Page = () => {
  const [errorFinded, setErrorFinded] = useState(null);
  const [resultCKD, setResultCKD] = useState(null);
  const [percentage, setPercentage] = useState(null);
  const [message, setMessage] = useState(null);
  const [colorResult, setColorResult] = useState("");
  const [minValues] = useState({
    age: 40,
    tas: 100,
    cholesterol: 58,
    cholesterolHDL: 19,
    diagnostic: 18,
    creatinine: 0.3,
    hba1c: 2,
  });
  const [maxValues] = useState({
    age: 69,
    tas: 200,
    cholesterol: 387,
    cholesterolHDL: 175,
    diagnostic: 69,
    creatinine: 6.0,
    hba1c: 21,
  });

  const [transformedValues] = useState({
    sex: null,
    age: null,
    smoke: null,
    tas: null,
    diabetes: 1,
    cholesterol: null,
    cholesterolHDL: null,
    smoking_age: null,
    diabetes_age: null,
    tas_age: null,
    ct_age: null,
    hdl_age: null,
  });

  const [finalValues] = useState({
    sex: null,
    age: null,
    smoke: null,
    tas: null,
    diabetes: null,
    cholesterol: null,
    cholesterolHDL: null,
    smoking_age: null,
    diabetes_age: null,
    tas_age: null,
    ct_age: null,
    hdl_age: null,
  });

  const [transformedValuesCKDEPI] = useState({
    diagnostic: null,
    hba1c: null,
    egfr: null,
    lnegfr: null,
    lnegfr2: null,
    age_hba1c: 1,
    age_lnegfr: null,
  });

  const [finalValuesCKDEPI] = useState({
    diagnostic: null,
    hba1c: null,
    egfr: null,
    lnegfr: null,
    lnegfr2: null,
    age_hba1c: 1,
    age_lnegfr: null,
  });
  const [isResultLoading, setIsResultLoading] = useState(false);

  const [form] = Form.useForm();
  Form.useWatch("age", form);

  const nextLinks = [
    {
      title: "CALCULADORA SCORE H2FPEF - PROBABILIDADE DE ICFEP",
      text: "Diagnóstico da IC com fração de ejeção preservada",
      url: "/calculadoras/test-a",
    },
    {
      title: "CALCULADORA SCORE H2FPEF - PROBABILIDADE DE ICFEP",
      text: "Diagnóstico da IC com fração de ejeção preservada",
      url: "/calculadoras/test-b",
    },
  ];

  function handleValuesChange() {
    let allValues = form.getFieldsValue();
    let columns = Object.keys(allValues);

    let nullItems = 0;
    let auxErrorsFinded = 0;

    for (let i = 0; i < columns.length; i++) {
      if (typeof allValues[columns[i]] === "string") {
        allValues[columns[i]] = Number(allValues[columns[i]]);
      }

      if (allValues[columns[i]] === null || allValues[columns[i]] === undefined) {
        nullItems++;
      }
    }

    if (nullItems === 0) {
      const validationErrors = Object.values(form.getFieldsError());

      for (let e = 0; e < validationErrors.length; e++) {
        if (validationErrors[e].errors.length > 0) {
          auxErrorsFinded++;
        }
      }
    }

    if (nullItems === 0 && auxErrorsFinded === 0) {
      let auxTransformedValue = transformedValues;
      let auxFinalValues = finalValues;
      let auxTransformedValueCKDEPI = transformedValuesCKDEPI;
      let auxFinalValuesCKDEPI = finalValuesCKDEPI;

      auxTransformedValue.sex = allValues.sex;

      auxTransformedValue.age = (allValues.age - 60) / 5;

      auxTransformedValue.smoke = allValues.smoke;

      auxTransformedValue.tas = (allValues.tas - 120) / 20;
      auxTransformedValue.diabetes = 1; // São todos diabéticos

      auxTransformedValue.cholesterol = allValues.cholesterol * 0.02586;
      auxTransformedValue.cholesterol = (auxTransformedValue.cholesterol - 6) / 1;

      auxTransformedValue.cholesterolHDL = allValues.cholesterolHDL * 0.02586;
      auxTransformedValue.cholesterolHDL = (auxTransformedValue.cholesterolHDL - 1.3) / 0.5;

      auxTransformedValue.smoking_age = auxTransformedValue.age * auxTransformedValue.smoke;

      auxTransformedValue.tas_age = auxTransformedValue.age * auxTransformedValue.tas;
      auxTransformedValue.diabetes_age = auxTransformedValue.age * auxTransformedValue.diabetes;
      auxTransformedValue.ct_age = auxTransformedValue.age * auxTransformedValue.cholesterol;
      auxTransformedValue.hdl_age = auxTransformedValue.age * auxTransformedValue.cholesterolHDL;

      if (auxTransformedValue.sex === 1) {
        // MASCULINO
        auxFinalValues.age = 0.5368 * auxTransformedValue.age;
        auxFinalValues.smoke = 0.4774 * auxTransformedValue.smoke;
        auxFinalValues.tas = 0.1322 * auxTransformedValue.tas;
        auxFinalValues.diabetes = 0.6457 * auxTransformedValue.diabetes;
        auxFinalValues.cholesterol = 0.1102 * auxTransformedValue.cholesterol;
        auxFinalValues.cholesterolHDL = -0.1087 * auxTransformedValue.cholesterolHDL;
        auxFinalValues.smoking_age = -0.0672 * auxTransformedValue.smoking_age;
        auxFinalValues.tas_age = -0.0268 * auxTransformedValue.tas_age;
        auxFinalValues.diabetes_age = -0.0983 * auxTransformedValue.diabetes_age;
        auxFinalValues.ct_age = -0.0181 * auxTransformedValue.ct_age;
        auxFinalValues.hdl_age = 0.0095 * auxTransformedValue.hdl_age;

        // CKDEPI - VALOR TRANSFORMADO
        auxTransformedValueCKDEPI.diagnostic = (allValues.diagnostic - 50) / 5;

        auxTransformedValueCKDEPI.hba1c = 10.93 * allValues.hba1c - 23.5;
        auxTransformedValueCKDEPI.hba1c = (auxTransformedValueCKDEPI.hba1c - 31) / 9.34;

        // CALCULAR EGFR
        let min_value = allValues.creatinine / 0.9 < 1 ? allValues.creatinine / 0.9 : 1;
        let max_value = allValues.creatinine / 0.9 > 1 ? allValues.creatinine / 0.9 : 1;
        auxTransformedValueCKDEPI.egfr = 141 * Math.pow(min_value, -0.411) * Math.pow(max_value, -1.209) * Math.pow(0.993, allValues.age) * (allValues.race === 1 ? 1.159 : 1);
        setResultCKD(parseFloat(auxTransformedValueCKDEPI.egfr).toFixed(0));

        auxTransformedValueCKDEPI.lnegfr = Math.log(auxTransformedValueCKDEPI.egfr);
        auxTransformedValueCKDEPI.lnegfr = (auxTransformedValueCKDEPI.lnegfr - 4.5) / 0.15;

        auxTransformedValueCKDEPI.lnegfr2 = auxTransformedValueCKDEPI.lnegfr * auxTransformedValueCKDEPI.lnegfr;
        auxTransformedValueCKDEPI.age_hba1c = auxTransformedValueCKDEPI.hba1c * auxTransformedValue.age;
        auxTransformedValueCKDEPI.age_lnegfr = auxTransformedValueCKDEPI.lnegfr * auxTransformedValue.age;

        // CKDEPI - VALOR FINAL POR SEXO

        auxFinalValuesCKDEPI.diagnostic = -0.0998 * auxTransformedValueCKDEPI.diagnostic;
        auxFinalValuesCKDEPI.hba1c = 0.0955 * auxTransformedValueCKDEPI.hba1c;
        auxFinalValuesCKDEPI.lnegfr = -0.0591 * auxTransformedValueCKDEPI.lnegfr;
        auxFinalValuesCKDEPI.lnegfr2 = 0.0058 * auxTransformedValueCKDEPI.lnegfr2;
        auxFinalValuesCKDEPI.age_hba1c = -0.0134 * auxTransformedValueCKDEPI.age_hba1c;
        auxFinalValuesCKDEPI.age_lnegfr = 0.0115 * auxTransformedValueCKDEPI.age_lnegfr;

        // SOMA DE TODOS OS VALORES

        let sumOfFinalValues =
          auxFinalValues.age +
          auxFinalValues.smoke +
          auxFinalValues.tas +
          auxFinalValues.diabetes +
          auxFinalValues.cholesterol +
          auxFinalValues.cholesterolHDL +
          auxFinalValues.smoking_age +
          auxFinalValues.tas_age +
          auxFinalValues.diabetes_age +
          auxFinalValues.ct_age +
          auxFinalValues.hdl_age +
          auxFinalValuesCKDEPI.diagnostic +
          auxFinalValuesCKDEPI.hba1c +
          auxFinalValuesCKDEPI.lnegfr +
          auxFinalValuesCKDEPI.lnegfr2 +
          auxFinalValuesCKDEPI.age_hba1c +
          auxFinalValuesCKDEPI.age_lnegfr;

        let tenYearsRisk = 1 - Math.pow(0.9605, Math.exp(sumOfFinalValues));

        let calibrationModeratedRisk = 1 - Math.exp(-Math.exp(-0.1565 + 0.8009 * Math.log(-Math.log(1 - tenYearsRisk))));
        let calibrationModeratedRiskPercentage = calibrationModeratedRisk * 100;
        calibrationModeratedRiskPercentage = calibrationModeratedRiskPercentage.toFixed(1);
        calibrationModeratedRiskPercentage = parseFloat(calibrationModeratedRiskPercentage);

        setPercentage(calibrationModeratedRiskPercentage);

        if (calibrationModeratedRiskPercentage < 5) {
          setMessage(`Risco baixo`);
          setColorResult("green");
        } else if (calibrationModeratedRiskPercentage >= 5 && calibrationModeratedRiskPercentage < 10) {
          setMessage(`Risco moderado`);
          setColorResult("yellow");
        } else if (calibrationModeratedRiskPercentage >= 10 && calibrationModeratedRiskPercentage < 20) {
          setMessage(`Risco alto`);
          setColorResult("orange");
        } else {
          setMessage(`Risco muito alto`);
          setColorResult("red");
        }
      } else {
        // FEMININO
        auxFinalValues.age = 0.6624 * auxTransformedValue.age;
        auxFinalValues.smoke = 0.6139 * auxTransformedValue.smoke;
        auxFinalValues.tas = 0.1421 * auxTransformedValue.tas;
        auxFinalValues.diabetes = 0.8096 * auxTransformedValue.diabetes;
        auxFinalValues.cholesterol = 0.1127 * auxTransformedValue.cholesterol;
        auxFinalValues.cholesterolHDL = -0.1568 * auxTransformedValue.cholesterolHDL;
        auxFinalValues.smoking_age = -0.1122 * auxTransformedValue.smoking_age;
        auxFinalValues.tas_age = -0.0167 * auxTransformedValue.tas_age;
        auxFinalValues.diabetes_age = -0.1272 * auxTransformedValue.diabetes_age;
        auxFinalValues.ct_age = -0.02 * auxTransformedValue.ct_age;
        auxFinalValues.hdl_age = 0.0186 * auxTransformedValue.hdl_age;

        // CKDEPI - VALOR TRANSFORMADO
        auxTransformedValueCKDEPI.diagnostic = (allValues.diagnostic - 50) / 5;

        auxTransformedValueCKDEPI.hba1c = 10.93 * allValues.hba1c - 23.5;
        auxTransformedValueCKDEPI.hba1c = (auxTransformedValueCKDEPI.hba1c - 31) / 9.34;

        // CALCULAR EGFR
        let min_value = allValues.creatinine / 0.7 < 1 ? allValues.creatinine / 0.7 : 1;
        let max_value = allValues.creatinine / 0.7 > 1 ? allValues.creatinine / 0.7 : 1;
        auxTransformedValueCKDEPI.egfr =
          141 * Math.pow(min_value, -0.329) * Math.pow(max_value, -1.209) * Math.pow(0.993, allValues.age) * 1.018 * (allValues.race === 1 ? 1.159 : 1);
        setResultCKD(parseFloat(auxTransformedValueCKDEPI.egfr).toFixed(1));

        auxTransformedValueCKDEPI.lnegfr = Math.log(auxTransformedValueCKDEPI.egfr);
        auxTransformedValueCKDEPI.lnegfr = (auxTransformedValueCKDEPI.lnegfr - 4.5) / 0.15;

        auxTransformedValueCKDEPI.lnegfr2 = auxTransformedValueCKDEPI.lnegfr * auxTransformedValueCKDEPI.lnegfr;
        auxTransformedValueCKDEPI.age_hba1c = auxTransformedValueCKDEPI.hba1c * auxTransformedValue.age;
        auxTransformedValueCKDEPI.age_lnegfr = auxTransformedValueCKDEPI.lnegfr * auxTransformedValue.age;

        // CKDEPI - VALOR FINAL POR SEXO

        auxFinalValuesCKDEPI.diagnostic = -0.118 * auxTransformedValueCKDEPI.diagnostic;
        auxFinalValuesCKDEPI.hba1c = 0.1173 * auxTransformedValueCKDEPI.hba1c;
        auxFinalValuesCKDEPI.lnegfr = -0.064 * auxTransformedValueCKDEPI.lnegfr;
        auxFinalValuesCKDEPI.lnegfr2 = 0.0062 * auxTransformedValueCKDEPI.lnegfr2;
        auxFinalValuesCKDEPI.age_hba1c = -0.0196 * auxTransformedValueCKDEPI.age_hba1c;
        auxFinalValuesCKDEPI.age_lnegfr = 0.0169 * auxTransformedValueCKDEPI.age_lnegfr;

        // SOMA DE TODOS OS VALORES

        let sumOfFinalValues =
          auxFinalValues.age +
          auxFinalValues.smoke +
          auxFinalValues.tas +
          auxFinalValues.diabetes +
          auxFinalValues.cholesterol +
          auxFinalValues.cholesterolHDL +
          auxFinalValues.smoking_age +
          auxFinalValues.tas_age +
          auxFinalValues.diabetes_age +
          auxFinalValues.ct_age +
          auxFinalValues.hdl_age +
          auxFinalValuesCKDEPI.diagnostic +
          auxFinalValuesCKDEPI.hba1c +
          auxFinalValuesCKDEPI.lnegfr +
          auxFinalValuesCKDEPI.lnegfr2 +
          auxFinalValuesCKDEPI.age_hba1c +
          auxFinalValuesCKDEPI.age_lnegfr;

        let tenYearsRisk = 1 - Math.pow(0.9776, Math.exp(sumOfFinalValues));

        let calibrationModeratedRisk = 1 - Math.exp(-Math.exp(-0.3143 + 0.7701 * Math.log(-Math.log(1 - tenYearsRisk))));
        let calibrationModeratedRiskPercentage = calibrationModeratedRisk * 100;
        calibrationModeratedRiskPercentage = calibrationModeratedRiskPercentage.toFixed(0);
        calibrationModeratedRiskPercentage = parseFloat(calibrationModeratedRiskPercentage);
        setPercentage(calibrationModeratedRiskPercentage);

        if (calibrationModeratedRiskPercentage < 5) {
          setMessage(`Risco baixo`);
          setColorResult("green");
        } else if (calibrationModeratedRiskPercentage >= 5 && calibrationModeratedRiskPercentage < 10) {
          setMessage(`Risco moderado`);
          setColorResult("yellow");
        } else if (calibrationModeratedRiskPercentage >= 10 && calibrationModeratedRiskPercentage < 20) {
          setMessage(`Risco alto`);
          setColorResult("orange");
        } else {
          setMessage(`Risco muito alto`);
          setColorResult("red");
        }
      }
      setIsResultLoading(false);
      setErrorFinded(false);
    } else {
      if (auxErrorsFinded > 0) {
        setErrorFinded(true);
      }
      setMessage(null);
      setResultCKD(null);
    }
  }

  function handleIncrement(key) {
    let value = form.getFieldValue(key);
    if (typeof value === "string") {
      value = Number(value);
    }

    if (value) {
      if (value >= minValues[key] && value < maxValues[key]) {
        if (key === "creatinine") {
          value = value + 0.01;
          value = parseFloat(value).toFixed(2);
        } else {
          value++;
        }
      } else if (value < minValues[key]) {
        value = minValues[key];
      } else {
        value = maxValues[key];
      }

      form.setFieldValue([key], Number(value));
      form.validateFields([key]);
    } else {
      form.setFieldValue([key], minValues[key]);
      form.validateFields([key]);
    }
  }

  function handleDecrement(key) {
    let value = form.getFieldValue(key);
    if (typeof value === "string") {
      value = Number(value);
    }

    if (value) {
      if (value > minValues[key] && value <= maxValues[key]) {
        if (key === "creatinine") {
          value = value - 0.01;
          value = parseFloat(value).toFixed(2);
        } else {
          value--;
        }
      } else if (value <= minValues[key]) {
        value = minValues[key];
      } else {
        value = maxValues[key];
      }

      form.setFieldValue([key], Number(value));
      form.validateFields([key]);
    } else {
      form.setFieldValue([key], minValues[key]);
      form.validateFields([key]);
    }
  }

  function handleValidate(rule, value) {
    console.log("VALIDATE");
    let key = rule.field;
    let keysOfNumber = Object.keys(minValues);
    if (keysOfNumber.includes(key)) {
      if (Number(value) < minValues[rule.field]) {
        return Promise.reject(<Tooltip color="red" placement="bottom" open={true} title={`Este valor não pode ser menor que ${minValues[rule.field]}`}></Tooltip>); // The validator should always return a promise on both success and error
      } else if (Number(value) > maxValues[rule.field]) {
        return Promise.reject(<Tooltip color="red" placement="bottom" open={true} title={`Este valor não pode ser maior que ${maxValues[rule.field]}`}></Tooltip>); // The validator should always return a promise on both success and error
      } else {
        if (key === "diagnostic") {
          if (form.getFieldValue("age")) {
            if (Number(value) > form.getFieldValue("age")) {
              return Promise.reject(<Tooltip color="red" placement="bottom" open={true} title={`Este valor não pode ser maior que a sua idade`}></Tooltip>); // The validator should always return a promise on both success and error
            } else {
              return Promise.resolve(); // The validator should always return a promise on both success and error
            }
          } else {
            return Promise.resolve(); // The validator should always return a promise on both success and error
          }
        } else {
          return Promise.resolve(); // The validator should always return a promise on both success and error
        }
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>Calculadora SCORE2 - Diabetes | Formação Científica em Medicina Cardiovascular</title>
        <meta
          name="description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
        <meta
          name="keywords"
          content="calculadoras área cardiovascular, Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:title" content="Calculadora SCORE2 - Diabetes | Formação Científica em Medicina Cardiovascular" />
        <meta
          property="og:description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
      </Helmet>
      <CalculatorBanner title="SCORE2 - Diabetes" />

      <CalculatorContent className="mt-10">
        <Form form={form} onFieldsChange={handleValuesChange} className="form_calculator">
          {/* Row #1 */}
          <Row
            gutter={[
              { xs: 20, sm: 40, md: 40, lg: 60 },
              { xs: 40, sm: 40, md: 40, lg: 60 },
            ]}
          >
            <Col xs={24} sm={12} md={12} lg={12} xl={6}>
              <p className="blue bold">Idade</p>
              <Row className="mt-10 d-flex flex-nowrap">
                <Button onClick={() => handleDecrement("age")} className="calculator_button mr-10">
                  -
                </Button>
                <Form.Item name={"age"} className="mb-0 w-100" rules={[{ validator: handleValidate }]}>
                  <Input type="number" className="w-100" />
                </Form.Item>
                <Button onClick={() => handleIncrement("age")} className="calculator_button ml-10">
                  +
                </Button>
              </Row>
              <p className="f-14 mt-10">Variável entre: 40 e 69 anos</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6}>
              <p className="blue bold">Sexo</p>
              <Form.Item name={"sex"} className="w-100 mt-10">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Radio.Button value={1} className="w-50 mr-10">
                    Homem
                  </Radio.Button>
                  <Radio.Button value={0} className="w-50 ml-10">
                    Mulher
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6}>
              <p className="blue bold">É de raça africana?</p>
              <Form.Item name={"race"} className="w-100 mt-10">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Radio.Button value={1} className="w-50 mr-10">
                    Sim
                  </Radio.Button>
                  <Radio.Button value={0} className="w-50 ml-10">
                    Não
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6}>
              <p className="blue bold">É fumador?</p>
              <Form.Item name={"smoke"} className="w-100 mt-10">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Radio.Button value={1} className="w-50 mr-10">
                    Sim
                  </Radio.Button>
                  <Radio.Button value={0} className="w-50 ml-10">
                    Não
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={6}>
              <p className="blue bold">Colesterol total</p>
              <Row className="mt-10 d-flex flex-nowrap">
                <Button onClick={() => handleDecrement("cholesterol")} className="calculator_button  mr-10">
                  -
                </Button>
                <Form.Item name={"cholesterol"} className="mb-0 w-100" rules={[{ validator: handleValidate }]}>
                  <Input type="number" className="w-100" />
                </Form.Item>
                <Button onClick={() => handleIncrement("cholesterol")} className="calculator_button ml-10">
                  +
                </Button>
              </Row>
              <p className="f-14 mt-10">Variável contínua entre 58 e 387 mg/dL</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6}>
              <p className="blue bold">Colesterol HDL</p>
              <Row className="mt-10 d-flex flex-nowrap">
                <Button onClick={() => handleDecrement("cholesterolHDL")} className="calculator_button  mr-10">
                  -
                </Button>
                <Form.Item name={"cholesterolHDL"} className="mb-0 w-100" rules={[{ validator: handleValidate }]}>
                  <Input type="number" className="w-100" />
                </Form.Item>
                <Button onClick={() => handleIncrement("cholesterolHDL")} className="calculator_button ml-10">
                  +
                </Button>
              </Row>
              <p className="f-14 mt-10">Variável contínua entre 19 e 175 mg/dL</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6}>
              <p className="blue bold">Idade Diagnóstico DM</p>
              <Row className="mt-10 d-flex flex-nowrap">
                <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.age !== currentValues.age}>
                  {({ getFieldValue }) => (
                    <>
                      <Button onClick={() => handleDecrement("diagnostic")} className="calculator_button  mr-10">
                        -
                      </Button>
                      <Form.Item name={"diagnostic"} className="mb-0 w-100" rules={[{ validator: handleValidate }]}>
                        <Input type="number" className="w-100" />
                      </Form.Item>
                      <Button onClick={() => handleIncrement("diagnostic")} className="calculator_button ml-10">
                        +
                      </Button>
                    </>
                  )}
                </Form.Item>
              </Row>
              <p className="f-14 mt-10">{"Variável entre: 18 e 69 anos (idade DM não pode ser > Idade)"}</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6}>
              <p className="blue bold">HbA1c</p>
              <Row className="mt-10 d-flex flex-nowrap">
                <Button onClick={() => handleDecrement("hba1c")} className="calculator_button  mr-10">
                  -
                </Button>
                <Form.Item name={"hba1c"} className="mb-0 w-100" rules={[{ validator: handleValidate }]}>
                  <Input type="number" className="w-100" />
                </Form.Item>
                <Button onClick={() => handleIncrement("hba1c")} className="calculator_button ml-10">
                  +
                </Button>
              </Row>
              <p className="f-14 mt-10">Variável contínua entre 2 e 21</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6}>
              <p className="blue bold">Creatinina (mg/dL)</p>
              <Row className="mt-35 d-flex flex-nowrap">
                <Button onClick={() => handleDecrement("creatinine")} className="calculator_button  mr-10">
                  -
                </Button>
                <Form.Item name={"creatinine"} className="mb-0 w-100" rules={[{ validator: handleValidate }]}>
                  <Input type="number" className="w-100" />
                </Form.Item>
                <Button onClick={() => handleIncrement("creatinine")} className="calculator_button ml-10">
                  +
                </Button>
              </Row>
              <p className="f-14 mt-10">Variável entre: 0.3 e 6.0</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6}>
              <p className="blue bold">Pressão Arterial Sistólica (PAs)</p>
              <Row className="mt-10 d-flex flex-nowrap">
                <Button onClick={() => handleDecrement("tas")} className="calculator_button  mr-10">
                  -
                </Button>
                <Form.Item name={"tas"} className="mb-0 w-100" rules={[{ validator: handleValidate }]}>
                  <Input type="number" className="w-100" />
                </Form.Item>
                <Button onClick={() => handleIncrement("tas")} className="calculator_button ml-10">
                  +
                </Button>
              </Row>
              <p className="f-14 mt-10">Variável entre 100 e 200</p>
            </Col>
          </Row>

          <Row
            gutter={[
              { xs: 20, sm: 8, md: 16, lg: 60 },
              { xs: 40, sm: 8, md: 16, lg: 60 },
            ]}
            className="mt-60"
          >
            <Col span={24}>
              <p className="blue bold">Taxa de Filtração Glomerular (TFG)</p>
              <Card
                className="mt-10"
                style={{
                  border: "2px dashed #C3D0DA",
                  background: "transparent",
                  borderRadius: "0",
                }}
              >
                <span className={`f-20 bold blue ${resultCKD ? "mr-40" : "mr-0"}`}>{resultCKD}</span> Resultado automático (a partir de idade, género, raça e creatinina)
              </Card>
              <p className="f-14 mt-10">Variável entre: 15 e 200</p>
            </Col>
          </Row>
        </Form>
      </CalculatorContent>

      {/* TODO: colocarmos condicao para aparecer o bloco com resultado*/}
      {message && (
        <CalculatorResult isLoading={isResultLoading}>
          <Row gutter={[24, 24]} className="h-100 ai-start jc-start">
            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
              <p>Resultado:</p>
              <div className="d-flex ai-center mt-20">
                <div className={`circle_calc ${colorResult} mr-20`}></div>
                <div className="d-flex flex-column">
                  <p className="f-30 bold text-uppercase">{percentage}%</p>
                  <p className="f-30 bold text-uppercase">{message}</p>
                </div>
              </div>
              <p className="f-12 italic mt-40">% estimada de eventos CV de acordo com o risco global da população portuguesa</p>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} className="d-flex flex-column jc-center ai-center mt-15">
              <Row gutter={[24]}>
                <div className="d-flex ai-center">
                  <div className="circle_calc green"></div>
                  <div className="ml-10">
                    <p className="m-0">{"<5%:"} Risco baixo</p>
                  </div>
                </div>
              </Row>
              <Row gutter={[24]} className="mt-15">
                <div className="d-flex ai-center">
                  <div className="circle_calc yellow"></div>
                  <div className="ml-10">
                    <p className="m-0">{"5 a <10%:"} Risco moderado</p>
                  </div>
                </div>
              </Row>
              <Row gutter={[24]} className="mt-15">
                <div className="d-flex ai-center">
                  <div className="circle_calc orange"></div>
                  <div className="ml-10">
                    <p className="m-0">{"10 < 20%:"} Risco alto</p>
                  </div>
                </div>
              </Row>
              <Row gutter={[24]} className="mt-15">
                <div className="d-flex ai-center">
                  <div className="circle_calc red"></div>
                  <div className="ml-10">
                    <p className="m-0">{">= 20%:"} Risco muito alto</p>
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
        </CalculatorResult>
      )}

      {errorFinded && (
        <CalculatorResult isLoading={isResultLoading}>
          <Row gutter={[24, 24]} className="h-100 ai-center jc-center">
            <Col xs={24} sm={24} md={16} lg={16} xl={16} className="d-flex jc-center ai-center">
              <p className="f-20 text-center">Algum valor não está entre os parâmetros requeridos</p>
            </Col>
          </Row>
        </CalculatorResult>
      )}

      <div className="container d-flex jc-end w-100">
        <div className="d-flex flex-column jc-end ai-end" style={{ maxWidth: 400 }}>
          <p className="blue f-16 text-right">Esta calculadora foi desenvolvida com o patrocínio independente de:</p>
          <img src={bial} className="w-100 maxw-200" />
        </div>
      </div>

      <CalculatorLinks array={nextLinks} />
    </>
  );
};
export default Calculator01Page;
