import React, { useState } from "react";
import "../../../assets/styles/calculator.css";
import { Col, Form, Row, Space, Radio } from "antd";
import CalculatorBanner from "../../../components/website/CalculatorBanner/CalculatorBanner";
import { CalculatorLinks } from "../../../components/website/CalculatorLinks/CalculatorLinks";
import { CalculatorResult } from "../../../components/website/CalculatorResult/CalculatorResult";
import { CalculatorContent } from "../../../components/website/CalculatorContent/CalculatorContent";
import { Helmet } from "react-helmet";
import PracticalGuidesCTA from "../../../components/website/PracticalGuidesCTA/PracticalGuidesCTA";

/* CALCULADORA SCORE CHADSVASC */
const CalculatorCHADSVASC = () => {
  const [risks] = useState([0.2, 0.6, 2.2, 3.2, 4.8, 7.2, 9.7, 10.8, 11.2, 12.2]);
  const [points, setPoints] = useState(0);
  const [message, setMessage] = useState("");
  const [treatment, setTreatment] = useState("");
  const [showResult, setShowResult] = useState(false);

  const [form] = Form.useForm();

  function handleSubmit(changedFields, allFields) {
    let columns = Object.keys(allFields);
    let sumPoints = 0;
    let readyToCalc = true;

    for (let i = 0; i < columns.length; i++) {
      if (allFields[columns[i]] !== null && allFields[columns[i]] !== undefined) {
        sumPoints += allFields[columns[i]];
      } else {
        readyToCalc = false;
        return;
      }
    }

    let auxTreatment = "";

    if (readyToCalc) {
      if (sumPoints === 0) {
        auxTreatment = "Tratamento anti-trombótico não recomendado";
      } else if (sumPoints === 1 && allFields.sex === 1) {
        auxTreatment = "Tratamento anti-trombótico não recomendado";
      } else if (sumPoints > 1 || (sumPoints === 1 && allFields.sex === 0)) {
        auxTreatment = "Hipocoagulação";
      }

      let auxMessage = `Risco de AVC de ${risks[sumPoints]}% por ano`;
      setTreatment(auxTreatment);
      setMessage(auxMessage);
      setPoints(sumPoints);
      setShowResult(true);
    }
  }

  return (
    <>
      <Helmet>
        <title>Calculadora SCORE CHADSVASC | Formação Científica em Medicina Cardiovascular</title>
        <meta
          name="description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
        <meta
          name="keywords"
          content="calculadoras área cardiovascular, Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:title" content="Calculadora SCORE CHADSVASC | Formação Científica em Medicina Cardiovascular" />
        <meta
          property="og:description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
      </Helmet>
      <CalculatorBanner title="SCORE CHADSVASC" />

      <CalculatorContent>
        <Form form={form} onValuesChange={handleSubmit} className="form_calculator">
          {/* Row #1 */}
          <Row
            gutter={[
              { xs: 20, sm: 8, md: 16, lg: 60 },
              { xs: 40, sm: 8, md: 16, lg: 60 },
            ]}
          >
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Idade</p>
              <Form.Item name="age" className="mt-10 w-100 mb-0">
                <Radio.Group className="w-100">
                  <Radio.Button value={0} className="w-100">
                    {"< 65"}
                  </Radio.Button>
                  <Radio.Button value={1} className="w-100 mt-10">
                    65 - 74
                  </Radio.Button>
                  <Radio.Button value={2} className="w-100 mt-10">
                    {"> 75"}
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <p className="f-14 mt-10">{"< 65 (0) | 65 - 74 (1) | > 75 (2)"}</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Sexo</p>
              <Form.Item name="sex" className="mt-10 mb-0">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Radio.Button value={1} className="w-50 mr-10">
                    Feminino
                  </Radio.Button>
                  <Radio.Button value={0} className="w-50 mr-10">
                    Masculino
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <p className="f-14 mt-10">Feminino = 1 ponto | Masculino = 0</p>
              <p className="blue bold mt-30">Hipertensão Arterial</p>
              <Form.Item name="hypertension" className="mt-10 mb-0">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Radio.Button value={1} className="w-50 mr-10">
                    Sim
                  </Radio.Button>
                  <Radio.Button value={0} className="w-50 ml-10">
                    Não
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <p className="f-14 mt-10">Sim = 1 ponto | Não = 0</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Diabetes</p>
              <Form.Item name="diabetes" className="mt-10 mb-0">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Radio.Button value={1} className="w-50 mr-10">
                    Sim
                  </Radio.Button>
                  <Radio.Button value={0} className="w-50 ml-10">
                    Não
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <p className="f-14 mt-10">Sim = 1 ponto | Não = 0</p>
              <p className="blue bold mt-30">AVC/AIT/Tromboembolismo Prévio</p>
              <Form.Item name="avc" className="mt-10 mb-0">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Radio.Button value={2} className="w-50 mr-10">
                    Sim
                  </Radio.Button>
                  <Radio.Button value={0} className="w-50 ml-10">
                    Não
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <p className="f-14 mt-10">Sim = 2 ponto | Não = 0</p>
            </Col>

            {/* Row #2 */}
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Insuficiência Cardíaca</p>
              <Form.Item name="cardiac" className="mt-40 mb-0">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Radio.Button value={1} className="w-50 mr-10">
                    Sim
                  </Radio.Button>
                  <Radio.Button value={0} className="w-50 ml-10">
                    Não
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <p className="f-14 mt-10">Sim = 1 ponto | Não = 0</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Enfarte do miocárdio/Doença arterial periférica/Placa de aterosclerose na aorta</p>
              <Form.Item
                name="heart_attack"
                className="mt-20 mb-0"
                rules={[
                  {
                    required: true,
                    message: "Este campo é obrigatório",
                  },
                ]}
              >
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Radio.Button value={1} className="w-50 mr-10">
                    Sim
                  </Radio.Button>
                  <Radio.Button value={0} className="w-50 ml-10">
                    Não
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <p className="f-14 mt-10">Sim = 1 ponto | Não = 0</p>
            </Col>
          </Row>
        </Form>
      </CalculatorContent>

      {/* TODO: colocarmos condicao para aparecer o bloco com resultado*/}
      {showResult && (
        <CalculatorResult className="CHADSVASC">
          <Row gutter={[20, 20]} className="d-flex jc-center ai-center ml-0 mr-0">
            <Col xs={24} sm={12} md={8} lg={8} xl={8} className="d-flex flex-column jc-center">
              <p className="white">Resultado:</p>
              <p className="f-50 bold white">{points}</p>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8} className="d-flex flex-column jc-center ai-start">
              <p className="white">{message}</p>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} className="d-flex flex-column jc-start ai-start calculator_treatment p-20">
              <p className="bold white">Recomendação de tratamento</p>
              <p className="white">{treatment}</p>
            </Col>
          </Row>
        </CalculatorResult>
      )}

      <CalculatorLinks />
    </>
  );
};
export default CalculatorCHADSVASC;
