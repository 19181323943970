import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal } from "antd";
import axios from "axios";
import endpoints from "../../../services/endpoints";
import dayjs from "dayjs";

function Update({ data, open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      form.setFieldsValue(data);
    }
  }, [open]);

  function handleSubmit(values) {
    setIsButtonLoading(true);
    axios
      .post(endpoints.tags.update, {
        data: values,
      })
      .then((res) => {
        setIsButtonLoading(false);
        form.resetFields();
        close();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function handleClose() {
    form.resetFields();
    close();
  }

  return (
    <Modal
      className="modal-edit-tag p-20"
      key={`modal-edit-tag-${data.id}`}
      title="Editar tag"
      width={800}
      onCancel={handleClose}
      open={open}
      footer={
        <>
          <Button
            disabled={isButtonLoading}
            className="mr-10"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            loading={isButtonLoading}
            type="primary"
            onClick={form.submit}
          >
            Editar
          </Button>
        </>
      }
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input size="large" placeholder="Nome" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default Update;
