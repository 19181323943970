import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Col,
  ColorPicker,
  Form,
  InputNumber,
  Radio,
  Drawer,
  Row,
  Select,
  message,
  Space,
  Switch,
  Upload,
  Input,
  Divider,
  DatePicker,
} from "antd";
import "../../../assets/styles/admin.css";
import axios from "axios";
import api from "../../../services/api";
import { Outlet, Link } from "react-router-dom";
import {
  InboxOutlined,
  UploadOutlined,
  DesktopOutlined,
  MobileOutlined,
  FileOutlined,
} from "@ant-design/icons";
import ReactQuill from "react-quill";
import { useForm } from "antd/es/form/Form";
import config from "../../../services/config";
import endpoints from "../../../services/endpoints";
import "react-quill/dist/quill.snow.css";
const { Dragger } = Upload;

const { Option } = Select;
const { TextArea } = Input;

function Create({ open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileListThumb, setFileListThumb] = useState([]);
  const [filePreview, setFilePreview] = useState("");

  const [form] = useForm();

  function handleClose() {
    setFileList([]);
    form.resetFields();
    close();
  }

  const uploadProps = {
    accept: ".pdf",
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList: fileList,
    defaultFileList: [],
  };

  const uploadPropsThumb = {
    accept: "image",
    onRemove: (file) => {
      const index = fileListThumb.indexOf(file);
      const newFileListThumb = fileListThumb.slice();
      newFileListThumb.splice(index, 1);
      setFileListThumb(newFileListThumb);
      setFilePreview("");
    },
    beforeUpload: (file) => {
      setFileListThumb([file]);
      handlePreview(file);
      return false;
    },
    fileList: fileListThumb,
    defaultFileList: [],
  };

  function handleSubmit(values) {
    setIsButtonLoading(true);
    const formData = new FormData();

    fileList.forEach((file) => {
      formData.append("file", file);
    });

    fileListThumb.forEach((file) => {
      formData.append("file_thumb", file);
    });

    formData.append("data", JSON.stringify(values));

    fetch(`${config.server_ip}${endpoints.newsletters.create}`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((resData) => {
        setTimeout(() => {
          setIsButtonLoading(false);
          handleClose();
          form.resetFields();
          setFileList([]);
        }, 1000);
      })
      .catch((error) => {
        setIsButtonLoading(false);
        console.error(error);
      });
  }

  async function handlePreview(file) {
    let filePreviewAux = await getBase64(file);
    setFilePreview(filePreviewAux);
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  return (
    <Drawer
      className="drawer-newsletter"
      key={`drawer-create-newsletter`}
      title="Criar newsletter"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        <>
          <Button
            disabled={isButtonLoading}
            className="mr-10"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            loading={isButtonLoading}
            type="primary"
            onClick={form.submit}
          >
            Criar
          </Button>
        </>
      }
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="thumbnail"
              label="Thumbnail"
              rules={[
                {
                  required: true,
                  message: "Este campo é obrigatório",
                },
              ]}
            >
              <Dragger
                {...uploadPropsThumb}
                className={`dragger ${filePreview ? "dragger_hidden" : ""}`}
              >
                <p className="ant-upload-drag-icon">
                  <FileOutlined style={{ color: "rgb(103 103 103)" }} />
                </p>
                <p className="ant-upload-text text">
                  <b>Upload do thumbnail</b>
                </p>
                <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                <div
                  className={`preview_file ${filePreview ? "" : "hidden"}`}
                  style={{
                    backgroundImage: `url(${
                      filePreview && filePreview.includes("base64")
                        ? filePreview
                        : `${config.server_ip}/media/${filePreview}`
                    })`,
                  }}
                ></div>
              </Dragger>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="file"
              label="Ficheiro"
              rules={[
                {
                  required: true,
                  message: "Este campo é obrigatório",
                },
              ]}
            >
              <Dragger {...uploadProps} className={`dragger`}>
                <p className="ant-upload-drag-icon">
                  <FileOutlined style={{ color: "rgb(103 103 103)" }} />
                </p>
                <p className="ant-upload-text text">
                  <b>Upload do ficheiro</b>
                </p>
                <p className="ant-upload-hint">Faça Drag and drop aqui</p>
              </Dragger>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Título"
          name="title"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input placeholder="Título" size="large" />
        </Form.Item>
        <Form.Item
          label="Data"
          name="date"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <DatePicker
            size="large"
            placeholder="Data"
            getPopupContainer={(triggerNode) => {
              return triggerNode.parentNode;
            }}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default Create;
