import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Col, Collapse, Row, Typography } from "antd";

import dayjs from "dayjs";
import axios from "axios";
import { Parser, ProcessNodeDefinitions } from "html-to-react";
import endpoints from "../../../services/endpoints";
import config from "../../../services/config";
import "../../../assets/styles/articles.css";

import "./ArticleDetails.css";
import GridArticles from "../../../components/website/GridArticles/GridArticles";
import { Helmet } from "react-helmet";
import BannerPrincipal from "../../../components/website/BannerPrincipal/BannerPrincipal";
import { CalendarOutlined } from "@ant-design/icons";

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;

// Boilerplate stuff
const htmlParser = new Parser(React);
const processNodeDefinitions = new ProcessNodeDefinitions(React);
function isValidNode() {
  return true;
}

// Custom instructions for processing nodes
const processingInstructions = [
  // Create instruction for custom elements
  {
    shouldProcessNode: (node) => {
      // Process the node if it matches a custom element
      return node.name && (node.name === "collapse" || node.name === "panel" || node.name === "img");
    },
    processNode: function (node, children, index) {
      console.log(node);
      if (node.name === "collapse") {
        return <Collapse defaultactivekey={node.attribs.defaultactivekey}>{children}</Collapse>;
      } else if (node.name === "panel") {
        return (
          <Panel header={node.attribs.header} key={node.attribs.key}>
            {children}
          </Panel>
        );
      } else if (node.name === "img") {
        return <img className={node.attribs.classname} src={`${config.server_ip}${node.attribs.src}`} alt={node.attribs.alt} title={node.attribs.title} />;
      }
    },
  },
  // Default processing
  {
    shouldProcessNode: () => true,
    processNode: processNodeDefinitions.processDefaultNode,
  },
];

function ArticleDetails() {
  const { slug } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({ tags: [] });
  const [othersArticles, setOthersArticles] = useState([]);

  useEffect(() => {
    handleGetArticle();
  }, [slug]);

  function handleGetArticle() {
    axios
      .get(endpoints.articles.readBySlug, {
        params: { slug },
      })
      .then((res) => {
        let auxArticle = res.data[0][0];
        let auxTags = res.data[1];
        let auxOtherArticles = res.data[2];
        let newTags = [];
        auxArticle.tags = JSON.parse(auxArticle.tags);
        let tagFiltered = auxTags.filter((item) => auxArticle.tags.includes(item.id));

        if (auxArticle.tags) {
          for (let i = 0; i < tagFiltered.length; i++) {
            newTags.push(tagFiltered[i].name);
          }
        }

        auxArticle.tags = newTags;

        const currentURL = window.location.href;
        const splitURL = currentURL.split("artigos");
        const filteredLinks = auxOtherArticles.filter((item) => !item.slug.includes(splitURL[1]));
        setOthersArticles(filteredLinks);
        handleRenderToHTML(auxArticle);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleRenderToHTML(pageData) {
    let auxData = JSON.parse(pageData.body);
    let html = [];

    let htmlItems = auxData.blocks.map((item) => {
      return (
        <>
          {item.type === "header" && <Title level={item.data.level}>{item.data.text}</Title>}
          {item.type === "paragraph" && <p dangerouslySetInnerHTML={{ __html: item.data.text }}></p>}
          {item.type === "list" && item.data.style === "unordered" && (
            <ul>
              {item.data.items.map((e) => (
                <li dangerouslySetInnerHTML={{ __html: e }}></li>
              ))}
            </ul>
          )}
          {item.type === "list" && item.data.style !== "unordered" && (
            <ol>
              {item.data.items.map((e) => (
                <li dangerouslySetInnerHTML={{ __html: e }}></li>
              ))}
            </ol>
          )}
          {item.type === "image" && <img src={`${config.server_ip}/assets/media/${item.name}`} />}
          {item.type === "raw" && stringToHTML(item.data.html)}
        </>
      );
    });

    html.push(
      <div className="full_container">
        <div className="container">{htmlItems}</div>
      </div>
    );

    pageData.content = html;

    setData(pageData);
    setIsLoading(false);
  }

  const stringToHTML = (html) => {
    console.log(html);
    let htmlWithoutSpaces = html.replace(/\s+/g, " ").trim();
    htmlWithoutSpaces = htmlWithoutSpaces.replace(/>\s+</g, "><");
    let reactComponent = htmlParser.parseWithInstructions(htmlWithoutSpaces, isValidNode, processingInstructions);
    return reactComponent;
  };

  return (
    <div className="single_article">
      <Helmet>
        <title>{`Artigo | ${data?.title}`}</title>
        <meta name="description" content="O Cardio4all tem à sua disposição várias calculadoras úteis para a prática clínica MGF." />
        <meta
          name="keywords"
          content={
            data?.title +
            "Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
          }
        />
        <meta property="og:title" content={"Artigo | " + data?.title} />
        <meta property="og:description" content="O Cardio4all tem à sua disposição várias calculadoras úteis para a prática clínica MGF." />
        <meta property="og:image" content={`${config.server_ip}/assets/media/${data?.image_banner}`} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <BannerPrincipal
        color={"#0E426A"}
        backgroundImage={data.image_banner}
        children={
          <>
            <div className="f-16 normal white mb-20 tags">
              {data?.tags.map((item) => (
                <span className="tag mr-10">#{item}</span>
              ))}
            </div>
            <div className="d-flex ai-center">
              <CalendarOutlined className="mr-10 f-18" style={{ color: "#87A1B5" }} />
              <p className="f-18 m-0" style={{ color: "#87A1B5" }}>
                {dayjs(data.date).format("DD-MM-YYYY")}
              </p>
            </div>
            <h1 className="white f-26 bold mt-20 mb-0">{data.title}</h1>
            {data.references && (
              <p className="white f-16 mt-20" style={{ color: "#87A1B5", fontStyle: "italic" }}>
                {data.references}
              </p>
            )}
          </>
        }
      />
      {data.content}
      <GridArticles data={othersArticles} />
    </div>
  );
}
export default ArticleDetails;
