import React, { useState, useEffect } from "react";
import axios from "axios";
import endpoints from "../../../services/endpoints";
import config from "../../../services/config";
import { Col, Form, Input, Row, Spin, Table, Tabs } from "antd";
import { Helmet } from "react-helmet";

import styled from "styled-components";
import DownloadIcon from "../../../assets/images/download-icon.svg";
import SearchIcon from "../../../assets/images/search-icon.svg";
import { ReactComponent as DownloadImage } from "../../../assets/images/download.svg";

import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import "./Newsletters.css";
import defaultThumb from "../../../assets/images/newsletter-thumb.png";

const Newsletters = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tabPosition, setTabPosition] = useState("left");

  const [form] = Form.useForm();

  useEffect(() => {
    handleGetData();

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Handler to call on window resize
  function handleResize() {
    // Set window width/height to state
    if (window.innerWidth < 768) {
      setTabPosition("top");
    } else {
      setTabPosition("left");
    }
  }

  const handleGetData = () => {
    axios
      .get(endpoints.newsletters.read)
      .then((res) => {
        handlePrepareData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function handlePrepareData(aux) {
    console.log(aux);
    let newData = {};
    for (let i = 0; i < aux.length; i++) {
      if (!newData[`${dayjs(aux[i].date).format("YYYY")}`]) {
        newData[`${dayjs(aux[i].date).format("YYYY")}`] = [];
        newData[`${dayjs(aux[i].date).format("YYYY")}`].push(aux[i]);
      } else {
        newData[`${dayjs(aux[i].date).format("YYYY")}`].push(aux[i]);
      }
    }

    console.log(newData);

    console.log(Object.keys(newData).sort((a, b) => b - a));

    setData(newData);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }

  const handleDownload = (n) => {
    window.open(`${config.server_ip}/assets/media/${n.file}`, "_blank");
  };

  function handleFilter(e) {
    let string = e.title.toLowerCase();
    let auxFilterData = data.filter((item) =>
      item.title.toLowerCase().includes(string.toLowerCase())
    );

    setFilteredData(auxFilterData);
  }

  return (
    <Spin spinning={isLoading}>
      <Helmet>
        <title>
          Newsletters do Cardio4all de a atualização científica e com as últimas
          guidelines de diagnóstico e tratamento da doença cardiovascular
        </title>
        <meta
          name="description"
          content="Newsletters do Cardio4all úteis para a prática clínica e formação médica em Medicina Geral e Familiar e de Medicina Interna."
        />
        <meta
          name="keywords"
          content="Newsletters do Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, calculadoras área cardiovascular, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta
          property="og:title"
          content="Newsletters do Cardio4all de a atualização científica e com as últimas guidelines de diagnóstico e tratamento da doença cardiovascular"
        />
        <meta
          property="og:description"
          content="Newsletters do Cardio4all úteis para a prática clínica e formação médica em Medicina Geral e Familiar e de Medicina Interna."
        />
      </Helmet>
      <div
        style={{
          background: `linear-gradient(0deg, #FFFFFF 50%, #85B8E3 100%)`,
        }}
      >
        <Row className="container">
          <Col span={24} className="pt-80">
            <p className="f-40 blue text-center pb-60">
              Consulte aqui todas as
              <br />
              <b>newsletters Cardio4all</b>
            </p>
            <Tabs
              className="tabs_videos"
              tabPosition={tabPosition}
              items={Object.keys(data)
                .sort((a, b) => b - a)
                .map((item) => ({
                  label: item,
                  key: `${item}`,
                  children: (
                    <Row
                      gutter={[
                        { xs: 40, sm: 8, md: 16, lg: 24 },
                        { xs: 40, sm: 8, md: 16, lg: 24 },
                      ]}
                      className="mr-0 ml-0 d-flex"
                    >
                      {data[item]?.map((n) => (
                        <Col
                          xs={24}
                          sm={12}
                          md={8}
                          lg={6}
                          xl={6}
                          className="d-flex flex-column mb-20"
                          onClick={() => handleDownload(n)}
                        >
                          <div className="newsletter_card">
                            <div
                              className="newsletter_card_image"
                              style={{
                                backgroundImage: n.thumbnail
                                  ? `url(${config.server_ip}/assets/media/${n.thumbnail})`
                                  : `url(${defaultThumb})`,
                              }}
                            ></div>
                            <div className="newsletter_card_text">
                              <p className="blue bold f-18 mb-10 mt-0 text-uppercase text-center">
                                {dayjs(n?.date).format("MMM YYYY")}
                              </p>
                              <div className="d-flex flex-column jc-center ai-center newsletter_link">
                                <p className="bold">descarregar</p>
                                <DownloadImage />
                              </div>
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  ),
                }))}
            />
          </Col>
        </Row>
      </div>
    </Spin>
  );
};
export default Newsletters;
