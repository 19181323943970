import React, { useState, useEffect } from "react";
import axios from "axios";
import endpoints from "../../../services/endpoints";
import BannerGuiasPraticos from "../../../components/website/BannerGuiasPraticos/BannerGuiasPraticos";
import ListGuiasPraticos from "../../../components/website/ListGuiasPraticos/ListGuiasPraticos";
import { Button, Col, Row, Spin } from "antd";
import { Helmet } from "react-helmet";
import BgGuiasPraticos from "../../../assets/images/guias-praticosk-bg.png";
import { Link } from "react-router-dom";
import CalculatorCTA from "../../../components/website/CalculatorCTA/CalculatorCTA";
import BannerPrincipal from "../../../components/website/BannerPrincipal/BannerPrincipal";

const PracticalGuides = () => {
  const [guides, setGuides] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    handleGetGuides();
  }, []);

  function handleGetGuides() {
    axios
      .get(endpoints.guides.read)
      .then((res) => {
        const sorted = res.data.sort((a, b) => {
          return a.position - b.position;
        });
        setGuides(sorted);
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Spin spinning={isLoading}>
      <Helmet>
        <title>Guias Práticos do Cardio4all de a atualização científica e com as últimas guidelines de diagnóstico e tratamento da doença cardiovascular</title>
        <meta name="description" content="Guias Práticos do Cardio4all úteis para a prática clínica e formação médica em Medicina Geral e Familiar e de Medicina Interna." />
        <meta
          name="keywords"
          content="Guias Práticos do Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, calculadoras área cardiovascular, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta
          property="og:title"
          content="Guias Práticos do Cardio4all de a atualização científica e com as últimas guidelines de diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:description" content="Guias Práticos do Cardio4all úteis para a prática clínica e formação médica em Medicina Geral e Familiar e de Medicina Interna." />
        <meta property="og:image" content={`${BgGuiasPraticos}`} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Row>
        <Col span={24}>
          <BannerPrincipal
            color={"rgb(240, 76, 75)"}
            backgroundImage={guides[0]?.image}
            children={
              <>
                <p className="f-20" style={{ color: "#F8A6A5" }}>
                  Guia Prático
                </p>
                <p className="white f-26 bold mt-10 ellipsisThree">{guides[0]?.title}</p>
                <Link to={`${guides[0]?.slug}`}>
                  <Button type="primary" size="large" className="minw-200 mt-20">
                    Ler guia completo
                  </Button>
                </Link>
              </>
            }
          />
          <ListGuiasPraticos data={guides.slice(1, guides?.length)} />
          <CalculatorCTA />
        </Col>
      </Row>
    </Spin>
  );
};

export default PracticalGuides;
