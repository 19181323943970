import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

function GoogleReCAPTCHA({ onVerify }) {
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const handleChange = value => {
    setRecaptchaValue(value);
    
    // Se onVerify for uma função, chame-a com o valor atual.
    if (typeof onVerify === 'function') {
      onVerify(value);
    }

    console.log(recaptchaValue)
  };

  return (
    <></>
    /*
    <ReCAPTCHA
      sitekey="6Lfh-acoAAAAAKgbuz2iwADyyx9o8_Nxln0jYCBB"
      onChange={handleChange}
      badge="bottomright"
    />
    */
  );
}

export default GoogleReCAPTCHA;

/*
* Adicionar codigo abaixo na pagina que queremos e adicionar valores aos elementos necessarios

const [isVerified, setIsVerified] = useState(false);
const handleVerify = value => {
  setIsVerified(!!value); // Converte o valor para boolean. Se houver um valor, ele se tornará true, caso contrário, false.
};

<GoogleReCAPTCHA onVerify={handleVerify} />

<button type="submit" disabled={!isVerified}>Enviar</button>

*/