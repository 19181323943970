import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";

import BannerVideocast from "../../../components/website/BannerVideocast/BannerVideocast";
import endpoints from "../../../services/endpoints";
import { Button, Col, Row, Spin } from "antd";
import ListVideocasts from "../../../components/website/ListVideocasts/ListVideocasts";

import BannerVideoImage from "../../../assets/images/Videocasts-Bial-banner.png";
import BannerPrincipal from "../../../components/website/BannerPrincipal/BannerPrincipal";
import ModalVideo from "../../../components/website/ModalVideo/ModalVideo";
import ReactPlayer from "react-player";
import config from "../../../services/config";
import dayjs from "dayjs";
import { useLocation, useSearchParams, Link } from "react-router-dom";
import CalculatorDiabetesCTA from "../../../components/website/CalculatorDiabetesCTA/CalculatorDiabetesCTA";

export default function ScientificVideocast() {
  const [videos, setVideos] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedVideo, setSelectedVideo] = useState({});
  const [isOpenModalVideo, setIsOpenModalVideo] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    handleGetData();
  }, []);

  const handleGetData = () => {
    axios
      .get(endpoints.videos.read)
      .then((res) => {
        let auxAllVideos = res.data[0];
        let scientificVideos = auxAllVideos.filter((item) => item.type === "videocasts_scientific_update");
        scientificVideos.sort(function (a, b) {
          return new Date(a.date) - new Date(b.date);
        });
        let newObj = groupBy(scientificVideos, "scientific_type");

        setVideos(newObj);

        let videoId = searchParams.get("id");
        console.log(videoId);
        let findVideo = auxAllVideos.filter((item) => item.id === Number(videoId));

        if (findVideo.length > 0) {
          handleOpenVideo(findVideo[0]);
        }

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function groupBy(arr, property) {
    return arr.reduce(function (memo, x) {
      if (!memo[x[property]]) {
        memo[x[property]] = [];
      }
      memo[x[property]].push(x);
      return memo;
    }, {});
  }

  function handleOpenVideo(item) {
    let canClick = handleCalcDate(item);
    if (canClick) {
      setSelectedVideo(item);
      setIsOpenModalVideo(true);
    }
  }

  function handleCloseVideo() {
    setSelectedVideo({});
    setIsOpenModalVideo(false);
  }

  function handleCalcDate(item) {
    const date1 = dayjs();
    const date2 = dayjs(item.date);
    let dayDiff = date1.diff(date2, "seconds");
    return dayDiff >= 0;
  }

  return (
    <Spin spinning={isLoading}>
      <Helmet>
        <title>Videocasts | Cardio4All</title>
        <meta name="description" content="Vídeos do Cardio4all úteis para a prática clínica e formação médica em Medicina Geral e Familiar e de Medicina Interna." />
        <meta
          name="keywords"
          content="Vídeos do Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, calculadoras área cardiovascular, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:title" content="Vídeos do Cardio4all de a atualização científica e com as últimas guidelines de diagnóstico e tratamento da doença cardiovascular" />
        <meta property="og:description" content="Vídeos do Cardio4all úteis para a prática clínica e formação médica em Medicina Geral e Familiar e de Medicina Interna." />
        <meta property="og:image" content={`${BannerVideoImage}`} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <ModalVideo data={selectedVideo} open={isOpenModalVideo} close={handleCloseVideo} />
      <BannerPrincipal
        color={"#F04C4B"}
        backgroundImage={BannerVideoImage}
        class={"banner-scientific-videocast"}
        children={
          <>
            <h1 className="white">Videocasts de atualização científica</h1>
          </>
        }
      />
      {Object.keys(videos).map((key, keyIndex) => (
        <Row style={{ background: keyIndex % 2 === 0 ? "#FFF" : "#E1EDF8" }}>
          <Col span={24} className="container pt-40 pb-40">
            <p className="blue f-24 bold" dangerouslySetInnerHTML={{ __html: `5 mensagens para ${key}` }}></p>
            {keyIndex === 0 && <p className="f-12 blue">(5 mensagems práticas, em 5 minutos)</p>}
            <Row gutter={[24]}>
              {videos[key].map((item, index) => (
                <Col xs={24} sm={12} md={12} lg={12} xl={12} key={item.id + "_scientific"} className="mt-40">
                  {item.image ? (
                    <div onClick={() => handleOpenVideo(item)} className={`${handleCalcDate(item) && "hover-cursor"} d-flex jc-center ai-center position-relative`}>
                      {!handleCalcDate(item) && (
                        <p className="white f-24 bold" style={{ position: "absolute", zIndex: 2 }}>
                          Brevemente
                        </p>
                      )}
                      <img
                        className="w-100"
                        src={`${config.server_ip}/assets/media/${item.image}`}
                        style={{
                          filter: handleCalcDate(item) ? "none" : "brightness(40%)",
                        }}
                      />
                    </div>
                  ) : (
                    <div className="player-wrapper">
                      <ReactPlayer className="react-player" controls volume={1} url={item?.link} />
                    </div>
                  )}
                  <p className="f-12 bold blue mt-15">Videocast #{index + 1}</p>
                  <p className="blue">{item.title}</p>

                  {item?.button_text && item?.button_link && (
                    <Row className="d-flex jc-center ai-center mt-10">
                      <Col span={24}>
                        <Link to={item.button_link} target="_blank">
                          <Button type="primary" size="large">
                            {item.button_text}
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  )}
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      ))}

      <CalculatorDiabetesCTA />
    </Spin>
  );
}
