import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import config from "../../../services/config";

import GridArticles from "../../../components/website/GridArticles/GridArticles";
import ListArticles from "../../../components/website/ListArticles/ListArticles";
import endpoints from "../../../services/endpoints";
import { Button, Col, Row, Spin } from "antd";
import BannerPrincipal from "../../../components/website/BannerPrincipal/BannerPrincipal";
import { Link } from "react-router-dom";
import { CalendarOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

function ArticlesPage() {
  const [articles, setArticles] = useState([]);
  const [medicalAreas, setMedicalAreas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    handleGetArticles();
  }, []);

  function handleGetArticles() {
    axios
      .get(endpoints.articles.read)
      .then((res) => {
        console.log(res);
        setArticles(res.data[0]);
        setMedicalAreas(res.data[2]);
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Spin spinning={isLoading}>
      <Helmet>
        <title>
          New Science 4all | O blog do Cardio4all com a atualidade útil para a
          prática clínica
        </title>
        <meta
          name="description"
          content="O Cardio4all tem à sua disposição várias calculadoras úteis para a prática clínica MGF."
        />
        <meta
          name="keywords"
          content="New Science 4all, Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta
          property="og:title"
          content="New Science 4all | O blog do Cardio4all com a atualidade útil para a prática clínica"
        />
        <meta
          property="og:description"
          content="O Cardio4all tem à sua disposição várias calculadoras úteis para a prática clínica MGF."
        />
        <meta
          property="og:image"
          content={`${config.server_ip}/assets/media/${articles[0]?.image_banner}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Row>
        <Col span={24}>
          <BannerPrincipal
            color={"#85B8E3"}
            backgroundImage={articles[0]?.image_banner}
            children={
              <>
                <div className="d-flex ai-center">
                  <CalendarOutlined
                    className="mr-10 f-20"
                    style={{ color: "#E1EDF8" }}
                  />
                  <p className="f-20" style={{ color: "#E1EDF8" }}>
                    {dayjs(articles[0]?.date).format("DD-MM-YYYY")}
                  </p>
                </div>
                <p className="white f-26 bold mt-20 ellipsisThree">
                  {articles[0]?.title}
                </p>
                <p className="white f-16 mt-20 ellipsisTwo">
                  {articles[0]?.description}
                </p>
                <Link to={`/artigos/${articles[0]?.slug}`}>
                  <Button size="large" className="minw-200 mt-30 btn-red">
                    Ler artigo completo
                  </Button>
                </Link>
              </>
            }
          />
          <GridArticles data={articles.slice(1, 4)} />
          <ListArticles data={articles} medicalAreasData={medicalAreas} />
        </Col>
      </Row>
    </Spin>
  );
}

export default ArticlesPage;
