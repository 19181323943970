import React, { useEffect, useState } from "react";
import {
  InboxOutlined,
  UploadOutlined,
  DesktopOutlined,
  MobileOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  ColorPicker,
  Form,
  InputNumber,
  Radio,
  Rate,
  Row,
  Select,
  Slider,
  Space,
  Switch,
  Upload,
  Input,
  Drawer,
  message,
  DatePicker,
} from "antd";
import { Outlet, Link } from "react-router-dom";
import axios from "axios";

import "../../../assets/styles/admin.css";
import api from "../../../services/api";
import endpoints from "../../../services/endpoints";
import CreateTag from "../tag/Create";
import dayjs from "dayjs";

function Update({ open, close, data, id_course }) {
  const [previewLink, setPreviewLink] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (open && Object.keys(data).length > 0) {
      data.date = dayjs(data.date);
      setPreviewLink(data.link);
      form.setFieldsValue(data);
    }
  }, [open]);

  function handleSubmit(values) {
    setIsButtonLoading(true);
    values.date = dayjs(values.date).format("YYYY-MM-DD");
    axios
      .post(endpoints.courseItem.update, {
        data: values,
      })
      .then((res) => {
        setIsButtonLoading(false);
        setPreviewLink("");
        form.resetFields();
        close();
      })
      .catch((e) => {
        setIsButtonLoading(false);
        console.log(e);
      });
  }

  function handleClose() {
    form.resetFields();
    close();
  }

  function handlePreviewLink(e) {
    setPreviewLink(e.target.value);
  }

  return (
    <Drawer
      className="drawer-video"
      key={`drawer-create-video`}
      title="Editar item curso"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={[
        <Button
          size="large"
          disabled={isButtonLoading}
          className="mr-10"
          onClick={handleClose}
        >
          Cancelar
        </Button>,
        <Button
          size="large"
          loading={isButtonLoading}
          type="primary"
          onClick={form.submit}
        >
          Editar
        </Button>,
      ]}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item
          label="Título"
          name="title"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input size="large" placeholder="Título" />
        </Form.Item>
        <Form.Item label="Descrição" name="description">
          <Input size="large" placeholder="Descrição" />
        </Form.Item>
        <Form.Item
          label="Data"
          name="date"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <DatePicker
            size="large"
            placeholder="Data"
            getPopupContainer={(triggerNode) => {
              return triggerNode.parentNode;
            }}
          />
        </Form.Item>
        <Form.Item
          label="Link"
          name="link"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input size="large" placeholder="Link" onChange={handlePreviewLink} />
        </Form.Item>

        {previewLink != "" && (
          <iframe
            width="100%"
            height="300px"
            frameBorder="0"
            src={`${previewLink}`}
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          />
        )}
      </Form>
    </Drawer>
  );
}

export default Update;
