import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Col,
  ColorPicker,
  Form,
  InputNumber,
  Radio,
  Drawer,
  Row,
  Select,
  message,
  Space,
  Switch,
  Upload,
  Input,
  Modal,
} from "antd";
import "../../../assets/styles/admin.css";
import axios from "axios";
import api from "../../../services/api";
import { Outlet, Link } from "react-router-dom";
import {
  InboxOutlined,
  UploadOutlined,
  DesktopOutlined,
  MobileOutlined,
} from "@ant-design/icons";
import ReactQuill from "react-quill";
import { useForm } from "antd/es/form/Form";
import config from "../../../services/config";
import endpoints from "../../../services/endpoints";
import "react-quill/dist/quill.snow.css";
const { Dragger } = Upload;

const { Option } = Select;
const { TextArea } = Input;

function Delete({ open, close, data }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  function handleClose() {
    close();
  }

  function handleSubmit() {
    setIsButtonLoading(true);
    axios
      .post(endpoints.calculators.delete, {
        data: data?.id,
      })
      .then((res) => {
        setTimeout(() => {
          setIsButtonLoading(false);
          close();
        }, 1000);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <Modal
      className="delete-calculator p-20"
      key={`delete-calculator-${data?.id}`}
      width={800}
      onCancel={handleClose}
      open={open}
      footer={[
        <Button
          disabled={isButtonLoading}
          className="mr-10"
          onClick={handleClose}
        >
          Cancelar
        </Button>,
        <Button loading={isButtonLoading} type="primary" onClick={handleSubmit}>
          Apagar
        </Button>,
      ]}
    >
      <Row>
        <Col span={24}>
          <p>Deseja mesmo apagar a calculadora:</p>
          <p className="f-26 mt-10">{data?.name}</p>
        </Col>
      </Row>
    </Modal>
  );
}

export default Delete;
