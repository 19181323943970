import React, { useState, useEffect } from "react";
import "../../../assets/styles/calculator.css";
import { Col, Form, Row, Space, Radio, Checkbox, Button, Input } from "antd";
import CalculatorBanner from "../../../components/website/CalculatorBanner/CalculatorBanner";
import { CalculatorLinks } from "../../../components/website/CalculatorLinks/CalculatorLinks";
import { CalculatorResult } from "../../../components/website/CalculatorResult/CalculatorResult";
import { CalculatorContent } from "../../../components/website/CalculatorContent/CalculatorContent";
import "./Calculators.css";
import { Helmet } from "react-helmet";

/* CALCULADORA AVALIAÇÃO DO RISCO CV GLOBAL */
/* ESTA CALCULADORA JÁ NÃO SE USA */
/* ESTA CALCULADORA JÁ NÃO SE USA */
/* ESTA CALCULADORA JÁ NÃO SE USA */
/* ESTA CALCULADORA JÁ NÃO SE USA */
/* ESTA CALCULADORA JÁ NÃO SE USA */
/* ESTA CALCULADORA JÁ NÃO SE USA */
/* ESTA CALCULADORA JÁ NÃO SE USA */
const CalculatorCVGlobal = () => {
  const [riskCV] = useState(["BAIXO RISCO", "RISCO MODERADO", "ALTO RISCO", "MUITO ALTO RISCO"]);
  const [minValues] = useState({ age: 40, total_colesterol: 116, pressure: 100 });
  const [maxValues] = useState({ age: 69, total_colesterol: 348, pressure: 200 });
  const [percentage, setPercentage] = useState(null);
  const [isResultLoading, setIsResultLoading] = useState(null);
  const [resultMessage, setResultMessage] = useState("");
  const [firstCalc] = useState(["cardiac", "atherosclerosis", "diabetes", "renal", "colesterol", "ldl", "pa"]);
  const [finalValues] = useState({
    cardiac: null,
    atherosclerosis: null,
    diabetes: null,
    diabetes_risk3: null,
    diabetes_lesion: null,
    diabetes_long_duration: null,
    diabetes_risk1: null,
    diabetes_other: null,
    renal: null,
    renal_severity: null,
    colesterol: null,
    ldl: null,
    pa: null,
    sex: null,
    age: null,
    total_colesterol: null,
    smoker: null,
    pressure: null,
    chdData: [
      //main
      {
        alpha: -22.1,
        rho: 4.71,
      },
      // woman
      {
        alpha: -29.8,
        rho: 6.36,
      },
    ],
    nonChdData: [
      // man
      {
        alpha: -26.7,
        rho: 5.64,
      },
      // woman
      {
        alpha: -31,
        rho: 6.62,
      },
    ],
    chd: {
      // step 1
      first: null,
      second: null,
      base: null,
      survival_now: null,
      survival_10y: null,

      // step 2
      smoker: 0.71,
      colesterol: 0.24,
      pressure: 0.018,
      colesterol_between: null,
      colesterol_fraction: null,
      pressure_between: null,
      pressure_fraction: null,
      smoker_fraction: null,
      convertor_colesterol: null,
      weighted_sum: null,

      //step3
      combined_survival_now: null,
      combined_survival_10y: null,

      //step4
      survival_10y_probability: null,

      //step5
      risk_10y: null,
    },
    nonChd: {
      // step 1
      first: null,
      second: null,
      base: null,
      survival_now: null,
      survival_10y: null,

      // step 2
      smoker: 0.63,
      colesterol: 0.02,
      pressure: 0.022,
      colesterol_between: null,
      colesterol_fraction: null,
      pressure_between: null,
      pressure_fraction: null,
      smoker_fraction: null,
      convertor_colesterol: null,
      weighted_sum: null,

      //step3
      combined_survival_now: null,
      combined_survival_10y: null,

      //step4
      survival_10y_probability: null,

      //step5
      risk_10y: null,
    },
  });
  let sumPoints = 0;
  let nullItems = 0;

  const [showInput, setShowInput] = useState({
    diabetes: 0,
    renal: 0,
  });

  const [form] = Form.useForm();

  function handleDebounce(func, timeout = 1200) {
    let timer;
    return (...args) => {
      setIsResultLoading(true);
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  const handleValuesChange = (changedValues, allValues) => {
    let columns = Object.keys(allValues);
    let nullItems = 0;
    console.log(changedValues);

    let keyChangedValue = Object.keys(changedValues)[0];

    if (maxValues[keyChangedValue] || minValues[keyChangedValue]) {
      if (Number(changedValues[keyChangedValue]) > maxValues[keyChangedValue]) {
        allValues[keyChangedValue] = maxValues[keyChangedValue];
        form.setFieldValue(keyChangedValue, maxValues[keyChangedValue]);
      } else if (Number(changedValues[keyChangedValue]) < minValues[keyChangedValue]) {
        allValues[keyChangedValue] = minValues[keyChangedValue];
        form.setFieldValue(keyChangedValue, minValues[keyChangedValue]);
      }
    }

    for (let i = 0; i < columns.length; i++) {
      if (allValues[columns[i]]) {
        if (typeof allValues[columns[i]] === "string" && (columns[i] === "age" || columns[i] === "total_colesterol" || columns[i] === "pressure")) {
          allValues[columns[i]] = Number(allValues[columns[i]]);
        }

        if (maxValues[columns[i]] || minValues[columns[i]]) {
          if (allValues[columns[i]] > maxValues[columns[i]]) {
            allValues[columns[i]] = maxValues[columns[i]];
            form.setFieldValue(columns[i], maxValues[columns[i]]);
          } else if (allValues[columns[i]] < minValues[columns[i]]) {
            allValues[columns[i]] = minValues[columns[i]];
            form.setFieldValue(columns[i], minValues[columns[i]]);
          }
        }
      }
    }

    if (allValues.diabetes_checkbox) {
      if (allValues.diabetes_checkbox.includes("diabetes_risk1")) {
        allValues.diabetes = 2;
      } else if (allValues.diabetes_checkbox.some((value) => ["diabetes_risk3", "diabetes_lesion", "diabetes_long_duration"].includes(value))) {
        allValues.diabetes = 3;
      } else {
        allValues.diabetes = 1;
      }
    }

    let lastCalc = false;

    for (let i = 0; i < firstCalc.length; i++) {
      console.log(allValues[firstCalc[i]]);
      if (allValues[firstCalc[i]] === 0) {
        lastCalc = true;
      } else {
        lastCalc = false;
        form.setFieldValue("sex", null);
        form.setFieldValue("smoker", null);
        form.setFieldValue("age", null);
        form.setFieldValue("total_colesterol", null);
        form.setFieldValue("pressure", null);
        break;
      }
    }

    console.log(allValues);
    if (
      lastCalc &&
      (allValues.sex === 0 || allValues.sex === 1) &&
      (allValues.smoker === 0 || allValues.smoker === 1) &&
      allValues.age &&
      allValues.total_colesterol &&
      allValues.pressure
    ) {
      setResultMessage(null);
      setPercentage(null);
      calculateRisk(allValues);
    } else {
      setResultMessage(null);
      setPercentage(null);
      calculateMessage(allValues);
    }
  };

  const calculateRisk = (calculatorObject) => {
    let nullFields = 0;
    for (let prop in calculatorObject) {
      if (prop === "smoker" || prop === "age" || prop === "diabetes" || prop === "total_colesterol" || prop === "pressure") {
        if (calculatorObject[prop] === null) {
          nullFields++;
        }
      }
    }

    if (nullFields === 0) {
      let totalRisk = null;
      let index = calculatorObject.sex;
      let auxFinalValues = finalValues;
      // step 1 CHD
      auxFinalValues.chd.first = -Math.exp(auxFinalValues.chdData[index].alpha);
      auxFinalValues.chd.second = round(Math.pow(calculatorObject.age - 20, auxFinalValues.chdData[index].rho));
      auxFinalValues.chd.base = auxFinalValues.chd.first * auxFinalValues.chd.second;
      auxFinalValues.chd.survival_now = Math.exp(auxFinalValues.chd.base);
      auxFinalValues.chd.second = round(Math.pow(calculatorObject.age - 10, auxFinalValues.chdData[index].rho));
      auxFinalValues.chd.base = auxFinalValues.chd.first * auxFinalValues.chd.second;
      auxFinalValues.chd.survival_10y = Math.exp(auxFinalValues.chd.base);

      // step 1 NON-CHD
      auxFinalValues.nonChd.first = -Math.exp(auxFinalValues.nonChdData[index].alpha);
      auxFinalValues.nonChd.second = round(Math.pow(calculatorObject.age - 20, auxFinalValues.nonChdData[index].rho));
      auxFinalValues.nonChd.base = auxFinalValues.nonChd.first * auxFinalValues.nonChd.second;
      auxFinalValues.nonChd.survival_now = Math.exp(auxFinalValues.nonChd.base);
      auxFinalValues.nonChd.second = round(Math.pow(calculatorObject.age - 10, auxFinalValues.nonChdData[index].rho));
      auxFinalValues.nonChd.base = auxFinalValues.nonChd.first * auxFinalValues.nonChd.second;
      auxFinalValues.nonChd.survival_10y = Math.exp(auxFinalValues.nonChd.base);

      // step 2 CHD
      auxFinalValues.chd.colesterol_between = calculatorObject.total_colesterol / 38.67 - 6;
      auxFinalValues.chd.colesterol_fraction = auxFinalValues.chd.colesterol_between * auxFinalValues.chd.colesterol;
      auxFinalValues.chd.pressure_between = calculatorObject.pressure - 120;
      auxFinalValues.chd.pressure_fraction = auxFinalValues.chd.pressure_between * auxFinalValues.chd.pressure;

      auxFinalValues.chd.smoker_fraction = 0;
      if (calculatorObject.smoker) {
        auxFinalValues.chd.smoker_fraction = auxFinalValues.chd.smoker;
      }

      auxFinalValues.chd.weighted_sum = auxFinalValues.chd.colesterol_fraction + auxFinalValues.chd.pressure_fraction + auxFinalValues.chd.smoker_fraction;

      // step 2 NON-CHD
      auxFinalValues.nonChd.colesterol_between = calculatorObject.total_colesterol / 38.67 - 6;
      auxFinalValues.nonChd.colesterol_fraction = auxFinalValues.nonChd.colesterol_between * auxFinalValues.nonChd.colesterol;
      auxFinalValues.nonChd.pressure_between = calculatorObject.pressure - 120;
      auxFinalValues.nonChd.pressure_fraction = auxFinalValues.nonChd.pressure_between * auxFinalValues.nonChd.pressure;

      auxFinalValues.nonChd.smoker_fraction = 0;
      if (calculatorObject.smoker) {
        auxFinalValues.nonChd.smoker_fraction = auxFinalValues.nonChd.smoker;
      }

      auxFinalValues.nonChd.weighted_sum = auxFinalValues.nonChd.colesterol_fraction + auxFinalValues.nonChd.pressure_fraction + auxFinalValues.nonChd.smoker_fraction;

      // step 3 CHD
      auxFinalValues.chd.combined_survival_now = Math.pow(auxFinalValues.chd.survival_now, Math.exp(auxFinalValues.chd.weighted_sum));
      auxFinalValues.chd.combined_survival_10y = Math.pow(auxFinalValues.chd.survival_10y, Math.exp(auxFinalValues.chd.weighted_sum));

      // step 3 NON-CHD
      auxFinalValues.nonChd.combined_survival_now = Math.pow(auxFinalValues.nonChd.survival_now, Math.exp(auxFinalValues.nonChd.weighted_sum));
      auxFinalValues.nonChd.combined_survival_10y = Math.pow(auxFinalValues.nonChd.survival_10y, Math.exp(auxFinalValues.nonChd.weighted_sum));

      // step 4 CHD
      auxFinalValues.chd.survival_10y_probability = auxFinalValues.chd.combined_survival_10y / auxFinalValues.chd.combined_survival_now;

      // step 4 NON-CHD
      auxFinalValues.nonChd.survival_10y_probability = auxFinalValues.nonChd.combined_survival_10y / auxFinalValues.nonChd.combined_survival_now;

      // step 5 CHD
      auxFinalValues.chd.risk_10y = 1 - auxFinalValues.chd.survival_10y_probability;

      // step 5 NON-CHD
      auxFinalValues.nonChd.risk_10y = 1 - auxFinalValues.nonChd.survival_10y_probability;

      // step 6
      totalRisk = auxFinalValues.chd.risk_10y + auxFinalValues.nonChd.risk_10y;
      let messageRisk = getRiskMessage(totalRisk * 100);

      totalRisk = (totalRisk * 100).toFixed(2);
      setPercentage(totalRisk);
      setResultMessage(messageRisk);
      setIsResultLoading(false);
    }
  };

  const calculateMessage = (calculatorObject) => {
    let totalRisk = null;
    let message = null;

    for (var prop in calculatorObject) {
      if (
        prop === "cardiac" ||
        prop === "atherosclerosis" ||
        prop === "diabetes" ||
        prop === "renal" ||
        prop === "renal_severity" ||
        prop === "colesterol" ||
        prop === "ldl" ||
        prop === "pa"
      ) {
        if (calculatorObject[prop] !== null) {
          if (!sumPoints || calculatorObject[prop] > sumPoints) {
            sumPoints = calculatorObject[prop];
          }
        } else {
          nullItems++;
        }
      }
    }

    if (nullItems && sumPoints !== 3) {
      sumPoints = null;
    }
    if (sumPoints) {
      message = riskCV[sumPoints];
      console.log(sumPoints);
      setResultMessage(message);
      setIsResultLoading(false);
    }
  };

  const getRiskMessage = (risk) => {
    let message_return = "";
    switch (true) {
      case risk < 1:
        message_return = "RISCO BAIXO";
        break;
      case risk >= 1 && risk < 5:
        message_return = "RISCO MODERADO";
        break;
      case risk >= 5 && risk < 10:
        message_return = "ALTO RISCO";
        break;
      default:
        message_return = "MUITO ALTO RISCO";
        break;
    }
    return message_return;
  };

  const round = (num) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  };

  function handleIncrement(key) {
    let value = form.getFieldValue(key);
    if (value) {
      if (value < maxValues[key]) {
        value++;
      }
      form.setFieldsValue({ [key]: value });
      handleValuesChange({ [key]: value }, form.getFieldsValue());
    } else {
      form.setFieldsValue({ [key]: minValues[key] });
      handleValuesChange({ [key]: minValues[key] }, form.getFieldsValue());
    }
  }

  function handleDecrement(key) {
    let value = form.getFieldValue(key);
    if (value) {
      if (value > minValues[key]) {
        value--;
      }
      form.setFieldsValue({ [key]: value });
      handleValuesChange({ [key]: value }, form.getFieldsValue());
    } else {
      form.setFieldsValue({ [key]: minValues[key] });
      handleValuesChange({ [key]: minValues[key] }, form.getFieldsValue());
    }
  }

  return (
    <>
      <Helmet>
        <title>Calculadora AVALIAÇÃO DO RISCO CV GLOBAL | Formação Científica em Medicina Cardiovascular</title>
        <meta
          name="description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
        <meta
          name="keywords"
          content="calculadoras área cardiovascular, Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:title" content="Calculadora AVALIAÇÃO DO RISCO CV GLOBAL | Formação Científica em Medicina Cardiovascular" />
        <meta
          property="og:description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
      </Helmet>
      <CalculatorBanner title="AVALIAÇÃO DO RISCO CV GLOBAL" />

      <CalculatorContent className="mt-10">
        <Form className="form_calculator" form={form} onValuesChange={handleDebounce(handleValuesChange)}>
          {/* Row #1 */}
          <Row
            gutter={[
              { xs: 20, sm: 40, md: 40, lg: 60 },
              { xs: 10, sm: 40, md: 40, lg: 60 },
            ]}
          >
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Evento CV Prévio</p>
              <Row className="mt-10 d-flex flex-nowrap">
                <Form.Item name={"cardiac"} className="w-100">
                  <Radio.Group className="w-100 d-flex flex-nowrap">
                    <Radio.Button value={1} className="w-100 mr-10">
                      Sim
                    </Radio.Button>
                    <Radio.Button value={0} className="w-100 ml-10">
                      Não
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Row>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Placa asterosclerose significativa</p>
              <Row className="mt-10 d-flex flex-nowrap">
                <Form.Item name={"atherosclerosis"} className="w-100">
                  <Radio.Group className="w-100 d-flex flex-nowrap">
                    <Radio.Button value={1} className="w-100 mr-10">
                      Sim
                    </Radio.Button>
                    <Radio.Button value={0} className="w-100 ml-10">
                      Não
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Row>
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Diabetes</p>
              <Row className="mt-10 d-flex flex-nowrap">
                <Form.Item name={"diabetes"} className="w-100">
                  <Radio.Group className="w-100 d-flex flex-nowrap">
                    <Radio.Button value={1} className="w-100 mr-10">
                      Sim
                    </Radio.Button>
                    <Radio.Button value={0} className="w-100 ml-10">
                      Não
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Row>
              <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.diabetes !== currentValues.diabetes}>
                {({ getFieldValue }) =>
                  getFieldValue("diabetes") ? (
                    <Row className="w-100">
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row className="mt-10 d-flex flex-nowrap">
                          <Form.Item name={"diabetes_checkbox"} valuePropName="checked">
                            <Checkbox.Group className="w-100 d-flex flex-column" style={{ gap: "20px" }}>
                              <Checkbox value="diabetes_risk3">{">= 3 fatores de risco"}</Checkbox>
                              <Checkbox value="diabetes_lesion">Lesão órgão alvo</Checkbox>
                              <Checkbox value="diabetes_long_duration">{"Longa duração (> 20 anos)"}</Checkbox>
                              <Checkbox value="diabetes_risk1">1 fator de risco</Checkbox>
                              <Checkbox value="diabetes_other">{"DM1 < 35 anos ou DM2 < 50 anos, curta duração diabetes (< 10 anos) e se outros fatores de risco"}</Checkbox>
                            </Checkbox.Group>
                          </Form.Item>
                        </Row>
                      </Col>
                    </Row>
                  ) : null
                }
              </Form.Item>
            </Col>

            {/* Row #2 */}

            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Doença renal crónica</p>
              <Row className="mt-10 d-flex flex-nowrap">
                <Form.Item name={"renal"} className="w-100">
                  <Radio.Group className="w-100 d-flex flex-nowrap">
                    <Radio.Button value={1} className="w-100 mr-10">
                      Sim
                    </Radio.Button>
                    <Radio.Button value={0} className="w-100 ml-10">
                      Não
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Row>

              <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.renal !== currentValues.renal}>
                {({ getFieldValue }) =>
                  getFieldValue("renal") ? (
                    <Row className="d-flex flex-nowrap">
                      <Form.Item name={"renal_severity"} className="w-100">
                        <Radio.Group className="w-100 d-flex flex-column" style={{ gap: "10px" }}>
                          <Radio.Button value="grave" className="w-100">
                            {"Grave (Cl. Creat < 30 ml/min)"}
                          </Radio.Button>
                          <Radio.Button value="moderada" className="w-100">
                            Moderada (Cl. Creat 30-59 ml/min)
                          </Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    </Row>
                  ) : null
                }
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">{"Colesterol total > 310mg/dL"}</p>
              <Row className="mt-10 d-flex flex-nowrap">
                <Form.Item name={"colesterol"} className="w-100">
                  <Radio.Group className="w-100 d-flex flex-nowrap">
                    <Radio.Button value={1} className="w-100 mr-10">
                      Sim
                    </Radio.Button>
                    <Radio.Button value={0} className="w-100 ml-10">
                      Não
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Row>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">{"LDL > 190 mg/dL"}</p>
              <Row className="mt-10 d-flex flex-nowrap">
                <Form.Item name={"ldl"} className="w-100">
                  <Radio.Group className="w-100 d-flex flex-nowrap">
                    <Radio.Button value={1} className="w-100 mr-10">
                      Sim
                    </Radio.Button>
                    <Radio.Button value={0} className="w-100 ml-10">
                      Não
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Row>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">{"PA > 180/110 mmHg"}</p>
              <Row className="mt-10 d-flex flex-nowrap">
                <Form.Item name={"pa"} className="w-100">
                  <Radio.Group className="w-100 d-flex flex-nowrap">
                    <Radio.Button value={1} className="w-100 mr-10">
                      Sim
                    </Radio.Button>
                    <Radio.Button value={0} className="w-100 ml-10">
                      Não
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Row>
            </Col>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.cardiac !== currentValues.cardiac ||
                prevValues.atherosclerosis !== currentValues.atherosclerosis ||
                prevValues.diabetes !== currentValues.diabetes ||
                prevValues.renal !== currentValues.renal ||
                prevValues.colesterol !== currentValues.colesterol ||
                prevValues.ldl !== currentValues.ldl ||
                prevValues.pa !== currentValues.pa
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("cardiac") === 0 &&
                getFieldValue("atherosclerosis") === 0 &&
                getFieldValue("diabetes") === 0 &&
                getFieldValue("renal") === 0 &&
                getFieldValue("colesterol") === 0 &&
                getFieldValue("ldl") === 0 &&
                getFieldValue("pa") === 0 ? (
                  <Col xs={24} sm={12} md={12} lg={12} xl={8}>
                    <p className="blue bold">Sexo</p>
                    <Row className="mt-10 d-flex flex-nowrap">
                      <Form.Item name={"sex"} className="w-100">
                        <Radio.Group className="w-100 d-flex flex-nowrap">
                          <Radio.Button value={1} className="w-100 mr-10">
                            Feminino
                          </Radio.Button>
                          <Radio.Button value={0} className="w-100 ml-10">
                            Masculino
                          </Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    </Row>
                  </Col>
                ) : null
              }
            </Form.Item>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.cardiac !== currentValues.cardiac ||
                prevValues.atherosclerosis !== currentValues.atherosclerosis ||
                prevValues.diabetes !== currentValues.diabetes ||
                prevValues.renal !== currentValues.renal ||
                prevValues.colesterol !== currentValues.colesterol ||
                prevValues.ldl !== currentValues.ldl ||
                prevValues.pa !== currentValues.pa
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("cardiac") === 0 &&
                getFieldValue("atherosclerosis") === 0 &&
                getFieldValue("diabetes") === 0 &&
                getFieldValue("renal") === 0 &&
                getFieldValue("colesterol") === 0 &&
                getFieldValue("ldl") === 0 &&
                getFieldValue("pa") === 0 ? (
                  <Col xs={24} sm={12} md={12} lg={12} xl={8}>
                    <p className="blue bold">Fumador</p>
                    <Row className="mt-10 d-flex flex-nowrap">
                      <Form.Item name={"smoker"} className="w-100">
                        <Radio.Group className="w-100 d-flex flex-nowrap">
                          <Radio.Button value={1} className="w-100 mr-10">
                            Sim
                          </Radio.Button>
                          <Radio.Button value={0} className="w-100 ml-10">
                            Não
                          </Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    </Row>
                  </Col>
                ) : null
              }
            </Form.Item>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.cardiac !== currentValues.cardiac ||
                prevValues.atherosclerosis !== currentValues.atherosclerosis ||
                prevValues.diabetes !== currentValues.diabetes ||
                prevValues.renal !== currentValues.renal ||
                prevValues.colesterol !== currentValues.colesterol ||
                prevValues.ldl !== currentValues.ldl ||
                prevValues.pa !== currentValues.pa
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("cardiac") === 0 &&
                getFieldValue("atherosclerosis") === 0 &&
                getFieldValue("diabetes") === 0 &&
                getFieldValue("renal") === 0 &&
                getFieldValue("colesterol") === 0 &&
                getFieldValue("ldl") === 0 &&
                getFieldValue("pa") === 0 ? (
                  <Col xs={24} sm={12} md={12} lg={12} xl={8}>
                    <p className="blue bold">Idade</p>
                    <Row className="mt-10 d-flex flex-nowrap">
                      <Button onClick={() => handleDecrement("age")} className="calculator_button mr-10">
                        -
                      </Button>
                      <Form.Item name={"age"} className="w-100 mb-0">
                        <Input type="number" className="w-100" />
                      </Form.Item>
                      <Button onClick={() => handleIncrement("age")} className="calculator_button ml-10">
                        +
                      </Button>
                    </Row>
                    <p className="f-14 mt-10">Valor entre: 40 - 69</p>
                  </Col>
                ) : null
              }
            </Form.Item>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.cardiac !== currentValues.cardiac ||
                prevValues.atherosclerosis !== currentValues.atherosclerosis ||
                prevValues.diabetes !== currentValues.diabetes ||
                prevValues.renal !== currentValues.renal ||
                prevValues.colesterol !== currentValues.colesterol ||
                prevValues.ldl !== currentValues.ldl ||
                prevValues.pa !== currentValues.pa
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("cardiac") === 0 &&
                getFieldValue("atherosclerosis") === 0 &&
                getFieldValue("diabetes") === 0 &&
                getFieldValue("renal") === 0 &&
                getFieldValue("colesterol") === 0 &&
                getFieldValue("ldl") === 0 &&
                getFieldValue("pa") === 0 ? (
                  <Col xs={24} sm={12} md={12} lg={12} xl={8}>
                    <p className="blue bold">Colesterol</p>
                    <Row className="mt-10 d-flex flex-nowrap">
                      <Button onClick={() => handleDecrement("total_colesterol")} className="calculator_button mr-10">
                        -
                      </Button>
                      <Form.Item name={"total_colesterol"} className="w-100 mb-0">
                        <Input type="number" className="w-100" />
                      </Form.Item>
                      <Button onClick={() => handleIncrement("total_colesterol")} className="calculator_button ml-10">
                        +
                      </Button>
                    </Row>
                    <p className="f-14 mt-10">Variável contínua entre 116 e 348 mg/dL</p>
                  </Col>
                ) : null
              }
            </Form.Item>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.cardiac !== currentValues.cardiac ||
                prevValues.atherosclerosis !== currentValues.atherosclerosis ||
                prevValues.diabetes !== currentValues.diabetes ||
                prevValues.renal !== currentValues.renal ||
                prevValues.colesterol !== currentValues.colesterol ||
                prevValues.ldl !== currentValues.ldl ||
                prevValues.pa !== currentValues.pa
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("cardiac") === 0 &&
                getFieldValue("atherosclerosis") === 0 &&
                getFieldValue("diabetes") === 0 &&
                getFieldValue("renal") === 0 &&
                getFieldValue("colesterol") === 0 &&
                getFieldValue("ldl") === 0 &&
                getFieldValue("pa") === 0 ? (
                  <Col xs={24} sm={12} md={12} lg={12} xl={8}>
                    <p className="blue bold">Pressão Arterial Sistólica (mmHg)</p>
                    <Row className="mt-10 d-flex flex-nowrap">
                      <Button onClick={() => handleDecrement("pressure")} className="calculator_button mr-10">
                        -
                      </Button>
                      <Form.Item name={"pressure"} className="w-100 mb-0">
                        <Input type="number" className="w-100" />
                      </Form.Item>
                      <Button onClick={() => handleIncrement("pressure")} className="calculator_button ml-10">
                        +
                      </Button>
                    </Row>
                    <p className="f-14 mt-10">Variável contínua entre 100 e 200</p>
                  </Col>
                ) : null
              }
            </Form.Item>
          </Row>
        </Form>
      </CalculatorContent>

      {resultMessage && (
        <CalculatorResult isLoading={isResultLoading}>
          <p>Resultado:</p>
          <p className="f-30 text-uppercase bold mt-20">
            {percentage ? percentage + "%" : ""} {resultMessage}
          </p>
        </CalculatorResult>
      )}

      <CalculatorLinks />
    </>
  );
};
export default CalculatorCVGlobal;
