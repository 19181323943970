import React, { useEffect, useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
import { CloseOutlined } from "@ant-design/icons";
import slugify from "react-slugify";
import { Outlet, Link, useLocation, useSearchParams } from "react-router-dom";
import ReactPlayer from "react-player";
import {
  Col,
  Row,
  Card,
  Select,
  Space,
  Button,
  Form,
  Input,
  Checkbox,
  Tabs,
} from "antd";
import BannerSummit from "../../../assets/images/banner-summit-2023.png";
import FiltroIcon from "../../../assets/images/filtro-icon.svg";
import SearchIcon from "../../../assets/images/search-icon.svg";
import config from "../../../services/config";
import "./ListCourses.css";
import ModalVideo from "../ModalVideo/ModalVideo";
import CalculatorCTA from "../CalculatorCTA/CalculatorCTA";

const { Meta } = Card;

const ListCourses = ({ data }) => {
  const [courses, setCourses] = useState({});
  const [selectedVideo, setSelectedVideo] = useState({});
  const [isOpenModalVideo, setIsOpenModalVideo] = useState(false);

  const [itemsToShow, setItemsToShow] = useState(10);

  const handleLoadMore = () => {
    setItemsToShow(itemsToShow + 10);
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const [form] = Form.useForm();

  const location = useLocation();

  useEffect(() => {
    setCourses(data);
  }, [data]);

  function handleOpenModalVideo(item) {
    setSelectedVideo(item);
    setIsOpenModalVideo(true);
  }

  function handleCloseModal() {
    setIsOpenModalVideo(false);
  }

  return (
    <Row id="section-list-videos" className="container mt-60">
      <Col span={24} className="d-flex flex-column jc-center ai-center">
        <Row
          gutter={[
            { xs: 40, sm: 8, md: 16, lg: 24 },
            { xs: 40, sm: 8, md: 16, lg: 24 },
          ]}
          className="mr-0 ml-0 d-flex"
        >
          {data?.slice(0, itemsToShow + 1).map((item, index) => {
            return (
              <Col
                xs={24}
                sm={12}
                md={12}
                lg={8}
                xl={8}
                className="d-flex flex-column mb-20"
                //onClick={() => handleOpenModalVideo(item)}
              >
                <div className="player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    controls
                    volume={1}
                    url={item?.link}
                  />
                </div>
                <p className="blue f-18 mb-10 mt-10">{item?.title}</p>
                {item?.description && (
                  <p className="f-16 normal ellipsis_list_description">
                    {item?.description}
                  </p>
                )}
              </Col>
            );
          })}
          {data && itemsToShow < data?.length && (
            <Row className="d-flex jc-center ai-center mt-40 mb-40">
              <Button className="btn-ver-mais" onClick={handleLoadMore}>
                Explorar mais videos
              </Button>
            </Row>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default ListCourses;
