import React, { useState, useEffect } from "react";
import { Button, Form, Drawer, Select, message, Input } from "antd";
import axios from "axios";

import endpoints from "../../../services/endpoints";

function Update({ data, open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      form.setFieldsValue(data);
    }
  }, [data]);

  function handleClose() {
    close();
    form.resetFields();
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);

    axios
      .post(endpoints.events.updateUser, {
        data: values,
      })
      .then((res) => {
        setIsButtonLoading(false);
        handleClose();
      })
      .catch((error) => {
        setIsButtonLoading(false);
        console.error(error);
      });
  }

  return (
    <Drawer
      className="drawer-user"
      key={`drawer-create-user`}
      title="Criar participante"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={[
        <Button disabled={isButtonLoading} className="mr-10" onClick={handleClose}>
          Cancelar
        </Button>,
        <Button loading={isButtonLoading} type="primary" onClick={form.submit}>
          Criar
        </Button>,
      ]}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input placeholder="Nome" size="large" />
        </Form.Item>
        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input placeholder="Nome" size="large" />
        </Form.Item>
        <Form.Item
          label="Código"
          name="password"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input placeholder="Código" size="large" />
        </Form.Item>

        <Form.Item
          label="Tipo de inscrição"
          name="type"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Select
            size="large"
            showSearch
            placeholder="Tipo..."
            options={[
              { value: "VIRTUAL", label: "VIRTUAL" },
              { value: "PRESENCIAL", label: "PRESENCIAL" },
            ]}
            getPopupContainer={(trigger) => trigger.parentNode}
          />
        </Form.Item>

        <Form.Item
          label="Convite"
          name="invited_from"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input size="large" placeholder="nd" />
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default Update;
