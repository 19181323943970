import React from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet";

const Content = styled.section`  
  margin: auto;  
  padding: 60px 20px;
  max-width: 1250px;

  * { margin: 16px 0; }
  ul, ol { padding-left: 40px; }

  p {
    color: #1C1C1C;
    font: normal normal normal 16px/26px Poppins;
    letter-spacing: 0.48px;
    text-align: left;
  }

  a {
    color: #1C1C1C;
    text-decoration: underline;
  }

  @media (max-width: 990px) {
    padding: 120px 20px;
  }
`

const Title = styled.h2`
  color: #0E426A;
  font: normal normal bold 36px/55px Poppins;
  letter-spacing: 1.08px;
  margin-bottom: 30px;
  text-align: center;
`

const TermosCondicoesPage = () => {
  return (
    <>
      <Helmet>
        <title>Termos e condições do Cardio4all | Formação Científica em Medicina Cardiovascular</title>
        <meta name="description" content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares." />
        <meta name="keywords" content="Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, calculadoras área cardiovascular, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular" />
        <meta property="og:title" content="Termos e condições do Cardio4all | Formação Científica em Medicina Cardiovascular" />
        <meta property="og:description" content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares." />
      </Helmet>
      <Content>
        <Title>Termos e condições</Title>
        <p>
          <b>
            1. Termos 
          </b>
        </p>
        <p>
          Ao acessar ao site <a href="https://cardio4all.pt/">https://cardio4all.pt/</a>, concorda em cumprir estes termos de serviço, todas as leis e regulamentos aplicáveis e concorda que é responsável pelo cumprimento de todas as leis locais aplicáveis. Se você não concordar com algum desses termos, está proibido de usar ou acessar este site. Os materiais contidos neste site são protegidos pelas leis de direitos autorais e marcas comerciais aplicáveis. 
        </p>
        <p>
          <b>
            2. Uso de Licença 
          </b>
        </p>
        <p>
          É concedida permissão para baixar temporariamente uma cópia dos materiais (informações ou software) no site Cardio4all, apenas para visualização transitória pessoal e não comercial. Esta é a concessão de uma licença, não uma transferência de título e, sob esta licença, você não pode: 
        </p>
        <p>
          <ul>
            <li>
              modificar ou copiar os materiais; 
            </li>
            <li>
              usar os materiais para qualquer finalidade comercial ou para exibição pública (comercial ou não comercial); 
            </li>
            <li>
              tentar descompilar ou fazer engenharia reversa de qualquer software contido no site Cardio4all remover quaisquer direitos autorais ou outras notações de propriedade dos materiais; ou 
            </li>
            <li>
              transferir os materiais para outra pessoa ou ‘espelhe’ os materiais em qualquer outro servidor. 
            </li>
            <li>
              Esta licença será automaticamente rescindida se você violar alguma dessas restrições e poderá ser rescindida por Cardio4all a qualquer momento. Ao encerrar a visualização desses materiais ou após o término desta licença, você deve apagar todos os materiais baixados em sua posse, seja em formato eletrónico ou impresso.
            </li>
          </ul>
        </p>
        <p>
          <b>
            3. Isenção de responsabilidade
          </b>
        </p>
        <p>
          <ol>
            <li>
              Os materiais no site do <a href="https://cardio4all.pt/">https://cardio4all.pt/</a> são fornecidos ‘como estão’. PHORMULA GROUP, lda não oferece garantias, expressas ou implícitas, e, por este meio, isenta e nega todas as outras garantias, incluindo, sem limitação, garantias implícitas ou condições de comercialização, adequação a um fim específico ou não violação de propriedade intelectual ou outra violação de direitos. 
            </li>
            <li>
              Além disso, o PHORMULA GROUP, lda não garante ou faz qualquer representação relativa à precisão, aos resultados prováveis ou à confiabilidade do uso dos materiais no seu site ou de outra forma relacionado a esses materiais ou em sites vinculados a este site.
            </li>
          </ol>
        </p>
        <p>
          <b>
            4. Limitações 
          </b>
        </p>
        <p>
          Em nenhum caso o Cardio4all ou seus fornecedores serão responsáveis por quaisquer danos (incluindo, sem limitação, danos por perda de dados ou lucro ou devido a interrupção dos negócios) decorrentes do uso ou da incapacidade de usar os materiais em Cardio4all, mesmo que Cardio4all ou um representante autorizado tenha sido notificado oralmente ou por escrito da possibilidade de tais danos. Como algumas jurisdições não permitem limitações em garantias implícitas, ou limitações de responsabilidade por danos consequentes ou incidentais, essas limitações podem não se aplicar a você. 
        </p>
        <p>
          <b>
            5. Precisão dos materiais 
          </b>
        </p>
        <p>
          Os materiais exibidos no site da Cardio4all podem incluir erros técnicos, tipográficos ou fotográficos. Follow – The Healthcare Communication Group não garante que qualquer material em seu site seja preciso, completo ou atual. Follow – The Healthcare Communication Group pode fazer alterações nos materiais contidos em seu site a qualquer momento, sem aviso prévio. No entanto, Follow – The Healthcare Communication Group não se compromete a atualizar os materiais. 
        </p>
        <p>
          <b>
            6. Links 
          </b>
        </p>
        <p>
          A Cardio4all não analisou todos os sites vinculados ao seu site e não é responsável pelo conteúdo de nenhum site vinculado. A inclusão de qualquer link não implica endosso por Cardio4all do site. O uso de qualquer site vinculado é por conta e risco do usuário. 
        </p>
        <p>
          <b>
            Modificações 
          </b>
        </p>
        <p>
          A Cardio4all pode revisar estes termos de serviço do site a qualquer momento, sem aviso prévio. Ao usar este site, você concorda em ficar vinculado à versão atual desses termos de serviço. 
        </p>
        <p>
          <b>
            Lei aplicável 
          </b>
        </p>
        <p>
          Estes termos e condições são regidos e interpretados de acordo com as leis do Cardio4all e você se submete irrevogavelmente à jurisdição exclusiva dos tribunais naquele estado ou localidade. 
        </p>
        <p>
          <b>
            Esta política é efetiva a partir de Setembro/2023. 
          </b>
        </p>
      </Content>
    </>
  )
}
export default TermosCondicoesPage