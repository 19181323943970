import React, { useEffect, useState } from "react";
import Main from "../../components/dashboard/Main";
import { Outlet, Route, useLocation, Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo-cardio4all-white.svg";
import "./Admin.css";
import { Layout, Button, theme, Menu, message } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, UploadOutlined, UserOutlined, FileImageOutlined, AudioOutlined, DashboardOutlined, FileOutlined } from "@ant-design/icons";

const { Header, Sider, Content } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem("Artigos", "admin/articles", <FileOutlined />),
  getItem("Banners", "admin/banners", <DashboardOutlined />),
  getItem("Calculadoras", "admin/calculadoras", <FileOutlined />),
  getItem("Multimédia", "admin/media", <DashboardOutlined />),
  getItem("Podcasts", "admin/podcasts", <DashboardOutlined />),
  getItem("Tags", "admin/tags", <FileOutlined />),
  getItem("Áreas médicas", "admin/medicalAreas", <FileOutlined />),
  getItem("Videos", "admin/videos", <DashboardOutlined />),
  getItem("Cursos", "admin/cursos", <DashboardOutlined />),
  getItem("Guias", "admin/guides", <DashboardOutlined />),
  getItem("Casos clínicos", "admin/clinical-cases", <DashboardOutlined />),
  getItem("Newsletters", "admin/newsletters", <DashboardOutlined />),
  getItem("Utilizadores", "admin/users", <DashboardOutlined />),
  getItem("Evento", "admin/events", <DashboardOutlined />),
];

const Admin = ({ user, isLoggedIn, handleLogOut }) => {
  const location = useLocation();
  const navigate = useNavigate();
  let pathname = location.pathname.split("/");

  const [current, setCurrent] = useState(pathname[pathname.length - 1] === "" ? "admin/" : `admin/${pathname[pathname.length - 1]}`);
  const [isLoading, setIsLoading] = useState(true);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    handleVerifyUser();
  }, [user]);

  function handleVerifyUser() {
    if (!user) {
      message.error({
        content: "Antes de entrar no backoffice precisa de fazer login",
      });
      return navigate(`/login?redirect=/admin`);
    } else {
      if (!user.is_admin) {
        message.error({
          content: "Não tem permissões para entrar no backoffice",
        });
        return navigate("/");
      } else {
        setIsLoading(false);
      }
    }
  }

  function handleClickMenu(e) {
    navigate(e.key);
  }

  return (
    <>
      {isLoading ? (
        <div id="loader"></div>
      ) : (
        <Layout className="admin_layout">
          <Sider width={250} collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
            <Link to="/">
              <div className="logo"></div>
            </Link>
            <Menu theme="dark" defaultSelectedKeys={[current]} mode="inline" items={items} onClick={handleClickMenu} />
          </Sider>
          <Layout className="site-layout">
            <Header>
              <div className="d-flex jc-end ai-center white">
                <UserOutlined className="mr-10" /> {user.name}
              </div>
            </Header>
            <Content className="admin_layout_content">
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      )}
    </>
  );
};
export default Admin;
