import React, { useState, useEffect } from "react";
import { Button, Checkbox, Col, ColorPicker, Form, InputNumber, Radio, Drawer, Row, Select, message, Space, Switch, Upload, Input, Divider, DatePicker } from "antd";
import "../../../assets/styles/admin.css";
import axios from "axios";
import api from "../../../services/api";
import { Outlet, Link } from "react-router-dom";
import { InboxOutlined, UploadOutlined, DesktopOutlined, MobileOutlined, FileOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import { useForm } from "antd/es/form/Form";
import config from "../../../services/config";
import endpoints from "../../../services/endpoints";
import "react-quill/dist/quill.snow.css";
import Password from "antd/es/input/Password";
import districts from "../../../services/districts";
const { Dragger } = Upload;

const { Option } = Select;
const { TextArea } = Input;

function Create({ open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = useForm();

  function handleClose() {
    close();
    form.resetFields();
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);

    axios
      .post(endpoints.events.createUser, {
        data: values,
      })
      .then((res) => {
        setIsButtonLoading(false);
        handleClose();
      })
      .catch((error) => {
        setIsButtonLoading(false);
        console.error(error);
      });
  }

  return (
    <Drawer
      className="drawer-user"
      key={`drawer-create-user`}
      title="Criar participante"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        <>
          <Button disabled={isButtonLoading} className="mr-10" onClick={handleClose}>
            Cancelar
          </Button>
          <Button loading={isButtonLoading} type="primary" onClick={form.submit}>
            Criar
          </Button>
        </>
      }
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input placeholder="Nome" size="large" />
        </Form.Item>
        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input placeholder="Nome" size="large" />
        </Form.Item>
        <Form.Item
          label="Código"
          name="password"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input placeholder="Código" size="large" />
        </Form.Item>

        <Form.Item
          label="Tipo de inscrição"
          name="type"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Select
            size="large"
            showSearch
            placeholder="Tipo..."
            options={[
              { value: "VIRTUAL", label: "VIRTUAL" },
              { value: "PRESENCIAL", label: "PRESENCIAL" },
            ]}
            getPopupContainer={(trigger) => trigger.parentNode}
          />
        </Form.Item>

        <Form.Item
          label="Convite"
          name="invited_from"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input size="large" placeholder="nd" />
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default Create;
