const endpoints = {
  info: {
    read: "/info/read",
  },
  banners: {
    read: "/banner/read",
    create: "/banner/create",
    update: "/banner/update",
    delete: "/banner/delete",
  },
  guides: {
    read: "/guide/read",
    readBySlug: "/guide/readBySlug",
    readByTopicSlug: "/guide/readByTopicSlug",
    readModules: "/guide/readModules",
    readTopics: "/guide/readTopics",
    create: "/guide/create",
    createModule: "/guide/createModule",
    createTopic: "/guide/createTopic",
    update: "/guide/update",
    updateModule: "/guide/updateModule",
    updateTopic: "/guide/updateTopic",
    delete: "/guide/delete",
    deleteModule: "/guide/deleteModule",
    deleteTopic: "/guide/deleteTopic",
  },
  clinicalCases: {
    read: "/clinicalCase/read",
    readOrientation: "/clinicalCase/readOrientation",
    readOrientationById: "/clinicalCase/readOrientationById",
    create: "/clinicalCase/create",
    createFeedback: "/clinicalCase/createFeedback",
    createOrientation: "/clinicalCase/createOrientation",
    readById: "/clinicalCase/readById",
    readBySlug: "/clinicalCase/readBySlug",
    update: "/clinicalCase/update",
    updateQuestions: "/clinicalCase/updateQuestions",
    delete: "/clinicalCase/delete",
  },
  articles: {
    read: "/article/read",
    readBySlug: "/article/readBySlug",
    readById: "/article/readById",
    create: "/article/create",
    update: "/article/update",
    duplicate: "/article/duplicate",
    delete: "/article/delete",
  },
  tags: {
    read: "/tag/read",
    filter: "/tag/filter",
    create: "/tag/create",
    update: "/tag/update",
    delete: "/tag/delete",
  },
  medicalAreas: {
    read: "/medicalArea/read",
    create: "/medicalArea/create",
    update: "/medicalArea/update",
    delete: "/medicalArea/delete",
  },
  calculators: {
    read: "/calculator/read",
    create: "/calculator/create",
    update: "/calculator/update",
    delete: "/calculator/delete",
  },
  podcasts: {
    read: "/podcast/read",
    create: "/podcast/create",
    update: "/podcast/update",
    delete: "/podcast/delete",
  },
  videos: {
    read: "/video/read",
    create: "/video/create",
    update: "/video/update",
    delete: "/video/delete",
  },
  media: {
    read: "/media/read",
    upload: "/media/upload",
    singleUpload: "/media/singleUpload",
    delete: "/media/delete",
  },
  newsletters: {
    read: "/newsletter/read",
    create: "/newsletter/create",
    update: "/newsletter/update",
    delete: "/newsletter/delete",
  },
  user: {
    read: "/user/read",
    readDashboard: "/user/readDashboard",
    update: "/user/update",
    delete: "/user/delete",
    create: "/user/create",
    adminUpdate: "/user/adminUpdate",
    adminDelete: "/user/adminDelete",
    readFileImport: "/user/readFileImport",
    import: "/user/import",
    subscribeNewsletter: "/user/subscribeNewsletter",
  },
  events: {
    read: "/events/read",
    readByParams: "/events/readByParams",
    import: "/events/import",
    login: "/events/login",
    createUser: "/events/createUser",
    updateUser: "/events/updateUser",
    deleteUser: "/events/deleteUser",
  },
  suggestions: {
    create: "/suggestion/create",
    read: "/suggestion/read",
    update: "/suggestion/update",
    delete: "/suggestion/delete",
  },
  contact: {
    create: "/contact/create",
    read: "/contact/read",
    update: "/contact/update",
    delete: "/contact/delete",
  },
  course: {
    read: "/course/read",
    readBySlug: "/course/readBySlug",
    create: "/course/create",
    update: "/course/update",
    delete: "/course/delete",
  },
  courseItem: {
    read: "/courseItem/read",
    create: "/courseItem/create",
    readById: "/courseItem/readById",
    update: "/courseItem/update",
    delete: "/courseItem/delete",
  },
  auth: {
    login: "/auth/login",
    register: "/auth/register",
    resetPasswordEmail: "/auth/resetPasswordEmail",
    resetPassword: "/auth/resetPassword",
  },
};

export default endpoints;
