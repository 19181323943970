import React, { useEffect, useState } from "react";
import { EyeOutlined } from '@ant-design/icons';
import { CloseOutlined } from '@ant-design/icons';

import { Col, Row, Card, Select, Space, Button, Form, Input, Checkbox } from 'antd';
import IconEyeImage from "../../../assets/images/vizualizacoes-icon.svg";
import IconCalendarImage from "../../../assets/images/calendario-icon.svg";
import BannerSummit from "../../../assets/images/banner-summit-2023.png";
import FiltroIcon from "../../../assets/images/filtro-icon.svg";
import SearchIcon from "../../../assets/images/search-icon.svg";
import "../../../assets/styles/podcasts.css";
import './ListPodcasts.css'
import { Outlet, Link } from "react-router-dom";
import dayjs from "dayjs";
const { Meta } = Card;

const ListPodcasts = ({ data }) => {

  const [podcasts, setPodcasts] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectFilter, setSelectFilter] = useState([]);
  const month = ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"];

  const [form] = Form.useForm();

  useEffect(() => {
    setPodcasts(data);
  }, [data]);

  useEffect(() => {
    setFilteredData(podcasts);
  }, [podcasts]);

  const handleFilterData = (changedValues, allValues) => {
    let formObject = { ...allValues };
    if (formObject.title?.length === false) {
      setFilteredData(podcasts);
      return;
    }

    let inputLower = (formObject.title?.length > 0) ? formObject.title?.toLowerCase() : '';

    let filteredItens = [];
    podcasts.map((item) => {
      let showItem = true;
      if (selectFilter?.length > 0) {
        if (item.category !== selectFilter) {
          showItem = false;
        }
      }
      if (formObject.title?.length > 0) {
        if (!item.title?.toLowerCase().includes(inputLower)) {
          showItem = false;
        }
      }

      if (showItem === true) {
        filteredItens.push(item);
      }
      return true
    })

    setFilteredData(filteredItens);
  }

  function handleClearFilter() {
    form.resetFields();
    setFilteredData(podcasts);
  }

  const [itemsToShow, setItemsToShow] = useState(10);
  const handleLoadMore = () => {
    setItemsToShow(itemsToShow + 10);
  }

  return (
    <Row className="container" id="section-list-podcasts">
      <Col span={24}>
        <Form
          className="mt-60 mb-60"
          form={form}
          onValuesChange={handleFilterData}
          layout="horizontal"
          initialValues={{ area: [], title: '' }}
        >
          <Row className="d-flex jc-center ai-center ml-0 mr-0" gutter={[24]}>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Row align={'middle'}>
                <Col xs={2} sm={2} md={2} lg={2} xl={2} span={2}>
                  <img src={SearchIcon} width={'25px'} alt='search icon' />
                </Col>
                <Col xs={21} sm={21} md={21} lg={21} xl={21}>
                  <Form.Item name="title" className="mb-0">
                    <Input size="large" placeholder="Procurar podcast" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.area !== currentValues.area || prevValues.title !== currentValues.title}
            >
              {({ getFieldValue }) =>
                getFieldValue('area').length > 0 || getFieldValue('title').length > 0 ? (
                  <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                    <div className="clear-filter-div" onClick={(e) => handleClearFilter(e)}>
                      <CloseOutlined /> <span className="clear-filter">Limpar filtro</span>
                    </div>
                  </Col>
                )
                  :
                  null
              }
            </Form.Item>
          </Row>
        </Form>
        {filteredData.length > 0 &&
          <Row gutter={[32, 40]} className="list-Podcasts-row pb-40 ml-0 mr-0">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <iframe
                style={{ borderRadius: '12px' }}
                src={`${filteredData[0].link}?utm_source=generator&theme=0`}
                width="100%"
                height="152"
                frameBorder="0"
                allowfullscreen=""
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
              />
              <Row className="d-flex ai-center">
                <img width={'30px'} src={IconCalendarImage} />
                <span style={{ color: '#6D8AA2' }}>
                  {month[dayjs(filteredData[0].date).month()]} {dayjs(filteredData[0].date).format('YYYY')}
                </span>
              </Row>
            </Col>
          </Row>
        }
        <Row gutter={[32, 40]} className="list-Podcasts-row pb-40 ml-0 mr-0">
          {filteredData?.slice(1, itemsToShow + 1).map((podcast, index) => {
            if (index != 0) {
              return (
                <Col xs={24} sm={24} md={12} lg={8} xl={8} key={'article-' + podcast?.id}>
                  <iframe
                    style={{ borderRadius: '12px' }}
                    src={`${podcast.link}?utm_source=generator&theme=0`}
                    width="100%"
                    height="152"
                    frameBorder="0"
                    allowfullscreen=""
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                    loading="lazy"
                  />
                  <Row className="d-flex ai-center">
                    <img width={'30px'} src={IconCalendarImage} />
                    <span style={{ color: '#6D8AA2' }}>
                      {month[dayjs(podcast?.date).month()]} {dayjs(podcast?.date).format('YYYY')}
                    </span>
                  </Row>
                </Col>
              )
            }
          })}
        </Row>
        <Row className="d-flex jc-center ai-center mt-40 mb-40">
          {(filteredData && itemsToShow < filteredData?.length) && (
            <Button className="btn-ver-mais" onClick={handleLoadMore}>
              Explorar mais podcasts
            </Button>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default ListPodcasts;
