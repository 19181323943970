import React, { useState } from 'react'
import { Col, Form, Row, Space, Radio, Slider, Button, Input, Tooltip } from 'antd'
import CalculatorBanner from '../../../components/website/CalculatorBanner/CalculatorBanner'
import { CalculatorLinks } from '../../../components/website/CalculatorLinks/CalculatorLinks'
import { CalculatorResult } from '../../../components/website/CalculatorResult/CalculatorResult'
import { CalculatorContent } from '../../../components/website/CalculatorContent/CalculatorContent'
import './Calculators.css'
import { Helmet } from "react-helmet";

/* CALCULADORA SELEÇÃO DA DOSE DE HIPOCOAGULAÇÃO NA FA */
function CalculatorHipocoagulationFA() {

  const [errorFinded, setErrorFinded] = useState(null);
  const [points, setPoints] = useState(0);
  const [messageOne, setMessageOne] = useState(null);
  const [messageTwo, setMessageTwo] = useState(null);
  const [messageThree, setMessageThree] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const [minValues] = useState({ age: 18, weight: 40, creatinine: 0.30 })
  const [maxValues] = useState({ age: 99, weight: 150, creatinine: 6.00 })
  const [isResultLoading, setIsResultLoading] = useState(false);

  const [form] = Form.useForm();

  function handleValuesChange() {
    let allValues = form.getFieldsValue();
    let columns = Object.keys(allValues);

    if (allValues.chadsvasc === 1) {
      setMessageOne('Está indicada a hipocoagulação')
    } else if (allValues.chadsvasc === 0) {
      setMessageOne('Não está indicada a hipocoagulação')
    }

    if (allValues.prosthesis === 1 || allValues.stenosis === 1 || allValues.prosthesis === 1 || allValues.stenosis === 1) {
      setMessageTwo('Doente não elegível para NOAC')
    } else if (allValues.prosthesis === 0 || allValues.stenosis === 0) {
      setMessageTwo('Doente elegível para NOAC')
    }

    let nullItems = 0;
    let auxErrorsFinded = 0;

    for (let i = 0; i < columns.length; i++) {
      if (typeof allValues[columns[i]] === "string") {
        allValues[columns[i]] = Number(allValues[columns[i]]);
      }


      if (allValues[columns[i]] === null || allValues[columns[i]] === undefined) {
        nullItems++;
      }
    }

    if (nullItems === 0) {
      const validationErrors = Object.values(form.getFieldsError());

      for (let e = 0; e < validationErrors.length; e++) {
        if (validationErrors[e].errors.length > 0) {
          auxErrorsFinded++
        }
      }
    }

    let sumPoints = 0;

    if (nullItems === 0 && auxErrorsFinded === 0 && allValues.chadsvasc === 1 && allValues.prosthesis === 0 && allValues.stenosis === 0 && allValues.age) {
      let messageThreeAux = '';

      sumPoints = ((140 - allValues.age) * allValues.weight) / (allValues.creatinine * 72);
      if (allValues.sex === 1) {
        sumPoints = sumPoints * 0.85;
      }

      sumPoints = parseFloat(sumPoints).toFixed(0);


      messageThreeAux = "<h3>Rivaroxabano</h3><p class='mt-10 mb-30'>";

      //Rivaroxabano
      // ClCr 15-49 = "A dose correta de rivaroxabano é de 15mg, uma vez por dia "A dose correta de rivaroxabano é de 20mg, uma vez por dia
      // ClCr <15 ou dialisado = "O uso de rivaroxabano não está recomendado"
      // Se nenhuma das anteriores = "A dose correta de rivaroxabano é de 20mg, uma vez por dia"

      if (allValues.dialysis) {
        messageThreeAux += "O uso de rivaroxabano não está recomendado";
      } else {
        switch (true) {
          case sumPoints < 15:
            messageThreeAux += "O uso de rivaroxabano não está recomendado";
            break;
          case sumPoints >= 15 && sumPoints <= 49:
            messageThreeAux += "A dose correta de rivaroxabano é de 15mg, uma vez por dia";
            break;
          default:
            messageThreeAux += "A dose correta de rivaroxabano é de 20mg, uma vez por dia";
            break;
        }
      }
      messageThreeAux += "</p><h3>Apixabano</h3><p class='mt-10 mb-30'>";

      //Apixabano	ClCr 15-29 = "A dose correta de apixabano é de 2,5mg, duas vezes por dia"
      // (2 de 3) creatinina sérica ≥1,50 / idade ≥80 / peso ≤ 60kg = "A dose correta de apixabano é de 2,5mg, duas vezes por dia"
      // ClCr <15 ou dialisado = "O uso de apixabano não está recomendado"
      // Se nenhuma das anteriores = "A dose correta de apixabano é de 5mg, duas vezes por dia"

      if (allValues.dialysis === 1) {
        messageThreeAux += "O uso de apixabano não está recomendado";
      } else {
        switch (true) {
          case sumPoints < 15:
            messageThreeAux += "O uso de apixabano não está recomendado";
            break;
          case sumPoints >= 15 && sumPoints <= 29:
            messageThreeAux += "A dose correta de apixabano é de 2,5mg, duas vezes por dia";
            break;
          case sumPoints >= 30 && twoOfThree():
            messageThreeAux += "A dose correta de apixabano é de 2,5mg, duas vezes por dia";
            break;
          default:
            messageThreeAux += "A dose correta de apixabano é de 5mg, duas vezes por dia";
            break;
        }
      }
      messageThreeAux += "</p><h3>Edoxabano</h3><p class='mt-10 mb-30'>";

      //Peso <= 60kg = "A dose correta de edoxabano é de 30mg, uma vez por dia"
      //Uso concomitante de ciclosporina, eritromicina ou cetoconazol = "A dose correta de edoxabano é de 30mg, uma vez por dia"
      // ClCr <15 ou dialisado = "O uso de edoxabano não está recomendado"
      // Se nenhuma das anteriores = "A dose correta de edoxabano é de 60mg, uma vez por dia"

      if (allValues.dialysis) {
        messageThreeAux += "O uso de edoxabano não está recomendado";
      } else {
        switch (true) {
          case sumPoints < 15:
            messageThreeAux += "O uso de edoxabano não está recomendado";
            break;
          case sumPoints >= 15 && sumPoints <= 50:
            messageThreeAux += "A dose correta de edoxabano é de 30mg, uma vez por dia";
            break;
          case sumPoints >= 30 && allValues.weight <= 60:
            messageThreeAux += "A dose correta de edoxabano é de 30mg, uma vez por dia";
            break;
          default:
            messageThreeAux += "A dose correta de edoxabano é de 60mg, uma vez por dia";
            messageThreeAux += "<br><span style='font-size: 11px'>Se uso concomitante de ciclosporina, eritromicina ou cetoconazol, a dose correta de edoxabano é de 30mg, uma vez por dia</span>";
            break;
        }
      }

      messageThreeAux += "</p><h3>Dabigatrano</h3><p class='mt-10 mb-30'>";

      //Dabigatrano	ClCr <30 ou dialisado = "O uso de dabigatrano não está recomendado"
      //ClCr 30-50ml/min ; idade entre 75 e 79 anos ; doente com gastrite, esofagite ou refluxo gastroesofágico ; risco aumentado de hemorragias = "A dose de 110mg ou 150mg duas vezes por dia deve ser selecionada com base no risco tromboembólico e hemorrágico global"
      //Uso concomitante verapamilo = "A dose correta de dabigatrano é de 110mg duas vezes por dia"
      //Idade ≥80 anos = "A dose correta de dabigatrano é de 110mg duas vezes por dia"
      //Se nenhuma das anteriores = "A dose correta de dabigatrano é de 150mg, duas vezes por dia"

      if (allValues.dialysis) {
        messageThreeAux += "O uso de dabigatrano não está recomendado";
      } else {
        switch (true) {
          case sumPoints < 30:
            messageThreeAux += "O uso de dabigatrano não está recomendado";
            break;
          case sumPoints >= 30 && sumPoints <= 50:
            messageThreeAux += "A dose correta de dabigatrano é de 110mg duas vezes por dia";
            break;
          case allValues.age >= 80:
            messageThreeAux += "A dose correta de dabigatrano é de 110mg duas vezes por dia";
            break;
          default:
            messageThreeAux += "A dose correta de dabigatrano é de 150mg, duas vezes por dia";
            messageThreeAux += "<br><span style='font-size: 11px'>(Se uso concomitante de verapamilo, se risco hemorrágico aumentado ou se antecedentes de gastrite, esofagite ou refluxo gastroesofágico a dose deve ser reduzida para 110 mg, duas vezes por dia)</span>";
            break;
        }
      }
      messageThreeAux += "</p>";
      setMessageThree(messageThreeAux)
      setPoints(sumPoints)
      setShowResult(true);
      setErrorFinded(false);
    } else {
      setShowResult(false);
      if(auxErrorsFinded > 0){
        setErrorFinded(true);
        setMessageThree(null)
        setMessageTwo(null);
      }
    }
    setIsResultLoading(false);


    function twoOfThree() {
      let res = 0;
      if (allValues.creatinine >= 1.5) {
        res++;
      }
      if (allValues.age >= 80) {
        res++;
      }
      if (allValues.weight <= 60) {
        res++;
      }
      return res > 1;
    }

  }

  function handleIncrement(key) {
    let value = form.getFieldValue(key);
    if (typeof value === 'string') {
      value = Number(value);
    }

    if (value) {
      if (value >= minValues[key] && value < maxValues[key]) {
        if (key === 'creatinine') {
          value = value + 0.01;
          value = parseFloat(value).toFixed(2);
        } else {
          value++;
        }
      } else if (value < minValues[key]) {
        value = minValues[key]
      } else {
        value = maxValues[key]
      }

      form.setFieldValue([key], Number(value));
      form.validateFields([key])
    } else {
      form.setFieldValue([key], minValues[key]);
      form.validateFields([key])
    }
  }

  function handleDecrement(key) {
    let value = form.getFieldValue(key);
    if (typeof value === 'string') {
      value = Number(value);
    }

    if (value) {
      if (value > minValues[key] && value <= maxValues[key]) {
        if (key === 'creatinine') {
          value = value - 0.01;
          value = parseFloat(value).toFixed(2);
        } else {
          value--;
        }
      } else if (value <= minValues[key]) {
        value = minValues[key]
      } else {
        value = maxValues[key]
      }

      form.setFieldValue([key], Number(value));
      form.validateFields([key])
    } else {
      form.setFieldValue([key], minValues[key]);
      form.validateFields([key])
    }
  }

  function handleValidate(rule, value) {
    let key = rule.field;
    let keysOfNumber = Object.keys(minValues);
    if (keysOfNumber.includes(key)) {
      if (Number(value) < minValues[rule.field]) {
        return Promise.reject(<Tooltip color='red' placement="bottom" open={true} title={`Este valor não pode ser menor que ${minValues[rule.field]}`}></Tooltip>); // The validator should always return a promise on both success and error
      } else if (Number(value) > maxValues[rule.field]) {
        return Promise.reject(<Tooltip color='red' placement="bottom" open={true} title={`Este valor não pode ser maior que ${maxValues[rule.field]}`}></Tooltip>); // The validator should always return a promise on both success and error
      } else {
        return Promise.resolve(); // The validator should always return a promise on both success and error
      }

    }
  }

  return (
    <>
      <Helmet>
        <title>Calculadora SELEÇÃO DA DOSE DE HIPOCOAGULAÇÃO NA FA | Formação Científica em Medicina Cardiovascular</title>
        <meta name="description" content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares." />
        <meta name="keywords" content="calculadoras área cardiovascular, Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular" />
        <meta property="og:title" content="Calculadora SELEÇÃO DA DOSE DE HIPOCOAGULAÇÃO NA FA | Formação Científica em Medicina Cardiovascular" />
        <meta property="og:description" content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares." />
      </Helmet>
      <CalculatorBanner
        title='SELEÇÃO DA DOSE DE HIPOCOAGULAÇÃO NA FA'
      />

      <Form
        form={form}
        onFieldsChange={handleValuesChange}
        className='form_calculator'
      >
        <CalculatorContent>
          <Row gutter={[{ xs: 20, sm: 8, md: 16, lg: 60 }, { xs: 40, sm: 8, md: 16, lg: 60 }]}>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className='blue bold'>{'Score CHADSVASC > 1'}</p>
              <Form.Item name='chadsvasc' className='mt-10 w-100 mb-0'>
                <Radio.Group className='w-100 d-flex flex-nowrap'>
                  <Radio.Button value={1} className='w-50 mr-10'>Sim</Radio.Button>
                  <Radio.Button value={0} className='w-50 ml-10'>Não</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className='blue bold'>Sexo</p>
              <Form.Item name='sex' className='mt-10 w-100 mb-0'>
                <Radio.Group className='w-100 d-flex flex-nowrap'>
                  <Radio.Button value={1} className='w-50 mr-10'>Feminino</Radio.Button>
                  <Radio.Button value={0} className='w-50 ml-10'>Masculino</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </CalculatorContent>

        {messageOne &&
          <CalculatorResult isLoading={isResultLoading}>
            <Row className='h-100 ai-center jc-start pt-20 pb-20'>
              <p className='f-30'>{messageOne}</p>
            </Row>
          </CalculatorResult>
        }

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.chadsvasc !== currentValues.chadsvasc}
        >
          {({ getFieldValue }) =>
            getFieldValue('chadsvasc') === 1 ? (
              <>
                <CalculatorContent>
                  {/* Row #1 */}
                  <Row gutter={[{ xs: 20, sm: 8, md: 16, lg: 60 }, { xs: 40, sm: 8, md: 16, lg: 60 }]}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={8}>
                      <p className='blue bold'>Prótese mecânica</p>
                      <Form.Item name={'prosthesis'} className='mt-35 w-100 mb-0'>
                        <Radio.Group className='w-100 d-flex flex-nowrap'>
                          <Radio.Button value={1} className='w-50 mr-10'>Sim</Radio.Button>
                          <Radio.Button value={0} className='w-50 ml-10'>Não</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <p className='f-14 mt-10'>Sim = 1 ponto | Não = 0</p>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={8}>
                      <p className='blue bold'>História atual ou prévia de estenose mitral moderada a grave/reumática</p>
                      <Form.Item name={'stenosis'} className='mt-10 w-100 mb-0'>
                        <Radio.Group className='w-100 d-flex flex-nowrap'>
                          <Radio.Button value={1} className='w-50 mr-10'>Sim</Radio.Button>
                          <Radio.Button value={0} className='w-50 ml-10'>Não</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <p className='f-14 mt-10'>Sim = 1 ponto | Não = 0</p>
                    </Col>

                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) => prevValues.prosthesis !== currentValues.prosthesis || prevValues.stenosis !== currentValues.stenosis}
                    >
                      {({ getFieldValue }) =>
                        getFieldValue('prosthesis') === 0 && getFieldValue('stenosis') === 0 ? (
                          <>
                            {/* Row #2 */}
                            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
                              <p className='blue bold'>Diálise</p>
                              <Form.Item name={'dialysis'} className='mt-30 w-100 mb-0'>
                                <Radio.Group className='w-100 d-flex flex-nowrap'>
                                  <Radio.Button value={1} className='w-50 mr-10'>Sim</Radio.Button>
                                  <Radio.Button value={0} className='w-50 ml-10'>Não</Radio.Button>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
                              <p className='blue bold'>Idade</p>
                              <Row className='mt-10 d-flex flex-nowrap'>
                                <Button onClick={() => handleDecrement('age')} className='calculator_button mr-10'>-</Button>
                                <Form.Item
                                  name={'age'}
                                  className='w-100 mb-0'
                                  rules={[{ validator: handleValidate }]}
                                >
                                  <Input
                                    type='number'
                                    className='w-100'
                                  />
                                </Form.Item>
                                <Button onClick={() => handleIncrement('age')} className='calculator_button ml-10'>+</Button>
                              </Row>
                            </Col>

                            {/* Row #3 */}
                            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
                              <p className='blue bold'>Peso (kg)</p>
                              <Row className='mt-10 d-flex flex-nowrap'>
                                <Button onClick={() => handleDecrement('weight')} className='calculator_button mr-10'>-</Button>
                                <Form.Item
                                  name={'weight'}
                                  className='w-100 mb-0'
                                  rules={[{ validator: handleValidate }]}
                                >
                                  <Input
                                    type='number'
                                    className='w-100'
                                  />
                                </Form.Item>
                                <Button onClick={() => handleIncrement('weight')} className='calculator_button ml-10'>+</Button>
                              </Row>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
                              <p className='blue bold'>Creatina sérica (mg/dL)</p>
                              <Row className='mt-10 d-flex flex-nowrap'>
                                <Button onClick={() => handleDecrement('creatinine')} className='calculator_button mr-10'>-</Button>
                                <Form.Item
                                  name={'creatinine'}
                                  className='w-100 mb-0'
                                  rules={[{ validator: handleValidate }]}
                                >
                                  <Input
                                    type='number'
                                    className='w-100'
                                  />
                                </Form.Item>
                                <Button onClick={() => handleIncrement('creatinine')} className='calculator_button ml-10'>+</Button>
                              </Row>
                              <p className='f-14 mt-10'>Valores entre 0.3 e 6.0</p>
                            </Col>
                          </>
                        ) : null
                      }
                    </Form.Item>
                  </Row>
                </CalculatorContent>

                {messageTwo &&
                  <CalculatorResult isLoading={isResultLoading}>
                    <Row className='h-100 ai-center jc-start pt-20 pb-20'>
                      <p className='f-30'>{messageTwo}</p>
                    </Row>
                  </CalculatorResult>
                }

                {(showResult && messageThree) &&
                  <CalculatorResult isLoading={isResultLoading}>
                    <Row className='h-100 ai-start jc-start pt-20 pb-20'>
                      <Col xs={24} sm={12} md={12} lg={12} xl={7}>
                        <p>Clearance creatinina</p>
                        <p className='f-40 bold'>{points} mg/dl</p>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xl={17}>
                        <div dangerouslySetInnerHTML={{ __html: messageThree }}></div>
                      </Col>
                    </Row>
                  </CalculatorResult>
                }

                {errorFinded &&
                  <CalculatorResult isLoading={isResultLoading}>
                    <Row gutter={[24, 24]} className='h-100 ai-center jc-center'>
                      <Col xs={24} sm={24} md={16} lg={16} xl={16} className='d-flex jc-center ai-center'>
                        <p className='f-20 text-center'>Algum valor não está entre os parâmetros requeridos</p>
                      </Col>
                    </Row>
                  </CalculatorResult>
                }
              </>
            ) : null
          }
        </Form.Item>
      </Form>

      <CalculatorLinks />
    </>
  )
}
export default CalculatorHipocoagulationFA