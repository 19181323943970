import React, { useState, useEffect } from "react";
import { Button, Checkbox, Col, ColorPicker, Form, InputNumber, Radio, Drawer, Row, Select, message, Space, Switch, Upload, Input, Divider, DatePicker, Tooltip } from "antd";
import "../../../assets/styles/admin.css";
import axios from "axios";
import api from "../../../services/api";
import { Outlet, Link } from "react-router-dom";
import { MinusCircleOutlined, PlusOutlined, ArrowUpOutlined, DesktopOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import { useForm } from "antd/es/form/Form";
import config from "../../../services/config";
import endpoints from "../../../services/endpoints";
import "react-quill/dist/quill.snow.css";
import dayjs from "dayjs";
import Guidelines from "./Guidelines";
const { Dragger } = Upload;

const { Option } = Select;
const { TextArea } = Input;

function Questions({ open, close, data }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isOpenGuidelines, setIsOpenGuidelines] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedGuideline, setSelectedGuideline] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [filePreview, setFilePreview] = useState([]);

  const [form] = useForm();

  useEffect(() => {
    if (open && Object.keys(data).length > 0) {
      if (data.questions) {
        form.setFieldsValue({ question: data.questions });
      }
    }
  }, [open]);

  function handleClose() {
    form.resetFields();
    close();
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);

    let deletedRows = [];

    if (JSON.stringify(values) !== JSON.stringify(data)) {
      for (let i = 0; i < values.length; i++) {
        let findObject = values.filter((item) => item.id === values[i].id);

        if (findObject.length === 0) {
          deletedRows.push(values[i]);
        }
      }
    }

    axios
      .post(endpoints.clinicalCases.updateQuestions, {
        data: { id_clinical_case: data.id, values, deletedRows },
      })
      .then((res) => {
        setTimeout(() => {
          setIsButtonLoading(false);
          handleClose();
        }, 1000);
      })
      .catch((error) => {
        setIsButtonLoading(false);
        console.error(error);
      });
  }

  async function handlePreview(file, indexOfTab, index) {
    let filePreviewAux = await getBase64(file);
    const newFilePreview = JSON.parse(JSON.stringify(filePreview));
    newFilePreview[indexOfTab][index] = filePreviewAux;
    setFilePreview(newFilePreview);
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleOpenGuideline(index) {
    let values = form.getFieldsValue();
    console.log(values.question[index]);
    let auxGuideline = values.question[index].guidelines ? JSON.parse(values.question[index].guidelines) : null;

    console.log(auxGuideline);
    setSelectedIndex(index);
    setSelectedGuideline(auxGuideline);
    setIsOpenGuidelines(true);
  }

  function handleCloseGuideline(guidelinesData) {
    if (guidelinesData) {
      console.log(guidelinesData);
      let values = form.getFieldsValue();
      values.question[selectedIndex].guidelines = guidelinesData ? JSON.stringify(guidelinesData) : null;
      console.log(values);
      form.setFieldsValue(values);
    }
    setIsOpenGuidelines(false);
  }

  return (
    <Drawer
      className="drawer-clinical"
      key={`drawer-create-questions-clinical`}
      title="Perguntas - Caso clínico"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        <>
          <Button disabled={isButtonLoading} className="mr-10" onClick={handleClose}>
            Cancelar
          </Button>
          <Button loading={isButtonLoading} type="primary" onClick={form.submit}>
            Submeter
          </Button>
        </>
      }
    >
      <Guidelines open={isOpenGuidelines} close={handleCloseGuideline} data={selectedGuideline} questionIndex={selectedIndex} />
      <Row gutter={[24]} className="m-0">
        <Col span={24}>
          <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Form.List
              key={`form_questions`}
              name="question"
              rules={[
                {
                  validator: async (_, question) => {
                    if (question.length > 0) {
                      for (let i = 0; i < question.length; i++) {
                        if (!question[i] || question[i].answers.length < 2) {
                          return Promise.reject(new Error("A pergunta tem de ter obrigatoriamente 2 respostas."));
                        }
                      }
                    } else {
                      return Promise.reject(new Error("Tem que adicionar alguma pergunta para conseguir submeter."));
                    }
                  },
                  validateTrigger: "submit",
                },
              ]}
            >
              {(fields, { add, remove, move }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <>
                      <Form.Item label={`Pergunta ${index + 1}`} required={false} key={`question_${field.key}`} className="questions_form_item">
                        <Form.Item name={[index, "id"]} key={`id`} hidden>
                          <Input />
                        </Form.Item>
                        <Form.Item name={[index, "guideline"]} key={`guideline_${index + 1}`} hidden>
                          <Input />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[index, "question"]}
                          key={`question_${field.key}_${index}`}
                          rules={[
                            {
                              required: true,
                              message: "Este campo é obrigatório",
                            },
                          ]}
                          validateTrigger={["onChange", "onBlur"]}
                          noStyle
                        >
                          <Input
                            size="large"
                            placeholder="Pergunta..."
                            style={{
                              width: "calc(100% - 80px)",
                            }}
                          />
                        </Form.Item>
                        {fields.length > 1 ? <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(field.name)} /> : null}
                        {index !== 0 && fields.length > 1 ? <ArrowUpOutlined className="dynamic-delete-button" onClick={() => move(index, index - 1)} /> : null}
                      </Form.Item>
                      <Form.List key={`answers_${index}`} name={[field.name, "answers"]}>
                        {(fields, { add, remove, move }, { errors }) => (
                          <>
                            {fields.map((field, i) => (
                              <>
                                <Form.Item label={i === 0 ? "Respostas" : ""} required={false} key={`answer_${field.name}_${field.key}`} className="questions_form_item">
                                  <Form.Item
                                    {...field}
                                    name={[i, "title"]}
                                    key={`answer_${field.name}_${field.key}_title`}
                                    sizelarge
                                    rules={[
                                      {
                                        required: true,
                                        message: "Este campo é obrigatório",
                                      },
                                    ]}
                                    validateTrigger="submit"
                                    noStyle
                                  >
                                    <Input
                                      className="mr-10"
                                      size="large"
                                      placeholder="Resposta..."
                                      style={{
                                        width: "calc(100% - 125px)",
                                      }}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    label="Correta"
                                    {...field}
                                    name={[i, "is_correct"]}
                                    key={`answer_${field.name}_${field.key}_is_correct`}
                                    sizelarge
                                    noStyle
                                    valuePropName="checked"
                                    initialValue={false}
                                  >
                                    <Checkbox className="mr-10 ml-10" size="large" />
                                  </Form.Item>
                                  {fields.length > 1 ? (
                                    <div>
                                      <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(field.name)} />
                                    </div>
                                  ) : null}
                                  {i !== 0 && fields.length > 1 ? <ArrowUpOutlined className="dynamic-delete-button" onClick={() => move(i, i - 1)} /> : null}
                                </Form.Item>
                              </>
                            ))}
                            <Form.Item>
                              <Button type="dashed" onClick={() => add()} className="w-100" size="large" icon={<PlusOutlined />}>
                                Adicionar resposta
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>

                      <Form.Item>
                        <Button type="dashed" onClick={() => handleOpenGuideline(index)} className="w-100" size="large">
                          Guideline
                        </Button>
                      </Form.Item>

                      <Divider
                        style={{
                          height: 4,
                          backgroundColor: "rgba(0,0,0,0.2)",
                        }}
                      />
                    </>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} className="w-100" size="large" icon={<PlusOutlined />}>
                      Adicionar pergunta
                    </Button>
                  </Form.Item>
                  <Form.ErrorList errors={errors} />
                </>
              )}
            </Form.List>
          </Form>
        </Col>
      </Row>
    </Drawer>
  );
}

export default Questions;
