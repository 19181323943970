import React, { useState, useEffect } from "react";
import { Button, Checkbox, Col, ColorPicker, Form, InputNumber, Radio, Drawer, Row, Select, message, Space, Switch, Upload, Input, Divider } from "antd";
import "../../../assets/styles/admin.css";
import axios from "axios";
import api from "../../../services/api";
import { Outlet, Link } from "react-router-dom";
import { InboxOutlined, UploadOutlined, DesktopOutlined, MobileOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import { useForm } from "antd/es/form/Form";
import config from "../../../services/config";
import endpoints from "../../../services/endpoints";
import "react-quill/dist/quill.snow.css";
const { Dragger } = Upload;

const { Option } = Select;
const { TextArea } = Input;

function CreateBanner({ open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileListDesktop, setFileListDesktop] = useState([]);
  const [fileListMobile, setFileListMobile] = useState([]);
  const [filePreview, setFilePreview] = useState({});
  const [formatHex, setFormatHex] = useState("hex");

  const [form] = useForm();

  function handleClose() {
    close();
  }

  const uploadPropsImageDesktop = {
    accept: "image/*",
    onRemove: (file) => {
      const index = fileListDesktop.indexOf(file);
      const newFileList = fileListDesktop.slice();
      const newFilePreview = JSON.parse(JSON.stringify(filePreview));
      newFilePreview.desktop = "";
      newFileList.splice(index, 1);
      setFileListDesktop(newFileList);
      setFilePreview(newFilePreview);
    },
    beforeUpload: (file) => {
      handlePreview(file, "desktop");
      setFileListDesktop([file]);
      return false;
    },
    fileList: fileListDesktop,
    defaultFileList: [],
  };

  const uploadPropsImageMobile = {
    accept: "image/*",
    onRemove: (file) => {
      const index = fileListMobile.indexOf(file);
      const newFileList = fileListMobile.slice();
      const newFilePreview = JSON.parse(JSON.stringify(filePreview));
      newFilePreview.mobile = "";
      newFileList.splice(index, 1);
      setFileListMobile(newFileList);
      setFilePreview(newFilePreview);
    },
    beforeUpload: (file) => {
      const isImage = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/svg+xml";
      if (!isImage) {
        message.error({
          type: "error",
          content: "Apenas é possível fazer upload de imagens.",
        });
      } else {
        handlePreview(file, "mobile");
        setFileListMobile([file]);
      }
      return false;
    },
    fileList: fileListMobile,
    defaultFileList: [],
  };

  async function handlePreview(file, type) {
    let filePreviewAux = await getBase64(file);
    const newFilePreview = JSON.parse(JSON.stringify(filePreview));
    newFilePreview[type] = filePreviewAux;
    console.log(newFilePreview);
    setFilePreview(newFilePreview);
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);
    const formData = new FormData();

    fileListDesktop.forEach((file) => {
      formData.append("file_desktop", file);
    });

    fileListMobile.forEach((file) => {
      formData.append("file_mobile", file);
    });

    values.button_back_color = values.button_back_color ? values.button_back_color.toHexString() : null;
    values.button_text_color = values.button_text_color ? values.button_text_color.toHexString() : null;

    formData.append("data", JSON.stringify(values));

    fetch(`${config.server_ip}${endpoints.banners.create}`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((resData) => {
        console.log(resData);
        setTimeout(() => {
          setIsButtonLoading(false);
          handleClose();
          form.resetFields();
          setFileListDesktop([]);
          setFileListMobile([]);
          setFilePreview({});
        }, 1000);
      })
      .catch((error) => {
        setIsButtonLoading(false);
        console.error(error);
      });
  }

  return (
    <Drawer
      className="drawer-banner"
      key={`drawer-create-banner`}
      title="Adicionar banner"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={[
        <Button size="large" disabled={isButtonLoading} className="mr-10" onClick={handleClose}>
          Cancelar
        </Button>,
        <Button size="large" loading={isButtonLoading} type="primary" onClick={form.submit}>
          Adicionar
        </Button>,
      ]}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical" name="form_edit">
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item
              name="image_desktop"
              label="Imagem Desktop"
              rules={[
                {
                  required: true,
                  message: "Este campo é obrigatório",
                },
              ]}
            >
              <Dragger {...uploadPropsImageDesktop} className={`dragger ${filePreview.desktop ? "dragger_hidden" : ""}`}>
                <p className="ant-upload-drag-icon">
                  <DesktopOutlined style={{ color: "rgb(103 103 103)" }} />
                </p>
                <p className="ant-upload-text text">
                  <b>Upload da imagem em desktop</b>
                </p>
                <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                <div
                  className={`preview_file ${filePreview.desktop ? "" : "hidden"}`}
                  style={{
                    backgroundImage: `url(${
                      filePreview.desktop && filePreview.desktop.includes("base64") ? filePreview.desktop : `${config.server_ip}assets/media/${filePreview.desktop}`
                    })`,
                  }}
                ></div>
              </Dragger>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="image_mobile"
              label="Imagem Mobile"
              rules={[
                {
                  required: true,
                  message: "Este campo é obrigatório",
                },
              ]}
            >
              <Dragger {...uploadPropsImageMobile} className={`dragger ${filePreview.mobile ? "dragger_hidden" : ""}`}>
                <p className="ant-upload-drag-icon">
                  <MobileOutlined style={{ color: "rgb(103 103 103)" }} />
                </p>
                <p className="ant-upload-text text">
                  <b>Upload da imagem em mobile</b>
                </p>
                <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                <div
                  className={`preview_file ${filePreview.mobile ? "" : "hidden"}`}
                  style={{
                    backgroundImage: `url(${
                      filePreview.mobile && filePreview.mobile.includes("base64") ? filePreview.mobile : `${config.server_ip}assets/media/${filePreview.mobile}`
                    })`,
                  }}
                ></div>
              </Dragger>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Título" name="title">
          <Input placeholder="Título" size="large" />
        </Form.Item>
        <Form.Item label="Subtítulo" name="subtitle">
          <ReactQuill placeholder="Subtítulo" size="large" />
        </Form.Item>
        <Form.Item label="Texto do botão" name="button_text">
          <Input size="large" placeholder="Clique aqui" />
        </Form.Item>
        <Row gutter={[24]}>
          <Col span={19}>
            <Form.Item label="Link do botão" name="button_link">
              <Input size="large" placeholder="https://exemplo.pt" />
            </Form.Item>
          </Col>
          <Col span={5} className="d-flex jc-center ai-center">
            <Form.Item name="link_target_blank" className="m-0" valuePropName="checked">
              <Checkbox className="m-0" size="large" style={{ lineHeight: "32px" }}>
                Target blank
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24]}>
          <Col span={12}>
            <Form.Item label="Cor de fundo do botão" name="button_back_color">
              <ColorPicker format={formatHex} getPopupContainer={(trigger) => trigger.parentNode} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Cor de texto do botão" name="button_text_color">
              <ColorPicker format={formatHex} getPopupContainer={(trigger) => trigger.parentNode} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item
              label="Posição"
              name="position"
              rules={[
                {
                  required: true,
                  message: "Este campo é obrigatório",
                },
              ]}
            >
              <InputNumber className="w-100" min={0} size="large" placeholder="Posição" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Banner default" name="is_banner_default">
              <Switch size="large" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}

export default CreateBanner;
