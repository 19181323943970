import React, { useState } from "react";
import { Button, Form, Input, Drawer, DatePicker, Select, Row, Col, Menu, Modal, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import "./ModalLogout.css";
import axios from "axios";
import endpoints from "../../../services/endpoints";

function ModalLogout({ handleLogout, open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  function handleClickLogout() {
    setIsButtonLoading(true);
    setTimeout(() => {
      handleLogout();
      setIsButtonLoading(false);
    }, 2000);
  }

  return (
    <Modal
      className="modal-logout-account"
      key={`modal-logout-account`}
      title="Logout"
      onCancel={close}
      open={open}
      footer={[
        <Button size="large" className="mt-20" disabled={isButtonLoading} onClick={close}>
          Cancelar
        </Button>,
        <Button size="large" loading={isButtonLoading} type="primary" danger className="ml-10 mt-20" onClick={handleClickLogout}>
          Sim
        </Button>,
      ]}
    >
      <div className="d-flex flex-column">
        <p className="f-20 mt-10 mb-10">Tem a certeza que quer sair da sua conta?</p>
      </div>
    </Modal>
  );
}

export default ModalLogout;
