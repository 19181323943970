import { Spin } from "antd";
import "../../../assets/styles/calculator.css";

export const CalculatorResult = (props) => {
  return (
    <Spin wrapperClassName="calculator_loading" spinning={props.isLoading ? props.isLoading : false}>
      <div className={`result container ${props.className ? props.className : ''}`}>
        <div className="result__bar" />
        <div className={'result__content ' + props.className}>
          {props.children}
        </div>
      </div>
    </Spin>
  )
}