import React, { useEffect, useState } from "react";
import BannerHome from "../../../components/website/BannerHome/BannerHome";
import HomeWhiteSection from "../../../components/website/HomeWhiteSection/HomeWhiteSection";
import { Helmet } from "react-helmet";

export default function Home() {
  return (
    <>
      <Helmet>
        <title>
          Cardio4all | Formação Científica em Medicina Cardiovascular
        </title>
        <meta
          name="description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
        <meta
          name="keywords"
          content="Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, calculadoras área cardiovascular, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta
          property="og:title"
          content="Cardio4all | Formação Científica em Medicina Cardiovascular"
        />
        <meta
          property="og:description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <BannerHome />
      <HomeWhiteSection />
    </>
  );
}
